import { gql } from 'graphql-request';
import type { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { gqlWsClient, handleError } from '@/lib/gql-client';
import { queryClient } from '@/lib/query-client';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import type {
  EntryAddedSubscription,
  EntryAddedSubscriptionVariables,
} from './useEntryAddedSubscription.generated';

const query = gql`
  subscription EntryAdded(
    $scheduleId: ID!
    $includeMessages: Boolean!
    $includeRsvps: Boolean!
  ) {
    entryAdded(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const useEntryAddedSubscription = (scheduleId: string) => {
  const handleData = useCallback(
    ({ data }: ExecutionResult<EntryAddedSubscription>) => {
      if (data?.entryAdded) {
        queryClient.invalidateQueries({
          queryKey: createEntriesQueryKey(scheduleId),
        });
      }
    },
    [scheduleId]
  );

  useEffect(() => {
    const variables: EntryAddedSubscriptionVariables = {
      scheduleId,
      // It's not possible to create an item with messages or rsvps in a single transaction
      includeMessages: false,
      includeRsvps: false,
    };

    const unsubscribe = gqlWsClient.subscribe<EntryAddedSubscription>(
      {
        query: query,
        variables,
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, handleData]);
};
