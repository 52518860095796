import { useContext } from 'react';
import { createContextHook } from '@/utils';
import {
  ScheduleFiltersContext,
  type ScheduleFiltersContextValue,
} from './ScheduleFiltersContext';

export const useScheduleFilters = createContextHook(ScheduleFiltersContext);

export const useUnsafeScheduleFilters = ():
  | ScheduleFiltersContextValue
  | undefined => {
  return useContext(ScheduleFiltersContext);
};
