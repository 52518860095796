import type { Label as LabelType } from '@/types/gql.generated';
import { Flex, type FlexProps } from '@/ui';
import { useItemCardContext } from '../../context/useItemCardContext';
import { Category } from '../Category';
import { Label } from './Label';

type Props = FlexProps & {
  onRemoveCategory?: () => void;
  onRemoveLabel?: (label: LabelType) => void;
  onRemoveWhoLabel?: (label: LabelType) => void;
};

export const Labels = ({
  onRemoveCategory,
  onRemoveLabel,
  onRemoveWhoLabel,
  ...props
}: Props) => {
  const { entry, instance } = useItemCardContext();

  const category = instance?.category || entry.category;
  const labels = instance?.labels || entry.labels;
  const whoLabels = instance?.whoLabels || entry.whoLabels;

  const hasCategoryOrLabels =
    Boolean(category) || labels.length > 0 || whoLabels.length > 0;

  if (!hasCategoryOrLabels) {
    return null;
  }

  return (
    <Flex align="center" display="inline-flex" gap="2" wrap="wrap" {...props}>
      {category && <Category category={category} onRemove={onRemoveCategory} />}
      {labels.map((label) => (
        <Label
          key={label.id}
          onRemove={onRemoveLabel ? () => onRemoveLabel(label) : undefined}
        >
          {label.text}
        </Label>
      ))}
      {whoLabels.map((label) => (
        <Label
          key={label.id}
          type="whoLabel"
          onRemove={
            onRemoveWhoLabel ? () => onRemoveWhoLabel(label) : undefined
          }
        >
          {label.text}
        </Label>
      ))}
    </Flex>
  );
};
