import { Fragment, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Button,
  type ButtonProps,
  Icon,
  Menu,
  MenuButton,
  type MenuButtonProps,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
} from '@/ui';
import type { PageButton } from '../../types';

type Props = {
  buttonProps?: MenuButtonProps;
  pageButtons: PageButton[][];
};

export const MoreMenu = ({ buttonProps, pageButtons }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation('header');

  const activeMenuItem = isMobileBreakpoint
    ? pageButtons.flat().find((button) => button.isActive)
    : undefined;

  const menuButtonProps: ButtonProps = {
    as: Button,
    variant: 'unstyled',
    color: 'customgray.dark',
    'aria-label': t('schedule.toolbar.more'),
    sx: {
      span: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 0.5,
      },
    },
    _hover: { bg: 'gray.100' },
    _active: { bg: 'gray.100' },
  };

  const menuButton = isMobileBreakpoint ? (
    <MenuButton
      borderColor="customgray.gray"
      borderRadius="32px"
      borderWidth="1px"
      data-group
      h="32px"
      minW="auto"
      pl="2"
      pr="2"
      {...menuButtonProps}
      {...buttonProps}
      _active={{
        ...menuButtonProps._active,
        borderColor: 'customgray.mid',
      }}
      _hover={{
        ...menuButtonProps._hover,
        borderColor: 'customgray.mid',
      }}
    >
      <>
        {activeMenuItem?.icon}
        <Icon
          _groupActive={{ color: 'customgray.dark' }}
          _groupHover={{ color: 'customgray.dark' }}
          color="customgray.mid"
          icon="ChevronDown"
          transition="all 0.2s ease"
        />
      </>
    </MenuButton>
  ) : (
    <MenuButton
      borderRadius="0"
      h="100%"
      w="56px"
      {...menuButtonProps}
      {...buttonProps}
    >
      <Icon icon="MoreHorizontal" size="6" />
    </MenuButton>
  );

  return (
    <Menu isLazy placement="bottom-start">
      {menuButton}

      <Portal>
        <MenuList data-testid="more-menu-list">
          {pageButtons.map((buttonGroup, groupIndex) => (
            <Fragment key={groupIndex}>
              {buttonGroup.map(({ icon, label, href, onClick }) => (
                <MenuItem
                  as={href ? Link : undefined}
                  icon={cloneElement(icon, { size: 16 })}
                  key={label}
                  {...(href ? { to: href } : { onClick })}
                  _hover={{ textDecoration: 'none' }}
                >
                  {label}
                </MenuItem>
              ))}
              {groupIndex < pageButtons.length - 1 && <MenuDivider />}
            </Fragment>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};
