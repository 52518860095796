import { forwardRef } from '@/ui';
import { MotionText, type MotionTextProps } from '@/ui/MotionText';

export const SlideText = forwardRef((props: MotionTextProps, ref) => {
  return (
    <MotionText
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      initial={{ opacity: 0 }}
      ref={ref}
      {...props}
    />
  );
});
