import { css } from '@emotion/react';
import { useRef, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import type { DecoratedProfile } from '@/contexts';
import { Box, Avatar, Button, Flex } from '@/ui';

type Props = {
  user: DecoratedProfile | null | undefined;
  onChange: (files: File | null) => void;
  onRemoveClick: () => void;
};

export const ChangeAvatar = ({ user, onChange, onRemoveClick }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [avatarPreview, setAvatarPreview] = useState<string | null>(
    user?.avatar ?? ''
  );

  return (
    <>
      <Box
        pos="relative"
        css={css`
          &:hover * {
            opacity: 1;
          }
        `}
      >
        <input
          accept="image/png, image/jpeg"
          ref={fileInputRef}
          style={{ display: 'none' }}
          type="file"
          onChange={(event) => {
            const fileReader = new FileReader();
            onChange(event.target.files?.[0] ?? null);
            fileReader.onloadend = () => {
              setAvatarPreview(fileReader.result as string);
            };
            if (event.target.files?.[0]) {
              fileReader.readAsDataURL(event.target.files[0]);
            }
          }}
        />
        <Avatar
          bg="brand.500"
          color="white"
          name={user?.name || user?.email || ''}
          size="xl"
          src={avatarPreview ?? ''}
        />
        <Flex
          align="center"
          as="button"
          bg="rgba(0,0,0,0.4)"
          borderRadius="50%"
          bottom="0"
          color="white"
          justify="center"
          left="0"
          opacity="0"
          pos="absolute"
          right="0"
          top="0"
          transition="all 0.2s ease"
          type="button"
          onClick={() => fileInputRef.current?.click()}
        >
          Replace
        </Flex>
        <Button
          _hover={{ bg: 'gray.300' }}
          bg="customgray.gray"
          borderRadius="50%"
          h="24px"
          minW="auto"
          opacity={0}
          pos="absolute"
          right="3px"
          top="3px"
          transition="all 0.2s ease"
          type="button"
          variant="unstyled"
          w="24px"
          onClick={(event) => {
            event.stopPropagation();
            setAvatarPreview(null);
            onRemoveClick();
          }}
        >
          <IoIosClose size="24" />
        </Button>
      </Box>
    </>
  );
};
