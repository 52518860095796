import { useTranslation } from 'react-i18next';
import { SlimHeader } from '@/components/SlimHeader';
import { useCurrentUserContext } from '@/contexts';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useAuthModal } from '@/pages/QuickCreate/components/AuthModal';
import { useIsSharePage } from '@/pages/QuickCreate/hooks/useIsSharePage';
import { type FlexProps, Button } from '@/ui';
import { Cta } from './Cta';
import { Tabs } from './Tabs';

export const Header = (props: FlexProps) => {
  const { isLoading, isAuthenticated } = useCurrentUserContext();
  const { open: openAuthModal } = useAuthModal();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation('auth');
  const { isSharePage } = useIsSharePage();

  const showCta =
    !isLoading && !isAuthenticated && !isMobileBreakpoint && isSharePage;

  const showTabs = !isLoading && isAuthenticated;

  return (
    <SlimHeader
      {...props}
      logoHref={isAuthenticated ? { to: '/dashboard' } : 'default'}
      middleColumn={showCta ? <Cta /> : showTabs ? <Tabs /> : null}
      rightColumn={
        isLoading ? (
          'none'
        ) : isAuthenticated ? (
          'default'
        ) : (
          <>
            <Button
              colorScheme="dark"
              mr="2"
              size="sm"
              variant="link"
              onClick={() => openAuthModal()}
            >
              {t('sign_in')}
            </Button>
            <Button
              colorScheme="dark"
              size="sm"
              onClick={() => openAuthModal()}
            >
              {t('sign_up')}
            </Button>
          </>
        )
      }
    />
  );
};
