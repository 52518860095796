import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCurrentUserContext } from '@/contexts';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  LogoutMutation,
  LogoutMutationVariables,
} from './useLogout.generated';

const query = gql`
  mutation Logout {
    logout
  }
`;

export const useLogout = () => {
  const { setUser } = useCurrentUserContext();
  const queryClient = useQueryClient();

  const { mutate } = useMutation<LogoutMutation, QueryError>({
    mutationFn: () => {
      return gqlClient.request<LogoutMutation, LogoutMutationVariables>(query);
    },
  });

  return {
    logout: ({ onSuccess }: { onSuccess: () => void }) => {
      mutate(undefined, {
        onSuccess: () => {
          setUser(null);
          queryClient.clear();
          onSuccess();
        },
      });
    },
  };
};
