import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import { createScheduleMembersQueryKey } from '@/utils/queryKeys';
import type {
  AddMemberMutation,
  AddMemberMutationVariables,
} from './useAddMember.generated';

const query = gql`
  mutation AddMember($scheduleId: ID!, $members: [MemberInput!]!) {
    addMembers(scheduleId: $scheduleId, members: $members) {
      id
    }
  }
`;

export const useAddMember = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, error, reset, isPending } = useMutation<
    AddMemberMutation,
    QueryError,
    AddMemberMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<AddMemberMutation, AddMemberMutationVariables>(
        query,
        variables
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createScheduleMembersQueryKey(scheduleId),
      });
    },
  });

  const addMember = (
    name: string,
    phoneNumber: string,
    options?: MutateOptions<
      AddMemberMutation,
      QueryError,
      AddMemberMutationVariables
    >
  ) => {
    return mutate(
      {
        scheduleId,
        members: [{ name, phoneNumber }],
      },
      options
    );
  };

  return {
    addMember,
    reset,
    error,
    isPending,
  };
};
