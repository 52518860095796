import { Text, type TextProps } from '@/ui';

type Props = TextProps & {
  first?: boolean;
};

export const SectionHeader = ({ first, ...props }: Props) => {
  return (
    <Text
      borderTopColor="gray.200"
      borderTopWidth={first ? 0 : '1px'}
      fontSize="xs"
      fontWeight="bold"
      mb="4"
      mt={first ? 0 : 10}
      pt={first ? 0 : 10}
      textTransform="uppercase"
      {...props}
    />
  );
};
