import type { ReactNode } from 'react';
import { createContext } from '@/utils';

type PopoverContextValue = {
  isOpen: boolean;
  onClose: () => void;
};

type Props = {
  children: ReactNode;
} & Pick<PopoverContextValue, 'isOpen' | 'onClose'>;

export const PopoverContext = createContext<PopoverContextValue>(
  {
    isOpen: true,
    onClose: () => {
      // Overridden by provider
    },
  },
  'PopoverContext'
);

export const PopoverContextProvider = ({
  isOpen,
  onClose,
  children,
}: Props) => {
  const value: PopoverContextValue = {
    isOpen,
    onClose,
  };

  return (
    <PopoverContext.Provider value={value}>{children}</PopoverContext.Provider>
  );
};
