import { Text } from '@/ui';
import Circle from './circle.svg?react';

export const BlankCanvasText = () => {
  return (
    <Text>
      blank
      <Text as="span" pos="relative" px="1.5" py="5px">
        <Circle style={{ position: 'absolute', inset: 0 }} />
        canvas
      </Text>
    </Text>
  );
};
