import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import type { IconType } from 'react-icons';
import { AiOutlineGoogle } from 'react-icons/ai';
import { useCurrentUserContext } from '@/contexts';
import { gqlClient } from '@/lib';
import { IntegrationType } from '@/types/gql.generated';
import { createIntegrationsQueryKey } from '@/utils/queryKeys';
import type {
  GetIntegrationsQuery,
  GetIntegrationsQueryVariables,
} from './useIntegrations.generated';

const query = gql`
  query GetIntegrations {
    getIntegrations {
      id
      externalId
      name
      type
      status
      scope
      calendars {
        id
        name
        uri
        color
        readonly
        scheduleCount
      }
    }
  }
`;

type RemoteIntegration = GetIntegrationsQuery['getIntegrations'][number];

export type DecoratedIntegration = RemoteIntegration & {
  icon: IconType | null;
};

const selector = (data: GetIntegrationsQuery): DecoratedIntegration[] =>
  data.getIntegrations.map((integration) => ({
    ...integration,
    icon:
      integration.type === IntegrationType.GoogleCalendar
        ? AiOutlineGoogle
        : null,
  }));

export const useIntegrations = () => {
  const { isAuthenticated } = useCurrentUserContext();

  return useQuery({
    queryKey: createIntegrationsQueryKey(),
    select: selector,
    enabled: isAuthenticated,
    queryFn: () => {
      return gqlClient.request<
        GetIntegrationsQuery,
        GetIntegrationsQueryVariables
      >(query);
    },
  });
};
