import type { ReactNode } from 'react';
import { Logo } from '@/components/Logo';
import { ProfileMenu } from '@/components/ProfileMenu';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { Flex, Grid, GridItem, type FlexProps, Container, Text } from '@/ui';
import type { LogoHref } from './types';
import { getLogoLinkProps } from './utils';

type Props = FlexProps & {
  logoHref?: LogoHref;
  middleColumn?: ReactNode;
  rightColumn?: JSX.Element | 'default' | 'none';
};

/**
 * Header is used on:
 * - Magic/dashboard tabs
 * - Public snippet pages
 * - Auth screens
 */
export const SlimHeader = ({
  logoHref = 'default',
  middleColumn,
  rightColumn,
  ...props
}: Props) => {
  const { isAuthenticated } = useCurrentUserContext();
  const logoLinkProps = getLogoLinkProps(logoHref, isAuthenticated);

  return (
    <Container
      alignItems="center"
      as={Grid}
      fontWeight="medium"
      gap="6"
      h="74px"
      templateColumns="1fr auto 1fr"
      variant="max"
      {...props}
    >
      <GridItem align="center" as={Flex} gap="2.5">
        <Flex align="center" {...logoLinkProps}>
          <Logo height={32} />
        </Flex>
        <Text
          {...logoLinkProps}
          fontSize="17px"
          fontWeight="extrabold"
          display={{
            base: middleColumn ? 'none' : 'initial',
            md: 'initial',
          }}
        >
          agendahero
        </Text>
      </GridItem>

      <GridItem>{middleColumn}</GridItem>

      <GridItem align="center" as={Flex} gap="2" justify="flex-end">
        {rightColumn === 'default' ? (
          <ProfileMenu />
        ) : rightColumn === 'none' ? null : (
          rightColumn
        )}
      </GridItem>
    </Container>
  );
};
