import { useMeasure } from 'react-use';
import {
  Box,
  RadioGroup,
  type RadioGroupProps,
  Flex,
  Radio,
  forwardRef,
  type FlexProps,
} from '@/ui';

export type SegmentedControlOption<T> = {
  isDisabled?: boolean;
  label: string;
  value: T;
};

export type SegmentedControlProps<T> = Omit<
  RadioGroupProps,
  'onChange' | 'children' | '_hover' | '_selected' | '_disabled'
> & {
  value: T;
  options: SegmentedControlOption<T>[];
  onChange: (value: T) => void;
  _hover?: FlexProps;
  _selected?: FlexProps;
  _disabled?: FlexProps;
};

type TabProps = FlexProps & {
  value: string;
  isDisabled?: boolean;
};

const TAB_GAP = 2;

const Tab = forwardRef(({ value, isDisabled, ...props }: TabProps, ref) => {
  return (
    <>
      <Radio display="none" id={value} isDisabled={isDisabled} value={value} />

      <Flex
        align="center"
        as="label"
        bg="transparent"
        border="none"
        borderRadius="md"
        color="black"
        cursor="pointer"
        flex="1"
        fontSize="sm"
        fontWeight="bold"
        h="9"
        htmlFor={value}
        justify="center"
        ref={ref}
        transition="all 0.15s ease-in-out"
        w="5rem"
        zIndex="2"
        {...(isDisabled && {
          color: 'gray.600',
          cursor: 'not-allowed',
          _hover: undefined,
        })}
        {...props}
      />
    </>
  );
});

export const SegmentedControl = <T extends string>({
  value,
  options,
  _hover = { bg: 'rgba(255, 255, 255, 0.7)' },
  _selected,
  _disabled,
  ...props
}: SegmentedControlProps<T>) => {
  const [ref, { width, height }] = useMeasure();

  const gliderX =
    value === options[0].value
      ? 0
      : `calc(100% + var(--chakra-space-${TAB_GAP}))`;

  return (
    <RadioGroup
      bg="#E0EBF5"
      borderRadius="md"
      display="flex"
      gap={TAB_GAP}
      p="1"
      pos="relative"
      value={value}
      {...props}
    >
      {options.map((option) => {
        const isSelected = value === option.value;
        const isDisabled = props.isDisabled || option.isDisabled;

        return (
          <Tab
            isDisabled={isDisabled}
            key={option.value}
            ref={isSelected ? ref : undefined}
            value={option.value as string}
            zIndex="2"
            _hover={
              isDisabled
                ? undefined
                : {
                    ..._hover,
                    bg: isSelected ? 'transparent' : _hover?.bg,
                  }
            }
          >
            {option.label}
          </Tab>
        );
      })}

      <Box
        bg="white"
        borderRadius="md"
        h={height + 'px'}
        pos="absolute"
        shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.10)"
        transform={`translateX(${gliderX})`}
        transition="transform 0.25s ease-in-out"
        w={width + 'px'}
        zIndex="1"
        {..._selected}
        {..._disabled}
      />
    </RadioGroup>
  );
};
