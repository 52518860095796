import { useMemo } from 'react';
import {
  Alert,
  Box,
  Flex,
  useToast as useChakraToast,
  type UseToastOptions,
} from '@/ui';

type Value = string | Partial<UseToastOptions>;

const getOptions = (value: Value): UseToastOptions => {
  return typeof value === 'string' ? { description: value } : value;
};

const globalOptions: UseToastOptions = {
  position: 'top',
  isClosable: true,
};

export const useToast = () => {
  const toast = useChakraToast(globalOptions);

  return useMemo(
    () => ({
      ...toast,

      success: (description: Value) => {
        const options = getOptions(description);
        return toast({ status: 'success', ...options });
      },

      error: (description: Value) => {
        const options = getOptions(description);
        return toast({ status: 'error', ...options });
      },

      warning: (description: Value) => {
        const options = getOptions(description);
        return toast({ status: 'warning', ...options });
      },

      info: (description: Value) => {
        const options = getOptions(description);
        return toast({ status: 'info', ...options });
      },

      notify: (description: Value) => {
        const options = getOptions(description);
        return toast({
          position: 'bottom-right',
          render: () => (
            <Alert variant="notify">
              {options.title && options.description ? (
                <Flex
                  align="stretch"
                  direction="column"
                  flexBasis="100%"
                  gap="0.5"
                >
                  <Box>{options.title}</Box>
                  <Box color="customgray.mid" fontSize="sm">
                    {options.description}
                  </Box>
                </Flex>
              ) : (
                options.description
              )}
            </Alert>
          ),
          ...options,
        });
      },
    }),
    [toast]
  );
};
