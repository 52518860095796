import type { DateTime } from 'luxon';
import type { Weekday } from 'rrule';
import { WeekDayNumbers } from '@/pages/Schedule/constants/recurrence';

/**
 * Get the default recurrence `Weekday` given a date.
 */
export const getDefaultWeekday = (startDate: DateTime): Weekday => {
  // Converts Luxon indexing to RRule.
  // Luxon: 1 = Monday, 7 = Sunday
  // RRUle: 0 = Monday, 6 = Sunday
  const dayIndex = startDate.weekday - 1;
  return WeekDayNumbers[dayIndex];
};
