import type { Label } from '@/types/gql.generated';
import { Flex } from '@/ui';
import { Description } from './Description';
import { Labels } from './Labels';
import { Location } from './Location';
import { Time } from './Time';

type Props = {
  onRemoveCategory?: () => void;
  onRemoveLabel?: (label: Label) => void;
  onRemoveWhoLabel?: (label: Label) => void;
};

export const Metadata = ({
  onRemoveCategory,
  onRemoveLabel,
  onRemoveWhoLabel,
}: Props) => {
  return (
    <>
      <Flex direction="column" gap="1.5">
        <Time data-time />
        <Location data-location />
      </Flex>
      <Description data-description />
      <Labels
        onRemoveCategory={onRemoveCategory}
        onRemoveLabel={onRemoveLabel}
        onRemoveWhoLabel={onRemoveWhoLabel}
      />
    </>
  );
};
