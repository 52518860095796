import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToggleHiddenInstances } from '@/pages/Schedule/hooks';
import { type ButtonProps, IconButton, type IconProps, Tooltip } from '@/ui';
import { HiddenIcon } from '../HiddenIcon';

type Props = ButtonProps & {
  iconProps?: IconProps;
};

export const ToggleHiddenInstancesButton = ({
  iconProps,
  ...buttonProps
}: Props) => {
  const { value: hideItemsEnabled } = useFeatureFlag('hideItemsEnabled');
  const { isShowingHiddenInstances, toggleHiddenInstances } =
    useToggleHiddenInstances();
  const { t } = useTranslation('common');

  if (!hideItemsEnabled) {
    return null;
  }

  return (
    <Tooltip
      label={
        isShowingHiddenInstances
          ? t('hide_hidden_entries')
          : t('show_hidden_entries')
      }
    >
      <IconButton
        variant="secondary"
        aria-label={
          isShowingHiddenInstances
            ? t('hide_hidden_entries')
            : t('show_hidden_entries')
        }
        icon={
          <HiddenIcon isHidden={!isShowingHiddenInstances} {...iconProps} />
        }
        onClick={toggleHiddenInstances}
        onFocus={(event) => event.preventDefault()} // Tooltip fix
        {...buttonProps}
      />
    </Tooltip>
  );
};
