import { DateTime } from 'luxon';
import { Frequency, RRule, type Weekday } from 'rrule';
import type { ParsedOptions } from 'rrule/dist/esm/types';
import { TIME_ZONE_UTC } from '@/constants';
import { WeekDayNumbers } from '../../constants/recurrence';
import { getDefaultWeekday } from './getDefaultWeekday';

export type ParsedRRule = {
  interval: ParsedOptions['interval'] | null;
  frequency: ParsedOptions['freq'] | null;
  byWeekday: Weekday[] | null;
  untilDate: DateTime | null;
};

type Input = {
  rule: string | null | undefined;
  startDate: DateTime;
  isOnDay: boolean;
  entryTimeZone: string;
  scheduleTimeZone: string;
};

type ParseRRule = (input: Input) => ParsedRRule;

const NO_RECURRENCE: ParsedRRule = {
  frequency: null,
  interval: null,
  byWeekday: null,
  untilDate: null,
};

export const parseRRule: ParseRRule = ({
  rule,
  startDate,
  isOnDay,
  entryTimeZone,
  scheduleTimeZone,
}) => {
  if (!rule) {
    return NO_RECURRENCE;
  }

  const { options: rrule } = RRule.fromString(rule);

  const byWeekday =
    rrule.freq === Frequency.WEEKLY
      ? rule?.includes('BYDAY')
        ? rrule.byweekday.map((day) => WeekDayNumbers[day])
        : [getDefaultWeekday(startDate)]
      : [];

  let untilDate: DateTime | null = null;
  if (rrule.until) {
    if (isOnDay) {
      untilDate = DateTime.fromJSDate(rrule.until, {
        zone: TIME_ZONE_UTC,
      });
    } else {
      untilDate = DateTime.fromJSDate(rrule.until, {
        zone: entryTimeZone,
      }).setZone(scheduleTimeZone);
    }
  }

  return {
    frequency: rrule.freq,
    interval: rrule.interval,
    byWeekday,
    untilDate,
  };
};
