import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  type ScheduleContextValue,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import type { LabelType } from '@/types/gql.generated';
import { definitions as labelDefinitions } from '../../../utils/labelDefinitions';

type Schedule = ScheduleContextValue['schedule'];

const getLabels = (schedule: Schedule | undefined, labelType: LabelType) => {
  const lib = labelDefinitions[labelType];
  return schedule ? lib.scheduleGetter(schedule) : [];
};

export const useOptimisticLabels = (labelType: LabelType) => {
  const { schedule } = useScheduleContext();
  const [labels, setLabels] = useState(() => getLabels(schedule, labelType));

  useUpdateEffect(() => {
    setLabels(getLabels(schedule, labelType));
  }, [schedule, labelType]);

  return { labels, setLabels };
};
