import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import pick from 'lodash.pick';
import { useCallback } from 'react';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import { createEntryDate } from '@/utils/dates';
import { createEntryMessagesQueryKey } from '@/utils/queryKeys';
import type { EntryModalMessage } from '../../types';
import type {
  GetMessagesForEntryQuery,
  GetMessagesForEntryQueryVariables,
} from './useFetchEntryMessages.generated';

const query = gql`
  query GetMessagesForEntry($scheduleId: ID!, $entryId: ID!) {
    getEntry(scheduleId: $scheduleId, entryId: $entryId) {
      timeZone
      recurrences {
        isOnDay
      }
      messages {
        id
        instance
        body
        deliveryTimeType
        hybridDeliveryTime
        hybridRelativeTime
        hybridTimeZone
        relativeTimeToEntryStart
        relativeTimeToEntryStartUnit
        relativeTimeToEntryStartDirection
        status
        recipients {
          id
        }
      }
    }
  }
`;

export const useFetchEntryMessages = () => {
  const { scheduleId, timeZone: scheduleTimeZone } = useScheduleContext();
  const { draftEntry, draftInstance, draftMode } = useDraftEntryStore(
    (state) => ({
      ...pick(state, ['draftInstance', 'draftMode']),
      draftEntry: state.draftEntry!, // guaranteed to exist
    })
  );

  const enabled = draftMode === 'edit';

  const selector = useCallback(
    (data: GetMessagesForEntryQuery): EntryModalMessage[] =>
      data.getEntry.messages.map((message) => ({
        ...message,
        // Messages are associated with an entry or entry instance through its `instance`
        // property so this must match the entry's `startDate`
        instance: createEntryDate(
          message.instance,
          data.getEntry.timeZone,
          scheduleTimeZone,
          data.getEntry.recurrences[0].isOnDay
        ),
      })),
    [scheduleTimeZone]
  );

  const { data: messages, isLoading } = useQuery({
    queryKey: createEntryMessagesQueryKey(scheduleId, draftEntry.id),
    enabled,
    select: selector,
    queryFn: () =>
      gqlClient.request<
        GetMessagesForEntryQuery,
        GetMessagesForEntryQueryVariables
      >(query, {
        scheduleId,
        entryId: draftEntry.id,
      }),
  });

  const debouncedIsLoading = useDebounceLoading(isLoading, 200);

  const messagesForInstance = draftInstance?.rule
    ? messages?.filter(
        (message) =>
          message.instance.toUTC().toISO() ===
          draftInstance.startDate.toUTC().toISO()
      )
    : messages;

  return {
    messages: messages ?? [],
    messagesForInstance: messagesForInstance ?? [],
    isLoading: enabled ? debouncedIsLoading : false,
  };
};
