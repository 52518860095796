import { gql } from 'graphql-request';
import { useState } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { QuickEntriesJobFragment } from '@/pages/QuickCreate/fragments/QuickEntriesJobFragment';
import { type ErrorCodes, JobStatus } from '@/types/gql.generated';
import { ErrorWithCode, type QueryError } from '@/utils/errors';
import { createPollMagicJobQueryKey } from '@/utils/queryKeys';
import type {
  PollQuickEntriesJobsStatusQuery,
  PollQuickEntriesJobsStatusQueryVariables,
} from './usePollQuickEntriesJobStatus.generated';

const query = gql`
  ${QuickEntriesJobFragment}
  query PollQuickEntriesJobsStatus($quickEntriesJobId: ID!) {
    getQuickEntriesJob(quickEntriesJobId: $quickEntriesJobId) {
      ...QuickEntriesJob
    }
  }
`;

type State = {
  isLoading: boolean;
  error: Error | QueryError | null;
};

export const usePollQuickEntriesJobsStatus = () => {
  const [state, setState] = useState<State>({
    error: null,
    isLoading: false,
  });

  const pollUntilJobComplete = async (
    quickEntriesJobId: string
  ): Promise<PollQuickEntriesJobsStatusQuery['getQuickEntriesJob']> => {
    setState({ isLoading: true, error: null });

    return new Promise((resolve, reject) => {
      const poll = async () => {
        try {
          const { getQuickEntriesJob: job } = await queryClient.fetchQuery({
            queryKey: createPollMagicJobQueryKey(quickEntriesJobId),
            queryFn: () => {
              return gqlClient.request<
                PollQuickEntriesJobsStatusQuery,
                PollQuickEntriesJobsStatusQueryVariables
              >(query, { quickEntriesJobId });
            },
          });

          if (job.status === JobStatus.Failed) {
            const message = job.errorMessage || job.errorCode || 'Job failed';
            throw new ErrorWithCode(message, job.errorCode as ErrorCodes);
          }

          if (job.status !== JobStatus.Reviewed) {
            setTimeout(poll, 1000);
            return;
          }

          setState({ isLoading: false, error: null });
          resolve(job);
        } catch (err) {
          const error = err as State['error'];
          setState({ isLoading: false, error });
          reject(err);
        }
      };

      poll();
    });
  };

  const reset = () => {
    setState({ isLoading: false, error: null });
  };

  return { pollUntilJobComplete, reset, ...state };
};
