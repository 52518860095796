import { CheckIcon } from '@chakra-ui/icons';
import { type ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotionFlex } from '@/ui';
import { SendToButton } from './SendToButton';

type Props = ComponentProps<typeof SendToButton> & {
  href: string; // make required
};

export const SendToOtherButton = ({ href, onClick, ...props }: Props) => {
  const [didCopy, setDidCopy] = useState(false);
  const { t } = useTranslation('addToCalendarModal');

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (didCopy) {
      timer = setTimeout(() => setDidCopy(false), 2000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [didCopy]);

  return (
    <SendToButton
      as="button"
      {...(didCopy && {
        bg: 'green.50',
        align: 'center',
        justify: 'center',
        color: 'green.600',
        fontWeight: 'medium',
        _hover: {
          bg: 'green.50',
        },
      })}
      onClick={(event) => {
        if (!didCopy) {
          navigator.clipboard.writeText(href);
          setDidCopy(true);
        }

        onClick?.(event);
      }}
      {...props}
    >
      {didCopy && (
        <MotionFlex
          align="center"
          animate={{ opacity: 1 }}
          gap="3"
          initial={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
        >
          <CheckIcon boxSize="3" /> {t('link_copied')}
        </MotionFlex>
      )}
    </SendToButton>
  );
};
