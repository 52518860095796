import { useTranslation } from 'react-i18next';
import { TbDots } from 'react-icons/tb';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToken,
  useColorModeValue,
} from '@/ui';

type Props = {
  onEdit: () => void;
  onDelete: () => void;
};

export const ManageMenu = ({ onEdit, onDelete }: Props) => {
  const { t } = useTranslation('labelSelect');
  const [iconColorLight, iconColorDark] = useToken('colors', [
    'gray.500',
    'gray.800',
  ]);
  const iconColor = useColorModeValue(iconColorLight, iconColorDark);
  const hoverBackgroundColor = useColorModeValue('gray.300', 'customgray.dark');

  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        _focusVisible={{ outline: '1px solid', outlineColor: 'brand.500' }}
        _hover={{ bg: hoverBackgroundColor }}
        aria-label={t('manage')}
        as={IconButton}
        colorScheme="dark"
        icon={<TbDots color={iconColor} size="16px" />}
        size="xs"
        variant="ghost"
      />
      <MenuList w="10">
        <MenuItem onClick={onEdit}>{t('edit')}</MenuItem>
        <MenuItem onClick={onDelete}>{t('delete')}</MenuItem>
      </MenuList>
    </Menu>
  );
};
