import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import {
  createIntegrationsQueryKey,
  createSchedulesQueryKey,
} from '@/utils/queryKeys';
import type {
  RemoveIntegrationMutation,
  RemoveIntegrationMutationVariables,
} from './useRemoveIntegration.generated';

const query = gql`
  mutation RemoveIntegration($id: ID!) {
    deleteIntegration(id: $id)
  }
`;

export const useRemoveIntegration = () => {
  const { mutate, isPending } = useMutation<
    RemoveIntegrationMutation,
    QueryError,
    RemoveIntegrationMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        RemoveIntegrationMutation,
        RemoveIntegrationMutationVariables
      >(query, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createSchedulesQueryKey(),
      });
      return queryClient.invalidateQueries({
        queryKey: createIntegrationsQueryKey(),
      });
    },
  });

  const removeIntegration = useCallback(
    (
      id: string,
      options?: MutateOptions<
        RemoveIntegrationMutation,
        QueryError,
        RemoveIntegrationMutationVariables
      >
    ) => mutate({ id }, options),
    [mutate]
  );

  return { removeIntegration, isPending };
};
