import { EntriesJobType, QuickEntriesJobType } from '@/types/gql.generated';
import type { ErrorWithCode, QueryError } from '@/utils/errors';

export type PromptType = QuickEntriesJobType | EntriesJobType;

export type ErrorInput = QueryError | ErrorWithCode;

export const isFileType = (type?: PromptType): boolean => {
  return type === QuickEntriesJobType.File || type === EntriesJobType.File;
};
