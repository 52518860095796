import { useQueryNormalizer } from '@normy/react-query';
import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  GenerateScheduleFeedSecretMutation,
  GenerateScheduleFeedSecretMutationVariables,
} from './useGenerateScheduleFeedSecret.generated';

const query = gql`
  mutation GenerateScheduleFeedSecret($scheduleId: ID!) {
    generateFeedSecret(scheduleId: $scheduleId)
  }
`;

export const useGenerateScheduleFeedSecret = () => {
  const { scheduleId } = useScheduleContext();
  const queryNormalizer = useQueryNormalizer();

  const { mutate, isPending } = useMutation<
    GenerateScheduleFeedSecretMutation,
    QueryError,
    GenerateScheduleFeedSecretMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        GenerateScheduleFeedSecretMutation,
        GenerateScheduleFeedSecretMutationVariables
      >(query, variables),
    onSuccess: (data) => {
      if (data.generateFeedSecret) {
        queryNormalizer.setNormalizedData({
          id: scheduleId,
          feedSecret: {
            slug: data.generateFeedSecret,
          },
        });
      }
    },
  });

  const generateSecret = (
    options?: MutateOptions<
      GenerateScheduleFeedSecretMutation,
      QueryError,
      GenerateScheduleFeedSecretMutationVariables
    >
  ) => mutate({ scheduleId }, options);

  return {
    generateSecret,
    isPending,
  };
};
