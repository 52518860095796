import type { ReactNode } from 'react';
import FocusLock from 'react-focus-lock';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  StackDivider,
  type StackProps,
  useColorModeValue,
  VStack,
} from '@/ui';
import { PopoverHeader } from './PopoverHeader';
import { usePopoverContext } from './usePopoverContext';

type Props = {
  title?: ReactNode;
  children: ReactNode;
  action?: ReactNode;
  secondaryAction?: ReactNode;
  panelProps?: StackProps;
  disableFocusLock?: boolean;
};

export const PopoverPanel = ({
  title,
  children,
  action,
  secondaryAction,
  panelProps,
  disableFocusLock,
}: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { isOpen, onClose } = usePopoverContext();
  const dividerColor = useColorModeValue(
    'rgba(0, 0, 0, 0.1)',
    'customgray.middark'
  );

  if (isMobileBreakpoint) {
    // Mobile always gets a modal
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalContent m="0" minH="100vh" minW="100vw" overflow="hidden">
          <FocusLock
            disabled={disableFocusLock}
            persistentFocus={false}
            returnFocus
          >
            {/* Header, deliberately outside the body */}
            <PopoverHeader
              action={action}
              secondaryAction={secondaryAction}
              title={title}
            />
            <ModalBody px="0" {...panelProps}>
              {children}
            </ModalBody>
          </FocusLock>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Box display="block" pos="relative" w="100%">
      <FocusLock
        disabled={disableFocusLock}
        persistentFocus={false}
        returnFocus={false}
      >
        <VStack
          align="stretch"
          divider={<StackDivider borderColor={dividerColor} />}
          flexGrow="1"
          maxH="300px"
          overflowY="auto"
          spacing="0"
          w="100%"
          {...panelProps}
        >
          {(title || action || secondaryAction) && (
            <PopoverHeader
              action={action}
              secondaryAction={secondaryAction}
              title={title}
            />
          )}

          <VStack align="stretch" spacing="0">
            {children}
          </VStack>
        </VStack>
      </FocusLock>
    </Box>
  );
};
