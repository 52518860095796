import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import {
  createProfileQueryKey,
  createScheduleCollaboratorsQueryKey,
} from '@/utils/queryKeys';
import type {
  TransferOwnershipMutation,
  TransferOwnershipMutationVariables,
} from './useTransferOwnership.generated';

const query = gql`
  mutation TransferOwnership($userId: ID!, $scheduleId: ID!) {
    transferOwnership(userId: $userId, scheduleId: $scheduleId)
  }
`;

export const useTransferOwnership = () => {
  const { scheduleId } = useScheduleContext();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation<
    TransferOwnershipMutation,
    QueryError,
    TransferOwnershipMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        TransferOwnershipMutation,
        TransferOwnershipMutationVariables
      >(query, variables);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: createProfileQueryKey() });

      return queryClient.invalidateQueries({
        queryKey: createScheduleCollaboratorsQueryKey(scheduleId),
      });
    },
  });

  const transferOwnership = (
    userId: string,
    options?: MutateOptions<
      TransferOwnershipMutation,
      QueryError,
      TransferOwnershipMutationVariables
    >
  ) => mutate({ scheduleId, userId }, options);

  return {
    transferOwnership,
    isPending,
  };
};
