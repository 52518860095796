import { offset, useFloating } from '@floating-ui/react';
import { type MouseEvent, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import {
  Box,
  type BoxProps,
  Button,
  forwardRef,
  useToken,
  Flex,
  Portal,
} from '@/ui';

type Props = BoxProps & {
  value?: string | null;
  onClick: () => void;
  onRemoveClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

const CLEAR_BUTTON_SIZE = 24;

export const EmojiButton = forwardRef(
  ({ value, onClick, onRemoveClick, ...props }: Props, ref) => {
    const [brandColor] = useToken('colors', ['brand.500']);
    const [hover, setHover] = useState(false);

    const { refs, floatingStyles } = useFloating({
      placement: 'top-end',
      middleware: [offset({ mainAxis: -CLEAR_BUTTON_SIZE })],
    });

    return (
      <Flex
        align="end"
        boxSize={`${56 + 12}px`}
        data-group
        flex="none"
        justify="start"
        ref={refs.setReference}
        role="button"
        onMouseOut={() => setHover(false)}
        onMouseOver={() => setHover(true)}
        {...props}
      >
        <Button
          _focusVisible={{ outline: `1px solid ${brandColor}` }}
          alignItems="center"
          borderColor="transparent"
          borderStyle={value ? 'solid' : 'dashed'}
          borderWidth="1px"
          display="flex"
          fontSize="38px"
          h="56px"
          justifyContent="center"
          lineHeight="1"
          ref={ref}
          variant="unstyled"
          w="56px"
          _groupHover={{
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.08)',
            borderColor: 'rgba(0, 0, 0, 0.1)',
          }}
          onClick={onClick}
        >
          <Box
            filter={value ? 'none' : 'grayscale(100%)'}
            transform="translateY(2px)"
          >
            {value || <>😄</>}
          </Box>
        </Button>

        {value && (
          <Portal>
            <Button
              _hover={{ bg: 'gray.300' }}
              bg="customgray.gray"
              borderRadius="full"
              boxSize={`${CLEAR_BUTTON_SIZE}px`}
              minW="auto"
              opacity={hover ? 1 : 0}
              ref={refs.setFloating}
              style={floatingStyles}
              transition="all 0.2s ease"
              variant="unstyled"
              zIndex="calc(var(--chakra-zIndices-modal) + 1)"
              onClick={onRemoveClick}
            >
              <IoIosClose size="24" />
            </Button>
          </Portal>
        )}
      </Flex>
    );
  }
);
