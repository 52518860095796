import type { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { notionVariantBaseStyles } from '@/theme/styles';
import { defineStyleConfig } from '@/ui';

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
    fontWeight: 'medium',
    lineHeight: 'normal',
    _focus: {
      boxShadow: 'none',
    },
    _hover: {
      _disabled: {
        bg: undefined,
      },
    },
  },
  sizes: {
    md: {
      fontSize: 14,
    },
  },
  variants: {
    secondary: () => {
      const boxShadow = '0 0 5px rgba(0, 0, 0, 0.08)';

      return {
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, .1)',
        color: 'dark.500',
        _hover: {
          borderColor: 'rgba(0, 0, 0, .15)',
          boxShadow,
        },
        _focus: {
          borderColor: 'rgba(0, 0, 0, .15)',
          _hover: {
            boxShadow,
          },
        },
        _active: {
          borderColor: 'rgba(0, 0, 0, .15)',
          boxShadow,
        },
        _dark: {
          borderColor: 'rgba(255, 255, 255, .15)',
          color: 'white',
        },
      };
    },
    ghost: (props: StyleFunctionProps) => {
      if (props.colorMode === 'dark') {
        return {
          _hover: {
            bg: 'customgray.evendarker',
          },
          _active: {
            bg: 'customgray.evendarker',
          },
        };
      }
      if (props.colorScheme === 'dark') {
        return {
          color: 'customgray.textdark',
          _hover: {
            color: 'customgray.textdark',
            bg: props.isDisabled ? 'none' : 'gray.100',
          },
          _active: {
            color: 'customgray.textdark',
            bg: props.isDisabled ? 'none' : 'customgray.light',
          },
        };
      }
      return {
        color: 'brand.500',
        _hover: {
          color: 'brand.600',
          bg: 'brand.40',
        },
        _active: {
          color: 'brand.600',
          bg: 'brand.50',
        },
      };
    },
    notion: notionVariantBaseStyles,
    outline: {
      borderColor: 'brand.500',
      color: 'brand.500',
      _hover: {
        bg: 'brand.500',
        color: 'white',
        _disabled: {
          color: 'brand.500',
        },
      },
      _active: {
        bg: 'brand.500',
        color: 'white',
      },
    },
    shadow: (props: StyleFunctionProps) => {
      const disabled = props.isDisabled || props.isLoading;

      const common = {
        borderWidth: '1px',
        borderColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '6px',
        shadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.12)',
        bg: 'white',
        _hover: !disabled && {
          bg: 'gray.50',
          shadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.22)',
        },
        _active: !disabled && {
          bg: 'gray.100',
          shadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.22)',
        },
        _focus: !disabled && {
          bg: 'gray.50',
          shadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.22)',
        },
      };

      if (props.colorScheme === 'brand') {
        return {
          ...common,
          borderColor: 'brand.100',
          color: 'brand.500',
          _hover: !disabled && {
            ...common._hover,
            borderColor: 'brand.500',
            color: 'white',
            bg: 'brand.500',
          },
          _active: !disabled && {
            ...common._active,
            borderColor: 'brand.600',
            color: 'white',
            bg: 'brand.600',
          },
          _focus: !disabled && {
            ...common._focus,
            borderColor: 'brand.500',
            color: 'white',
            bg: 'brand.500',
          },
        };
      } else if (props.colorScheme === 'green') {
        const active = !disabled && {
          ...common._active,
          borderColor: 'green.600',
          bg: 'green.600',
        };
        return {
          ...common,
          borderColor: 'green.500',
          color: 'white',
          bg: 'green.500',
          _hover: active,
          _active: active,
          _focus: active,
        };
      }

      return common;
    },
  },
});
