import type { Editor } from '@tiptap/react';
import { Suspense, lazy, forwardRef } from 'react';
import { SkeletonText } from '@/ui';
import type { RichTextEditorProps } from './RichTextEditor';

const LazyRichTextEditor = lazy(() => {
  return Promise.all([
    import('./RichTextEditor'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});

type Props = RichTextEditorProps & {
  loader?: JSX.Element | null;
};

export const RichTextEditor = forwardRef<Editor, Props>(
  function RichTextEditorWithRef(
    {
      loader = <SkeletonText noOfLines={2} skeletonHeight="2" w="100%" />,
      ...props
    },
    ref
  ) {
    return (
      <Suspense fallback={loader}>
        <LazyRichTextEditor {...props} ref={ref} />
      </Suspense>
    );
  }
);
