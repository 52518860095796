import { gql } from 'graphql-request';
import type { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { gqlWsClient, handleError } from '@/lib/gql-client';
import { queryClient } from '@/lib/query-client';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import type { EntryDeletedSubscription } from './useEntryDeletedSubscription.generated';

const query = gql`
  subscription EntryDeleted($scheduleId: ID!) {
    entryDeleted(scheduleId: $scheduleId)
  }
`;

export const useEntryDeletedSubscription = (scheduleId: string) => {
  const handleData = useCallback(
    ({ data }: ExecutionResult<EntryDeletedSubscription>) => {
      if (data?.entryDeleted) {
        queryClient.invalidateQueries({
          queryKey: createEntriesQueryKey(scheduleId),
        });
      }
    },
    [scheduleId]
  );

  useEffect(() => {
    const unsubscribe = gqlWsClient.subscribe<EntryDeletedSubscription>(
      {
        query: query,
        variables: {
          scheduleId,
        },
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, handleData]);
};
