import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { OnboardingFlow } from './types';

type Config = {
  flow?: OnboardingFlow;
};

type State = {
  isOpen: boolean;
  open: (config?: Config) => void;
  close: () => void;
  flow: OnboardingFlow | null;
  setFlow: (flow: OnboardingFlow | null) => void;
  reset: () => void;
};

export const useOnboardingModalStore = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    open: (config) => set({ isOpen: true, flow: config?.flow ?? null }),
    close: () => set({ isOpen: false, flow: null }),
    flow: null,
    setFlow: (flow) => set({ flow }),
    reset: () => set({ isOpen: false, flow: null }),
  }),
  shallow
);
