import { Flex, type FlexProps } from '@/ui';
import { Title } from './Title';

type Props = Omit<FlexProps, 'children'> & {
  buttons?: Array<JSX.Element | false | undefined>;
  buttonsContainerMaxWidth?: string;
};

export const Header = ({
  buttons,
  buttonsContainerMaxWidth = '125px',
  ...props
}: Props) => {
  return (
    <Flex gap="4" justify="space-between" pos="relative" {...props}>
      <Title maxW={`calc(100% - ${buttonsContainerMaxWidth})`} />

      {buttons && (
        <Flex
          align="center"
          gap="1"
          maxW={buttonsContainerMaxWidth}
          pos="absolute"
          right="0"
          top="-0.5"
        >
          {buttons.filter((button) => Boolean(button))}
        </Flex>
      )}
    </Flex>
  );
};
