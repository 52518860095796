import type { MouseEventHandler, ReactNode } from 'react';
import { Box, Link, List, ListItem } from '@/ui';

type NavLinkProps = {
  href?: string;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  primary?: boolean;
};

type NavProps = {
  children: ReactNode;
};

export const NavLink = ({
  href,
  children,
  onClick,
  primary = false,
}: NavLinkProps) => {
  return (
    <ListItem>
      <Link
        color={primary ? 'brand.500' : 'text'}
        fontWeight="bold"
        href={href}
        transition="color 0.1s ease-in-out"
        whiteSpace="nowrap"
        _hover={{
          textDecoration: 'none',
          color: primary ? 'brand.600' : 'black',
        }}
        onClick={onClick}
      >
        {children}
      </Link>
    </ListItem>
  );
};

export const Nav = ({ children }: NavProps) => {
  return (
    <Box as="nav">
      <List display="flex" gap="4" variant="unstyled">
        {children}
      </List>
    </Box>
  );
};
