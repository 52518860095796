import { useTranslation } from 'react-i18next';
import { AuthAction } from '@/types/gql.generated';
import { AuthHeader } from '../AuthHeader';
import type { AuthHeaderRenderProp } from '../types';

type Props = {
  authAction: AuthAction | null;
  renderHeader?: AuthHeaderRenderProp;
};

export const AuthHeaderRenderer = ({ authAction, renderHeader }: Props) => {
  const { t } = useTranslation('auth');

  if (renderHeader) {
    return renderHeader({
      authAction,
    });
  }

  let title = '';
  let byline = '';

  if (authAction === null) {
    title = t('sign_in_or_create');
  } else if (authAction === AuthAction.Register) {
    title = t('create_free_account');
  } else if (authAction === AuthAction.LoginPassword) {
    title = t('welcome_back');
    byline = t('sign_into_account');
  } else if (authAction === AuthAction.LoginGoogle) {
    title = t('welcome_back');
    byline = t('sign_in_with_google');
  }

  if (title) {
    return <AuthHeader title={title}>{byline}</AuthHeader>;
  }

  return null;
};
