import { useTranslation } from 'react-i18next';
import { ModalHeader as ChakraModalHeader } from '@/ui';

export const ModalHeader = () => {
  const { t } = useTranslation('addContent');

  return (
    <ChakraModalHeader
      borderBottomColor="customgray.gray"
      borderBottomWidth="1px"
      fontWeight="medium"
      lineHeight="1"
      pb="6"
      pt="7"
      px={{ base: 6, md: 8 }}
    >
      {t('title')}
    </ChakraModalHeader>
  );
};
