import type { ReactNode } from 'react';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { createContext } from '@/utils';

type ItemCardContextValue = {
  entry: DecoratedEntry;
  instance?: DecoratedInstance;
};

type Props = ItemCardContextValue & {
  children: ReactNode;
};

export const ItemCardContext = createContext<ItemCardContextValue | undefined>(
  undefined,
  'ItemCardContext'
);

export const ItemCardContextProvider = ({
  entry,
  instance,
  children,
}: Props) => {
  return (
    <ItemCardContext.Provider value={{ entry, instance }}>
      {children}
    </ItemCardContext.Provider>
  );
};
