import { create } from 'zustand';
import type { Ingredient, SnippetInUse } from './types';

type State = {
  isOpen: boolean;
  ingredient: Ingredient | null;
  snippets: SnippetInUse[];
  open: (ingredient: Ingredient, snippets: SnippetInUse[]) => void;
  close: () => void;
};

// Modals can't be triggered from a Popover, which is where labels live,
// so the SnippetIngredientInUseModal is mounted to the DOM outside of any popovers
// and triggered via this stateful hook
export const useSnippetIngredientInUseModal = create<State>()((set) => ({
  isOpen: false,
  ingredient: null,
  snippets: [],
  open: (ingredient: Ingredient, snippets: SnippetInUse[]) =>
    set({ isOpen: true, ingredient, snippets }),
  close: () => set({ isOpen: false, snippets: [] }),
}));
