import orderBy from 'lodash.orderby';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '@/contexts';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useScheduleCollaborators } from '@/pages/Schedule/hooks';
import { AvatarStack } from '@/ui';
import type { BoxProps } from '@/ui';

export const SharedUsersStack = (props: BoxProps) => {
  const { currentUser } = useCurrentUserContext();
  const { schedule, isPublicRole } = useScheduleContext();
  const { collaborators } = useScheduleCollaborators();
  const { t } = useTranslation('header');

  if (!schedule || isPublicRole) {
    return null;
  }

  // The owner is the only one on the schedule
  if (collaborators.length === 1) {
    return null;
  }

  const users = orderBy(
    collaborators,
    [
      ({ id }) => id === currentUser?.id,
      ({ id }) => schedule.activeUsers.includes(id),
    ],
    ['desc', 'desc']
  ).map((user) => ({
    ...user,
    isDimmed:
      currentUser?.id !== user.id && !schedule.activeUsers.includes(user.id),
  }));

  return (
    <AvatarStack
      aria-label={t('schedule.shared_users_stack.aria')}
      users={users}
      {...props}
    />
  );
};
