import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';

type State = {
  isOpen: boolean;
  integration: DecoratedIntegration | null;
  open: (integration: DecoratedIntegration) => void;
  close: () => void;
};

const useDisconnectDialogStore = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    integration: null,
    open: (integration) => set({ isOpen: true, integration }),
    close: () => set({ isOpen: false, integration: null }),
  }),
  shallow
);

export const useDisconnectDialog = () => {
  return useDisconnectDialogStore((state) => state);
};
