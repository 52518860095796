import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { List, StackDivider, VStack } from '@/ui';
import { Benefits } from './Benefits';
import { IntegrationItem } from './IntegrationItem';

type ListProps = {
  integrations: DecoratedIntegration[];
};

export const IntegrationList = ({ integrations }: ListProps) => {
  if (integrations.length === 0) {
    return <Benefits mb="2" />;
  }

  return (
    <List
      alignItems="stretch"
      as={VStack}
      divider={<StackDivider borderColor="gray.100" pt="2" />}
      mb="4"
      spacing="1"
      w="100%"
    >
      {integrations.map((integration) => {
        return (
          <IntegrationItem integration={integration} key={integration.id} />
        );
      })}
    </List>
  );
};
