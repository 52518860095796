import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import {
  AuthFormStack,
  GoogleSignInButton,
  PasswordInput,
} from '@/components/auth';
import { useCurrentUserContext } from '@/contexts';
import { Sentry } from '@/lib';
import { AuthAction } from '@/types/gql.generated';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import {
  Flex,
  Button,
  Divider,
  FormControl,
  Input,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  Alert,
  AlertIcon,
  AlertTitle,
  forwardRef,
  Box,
  Link,
  FormLabel,
  Text,
} from '@/ui';
import { getErrorMessage } from '@/utils/errors';
import { isValidEmail } from '@/utils/validation';
import { AuthHeaderRenderer } from './hooks/AuthHeaderRenderer';
import { useAuthAction } from './hooks/useAuthAction';
import { useAuthenticate } from './hooks/useAuthenticate';
import { ResetPasswordLink } from './ResetPasswordLink';
import { Terms } from './Terms';
import type { AuthResult, AuthHeaderRenderProp } from './types';

type GoogleProps = {
  googleOnly?: boolean;
  googleFirst?: boolean;
  authorizeCalendar?: boolean;
};

type Props = {
  redirectTo?: string;
  renderHeader?: AuthHeaderRenderProp;
  defaults?: { email?: string };
  googleOptions?: GoogleProps;
  fullWidth?: boolean;
  hideLogo?: boolean;
  onAuthenticated?: (
    result: AuthResult,
    data: ReturnType<typeof useAuthenticate>['data'] | undefined
  ) => void;
};

type FormValues = {
  email: string;
  name: string;
  password: string;
};

export const AuthPanel = ({
  redirectTo,
  defaults,
  renderHeader,
  fullWidth,
  hideLogo,
  googleOptions = {},
  onAuthenticated,
}: Props): JSX.Element => {
  const { t } = useTranslation('auth');
  const { currentUser } = useCurrentUserContext();
  const { getAuthAction, isLoading: isLoadingAuthAction } = useAuthAction();
  const [authAction, setAuthAction] = useState<null | AuthAction>(null);
  const {
    authorizeCalendar = false,
    googleFirst = false,
    googleOnly = false,
  } = googleOptions;

  const {
    authenticate,
    isLoading: isAuthenticating,
    result: authResult,
    data: authData,
    error: authError,
    reset: resetAuthenticate,
  } = useAuthenticate();

  const {
    register,
    handleSubmit: onSubmit,
    control,
    reset: resetForm,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: defaults?.email || '',
      password: '',
    },
  });

  useEffect(() => {
    if (defaults?.email) {
      setValue('email', defaults.email);
    }
  }, [defaults?.email, setValue]);

  // reset form validation when the authAction changes
  useEffect(() => {
    resetForm(undefined, { keepValues: true });
    resetAuthenticate();
  }, [authAction, resetForm, resetAuthenticate]);

  useEffect(() => {
    if (currentUser && authResult) {
      // Important that we notify consumers of authentication only after react's
      // state has settled and `currentUser` becomes available
      onAuthenticated?.(authResult, authData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, authResult, authData]);

  const handleSubmit = async (values: FormValues) => {
    if (!authAction) {
      const authAction = await getAuthAction(values.email);
      setAuthAction(authAction);
    } else if (
      authAction === AuthAction.LoginPassword ||
      authAction === AuthAction.Register
    ) {
      authenticate(authAction, values);
    } else {
      Sentry.captureException(
        new Error(`Auth form submitted with unsupported action: ${authAction}`)
      );
    }
  };

  const nameControl = (
    <FormControl isInvalid={!!errors.name} variant="floating">
      <Input
        autoComplete="name"
        autoFocus={authAction === AuthAction.Register}
        bg="white"
        id="name"
        placeholder=" " // intentional space for floating labels
        {...register('name', {
          validate: {
            name: (value) => {
              if (authAction !== AuthAction.Register) {
                return true;
              }
              if (!value.trim().length) {
                return t('validation.required');
              }
            },
          },
        })}
      />
      <FormLabel color="customgray.mid" htmlFor="name">
        {t('name')}
      </FormLabel>
      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
    </FormControl>
  );

  const emailControl = (authAction === null ||
    authAction === AuthAction.Register ||
    authAction === AuthAction.LoginPassword) && (
    <FormControl isInvalid={!!errors.email} variant="floating">
      <InputGroup>
        <Input
          _readOnly={{ opacity: 0.4, cursor: 'default' }}
          autoComplete="username"
          autoFocus={authAction === null}
          bg="white"
          id="email"
          placeholder=" " // intentional space for floating labels
          readOnly={authAction !== null}
          type="email"
          _focusWithin={{
            _readOnly: {
              borderColor: 'gray.300',
              shadow: 'none',
            },
          }}
          {...register('email', {
            required: t('validation.required'),
            validate: {
              email: (value) =>
                isValidEmail(value) || t('validation.email_invalid'),
            },
          })}
        />
        <FormLabel color="customgray.mid" htmlFor="email">
          {t('email')}
        </FormLabel>
        {authAction !== null && (
          <InputRightElement pt="2">
            <Button
              colorScheme="dark"
              mr="1"
              size="sm"
              variant="ghost"
              onClick={() => setAuthAction(null)}
            >
              {t('edit_email')}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
    </FormControl>
  );

  const passwordControl = (
    <FormControl isInvalid={!!errors.password} variant="floating">
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <PasswordInput
            autoFocus={authAction === AuthAction.LoginPassword}
            bg="white"
            id="password"
            placeholder={t('password')}
            autoComplete={
              authAction === AuthAction.Register
                ? 'new-password'
                : 'current-password'
            }
            {...field}
          />
        )}
        rules={{
          validate: {
            password: (value) => {
              if (authAction === AuthAction.LoginGoogle) {
                return true;
              }
              if (!value.trim().length) {
                return t('validation.required');
              }
              if (value.length < 8) {
                return t('validation.password_too_short');
              }
            },
          },
        }}
      />
      <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
    </FormControl>
  );

  const serverErrors = authError && (
    <Alert status="error">
      <AlertIcon />
      <AlertTitle>{getErrorMessage(authError)}</AlertTitle>
    </Alert>
  );

  return (
    <AuthFormStack
      fullWidth={fullWidth}
      hideLogo={hideLogo}
      header={
        <AuthHeaderRenderer
          authAction={authAction}
          renderHeader={renderHeader}
        />
      }
      onSubmit={onSubmit(handleSubmit)}
    >
      {serverErrors}

      <AnimatePresence mode="popLayout">
        {!authAction && !googleFirst && !googleOnly ? (
          <MotionContainer key="default">
            {emailControl}
            <Button isLoading={isLoadingAuthAction} type="submit">
              {t('continue')}
            </Button>
            <Flex align="center" my="4" pos="relative">
              <Divider borderColor="gray.400" />
              <Text px="4">{t('or')}</Text>
              <Divider borderColor="gray.400" />
            </Flex>
            <GoogleSignInButton
              redirectTo={redirectTo}
              type={authorizeCalendar ? 'calendar' : 'login'}
            />
            <Terms mt="1" />
          </MotionContainer>
        ) : !authAction ? (
          <MotionContainer key="default">
            <GoogleSignInButton
              redirectTo={redirectTo}
              type={authorizeCalendar ? 'calendar' : 'login'}
            />
            {!googleOnly && (
              <>
                <Flex align="center" my="4" pos="relative">
                  <Divider borderColor="gray.400" />
                  <Text px="4">{t('or')}</Text>
                  <Divider borderColor="gray.400" />
                </Flex>
                {emailControl}
                <Button isLoading={isLoadingAuthAction} type="submit">
                  {t('continue')}
                </Button>
              </>
            )}
            <Terms mt="1" />
          </MotionContainer>
        ) : authAction === AuthAction.Register ? (
          <>
            {emailControl}
            <MotionContainer key={AuthAction.Register}>
              {nameControl}
              {passwordControl}
              <Button isLoading={isAuthenticating} type="submit">
                {t('create_account')}
              </Button>
              <Terms />
            </MotionContainer>
          </>
        ) : authAction === AuthAction.LoginGoogle ? (
          <>
            {emailControl}
            <MotionContainer key={AuthAction.LoginGoogle}>
              <GoogleSignInButton
                redirectTo={redirectTo}
                type={authorizeCalendar ? 'calendar' : 'login'}
              />
              <Box color="customgray.mid" fontSize="sm">
                <Trans i18nKey="sign_in_with_google_go_back" t={t}>
                  If you&apos;d like to use a different email address,{' '}
                  <Link
                    colorScheme="dark"
                    onClick={(event) => {
                      event.preventDefault();
                      setAuthAction(null);
                    }}
                  >
                    go back
                  </Link>
                </Trans>
              </Box>
            </MotionContainer>
          </>
        ) : authAction === AuthAction.LoginPassword ? (
          <>
            {emailControl}
            <MotionContainer key={AuthAction.LoginPassword}>
              {passwordControl}
              <Box textAlign="left">
                <ResetPasswordLink fontSize="sm" />
              </Box>
              <Button isLoading={isAuthenticating} type="submit">
                {t('sign_in')}
              </Button>
            </MotionContainer>
          </>
        ) : null}
      </AnimatePresence>
    </AuthFormStack>
  );
};

const MotionContainer = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    animate={{ opacity: 1 }}
    direction="column"
    exit={{ opacity: 0 }}
    flex="1"
    gap="3"
    initial={{ opacity: 0 }}
    overflow="hidden"
    ref={ref}
    transition={{ duration: 0.1 }}
    {...props}
  />
));
