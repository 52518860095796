import type { DecoratedEntry } from '@/pages/Schedule/types';
import { NotesButton } from '../../NotesButton';
import { useRsvp } from '../hooks/useRsvp';

type Props = {
  entry: DecoratedEntry;
};

export const RsvpNotesButton = ({ entry }: Props) => {
  const { setNotes, isPending } = useRsvp(entry);

  return (
    <NotesButton
      isLoading={isPending}
      note={entry.rsvpInfo?.notes || ''}
      onSubmit={(value: string | null, close) => {
        setNotes(entry.id, value, { onSuccess: close });
      }}
    />
  );
};
