import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Button, Icon, IconButton, type IconButtonProps, Text } from '@/ui';
import { useAddContentModalStore } from '../AddContentModal';
import { Trigger } from './Trigger';

type Props = {
  buttonComponent?: JSX.Element;
} & Omit<IconButtonProps, 'aria-label' | 'onClick'>;

export const AddContentButton = forwardRef(function CreateButtonWithRef(
  { buttonComponent, ...buttonProps }: Props,
  ref
) {
  const { t } = useTranslation('addContent');
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { open } = useAddContentModalStore();

  if (buttonComponent) {
    return (
      <Trigger {...buttonProps} onClick={open}>
        {buttonComponent}
      </Trigger>
    );
  }

  if (isMobileBreakpoint) {
    return (
      <IconButton
        aria-label={t('trigger_button')}
        borderRadius="full"
        colorScheme="dark"
        icon={<Icon color="white" icon="Plus" size="5" />}
        {...buttonProps}
        onClick={() => open()}
      />
    );
  }

  return (
    <Button
      _focus={{ borderColor: 'yellow.400' }}
      _focusVisible={{ borderColor: 'yellow.400' }}
      _hover={{ borderColor: 'yellow.400' }}
      colorScheme="dark"
      leftIcon={<Text lineHeight="normal">✨</Text>}
      ref={ref}
      {...buttonProps}
      onClick={() => open()}
    >
      {t('trigger_button')}
    </Button>
  );
});
