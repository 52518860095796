import {
  type Category,
  CategorySelect,
  CategorySelectContextProvider,
} from '@/pages/Schedule/components';
import { Menu, MenuButton, MenuList, Icons } from '@/ui';
import { FooterButton } from './FooterButton';

type Props = {
  selected: Category | null | undefined;
  onSelect: (category: Category | null) => void;
};

export const CategoryPicker = ({ selected, onSelect }: Props) => {
  return (
    <CategorySelectContextProvider>
      <Menu isLazy>
        {({ onClose }) => (
          <>
            <MenuButton
              as={FooterButton}
              label="Category"
              leftIcon={Icons.Palette}
            />

            <MenuList minW="350px" p="0">
              <CategorySelect
                selected={selected}
                onClose={onClose}
                onSelect={(category) => {
                  onSelect(category);
                  onClose();
                }}
              />
            </MenuList>
          </>
        )}
      </Menu>
    </CategorySelectContextProvider>
  );
};
