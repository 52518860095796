import { useTranslation } from 'react-i18next';
import { Box, Flex, type FlexProps, Text } from '@/ui';
import { useItemCardContext } from '../context/useItemCardContext';

export const Title = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();
  const { t } = useTranslation('schedule');

  const emoji = instance?.emoji || entry.emoji;
  const title =
    instance?.title || entry.title || t('untitled_entry', { ns: 'common' });

  return (
    <Flex align="flex-start" fontSize="20px" gap="2.5" {...props}>
      {emoji && (
        <Box
          h="22px"
          left="-2px"
          pos="relative"
          textAlign="center"
          top="-1px"
          w="20px"
        >
          {emoji}
        </Box>
      )}
      <Text
        data-title
        fontWeight="700"
        lineHeight="22px"
        overflowWrap="anywhere"
      >
        {title}
      </Text>
    </Flex>
  );
};
