import {
  Flex,
  type FlexProps,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  type TextProps,
} from '@/ui';

const Wave = (props: TextProps) => (
  <Text as="span" {...props}>
    🌊
  </Text>
);
const Calendar = (props: TextProps) => (
  <Text as="span" {...props}>
    📆
  </Text>
);
const Sparkles = (props: TextProps) => (
  <Text as="span" {...props}>
    ✨
  </Text>
);

export const Benefits = (props: FlexProps) => {
  return (
    <Flex direction="column" gap="6" {...props}>
      <Heading as="h3" fontSize="lg" fontWeight="bold">
        Connect your Google Calendar for an even better experience!
      </Heading>

      <List display="flex" flexDir="column" fontWeight="medium" gap="3">
        <ListItem alignItems="center" display="flex">
          <ListIcon as={Wave} />
          <Text>Streamlined add-to-calendar</Text>
        </ListItem>
        <ListItem alignItems="center" display="flex">
          <ListIcon as={Calendar} />
          <Text>Send to specific calendars</Text>
        </ListItem>
        <ListItem alignItems="center" display="flex">
          <ListIcon as={Sparkles} />
          <Text>Connect multiple accounts</Text>
        </ListItem>
      </List>
    </Flex>
  );
};
