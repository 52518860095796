import type { ReactNode } from 'react';
import { Box, HStack, Tag, useColorModeValue } from '@/ui';

type Props = {
  children: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

export const Pill = ({ children, disabled, onClick }: Props) => {
  const disabledColor = useColorModeValue('gray.500', 'customgray.mid');
  const color = useColorModeValue('customgray.dark', 'customgray.light');
  const disabledBgColor = useColorModeValue('white', 'customgray.darker2');
  const bgColor = useColorModeValue('white', 'customgray.dark');
  const hoverBgColor = useColorModeValue('gray.50', 'customgray.dark');

  return (
    <Tag
      as={onClick ? 'button' : 'div'}
      bgColor={disabled ? disabledBgColor : bgColor}
      borderColor={disabled ? disabledColor : color}
      borderRadius="full"
      color={disabled ? disabledColor : color}
      h="34px"
      lineHeight="normal"
      px="3"
      shadow="inset 0 0 0px 1px currentColor"
      size="md"
      variant="outline"
      {...(onClick ? { type: 'button' } : {})}
      _focusVisible={{
        outline: '1px solid',
        outlineColor: 'brand.500',
        outlineOffset: -1,
      }}
      _groupHover={{
        backgroundColor: 'transparent',
      }}
      _hover={{
        bg: onClick ? hoverBgColor : undefined,
      }}
      onClick={onClick}
    >
      <HStack overflow="hidden">
        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {children}
        </Box>
      </HStack>
    </Tag>
  );
};
