import { Text, Icon } from '@/ui';
import { Pill, type PillProps } from '../Pill';

type Props = Omit<PillProps, 'children'> & {
  type?: 'label' | 'whoLabel';
  children: string;
};

export const Label = ({ type = 'label', children, ...props }: Props) => {
  return (
    <Pill {...props}>
      <Icon icon={type === 'label' ? 'Tag' : 'User'} size="3.5" />
      <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
        {children}
      </Text>
    </Pill>
  );
};
