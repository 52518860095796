import {
  Children,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Flex, type FlexProps } from '@/ui';

export type CarouselRef = {
  index: number;
  next: () => void;
  prev: () => void;
  goToIndex: (index: number) => void;
};

type SlideProps = FlexProps & {
  x: number;
};

type CarouselProps = FlexProps & {
  children: ReactNode;
  transition?: string;
  slideProps?: Omit<SlideProps, 'x' | 'transition'>;
  strategy?: // Unmount the disappearing slide after the transition has completed
  | 'unmount-after-transition'
    // Keep all slides mounted
    | 'keep-mounted';
};

const ContainerComponent = (
  props: CarouselProps,
  ref: ForwardedRef<HTMLDivElement>
) => <Flex flex="1" overflowX="hidden" ref={ref} wrap="nowrap" {...props} />;
const Container = forwardRef(ContainerComponent);

const Slide = ({ x, ...props }: SlideProps) => (
  <Flex
    h="100%"
    minW="100%"
    transform={`translate(-${x}%)`}
    w="100%"
    {...props}
  />
);

const CarouselComponent = (
  {
    children,
    transition = '0.15s ease',
    strategy = 'keep-mounted',
    slideProps,
    ...props
  }: CarouselProps,
  ref: ForwardedRef<CarouselRef>
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [index, setIndex] = useState(0);
  const [fullyVisibleIndex, setFullyVisibleIndex] = useState(0);
  const childrens = Children.toArray(children);

  const next = useCallback(() => {
    const nextIndex = index + 1 === childrens.length ? index : index + 1;
    setIndex(nextIndex);
  }, [setIndex, index, childrens]);

  const prev = useCallback(() => {
    const prevIndex = index - 1 < 0 ? 0 : index - 1;
    setIndex(prevIndex);
  }, [setIndex, index]);

  const goToIndex = useCallback(
    (index: number) => {
      const adjustedIndex =
        index < 0 ? 0 : index > childrens.length ? childrens.length - 1 : index;
      setIndex(adjustedIndex);
    },
    [setIndex, childrens]
  );

  useEffect(() => {
    if (typeof ref === 'function') {
      ref({ index, next, prev, goToIndex });
    } else if (ref) {
      ref.current = {
        index,
        next,
        prev,
        goToIndex,
      };
    }
  }, [ref, next, prev, index, goToIndex]);

  return (
    <Container ref={containerRef} {...props}>
      {childrens.map((child, slideIndex) => (
        <Slide
          key={slideIndex}
          {...slideProps}
          transition={transition}
          x={index * 100}
          onTransitionEnd={(event) => {
            if (
              strategy === 'unmount-after-transition' &&
              index === slideIndex
            ) {
              setFullyVisibleIndex(index);
            }
            slideProps?.onTransitionEnd?.(event);
          }}
        >
          {strategy === 'keep-mounted' && child}
          {strategy === 'unmount-after-transition' &&
            (slideIndex === index || slideIndex === fullyVisibleIndex
              ? child
              : null)}
        </Slide>
      ))}
    </Container>
  );
};

export const Carousel = forwardRef(CarouselComponent);
