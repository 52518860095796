import { useTranslation } from 'react-i18next';
import { AiOutlineLogin, AiOutlinePlus } from 'react-icons/ai';
import { FaRegCompass } from 'react-icons/fa';
import { HiMenu } from 'react-icons/hi';
import { config } from '@/config';
import { Icon, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@/ui';
import { useAuthClickHandler } from './hooks';

export const MobileMenu = () => {
  const { t } = useTranslation(['header', 'auth']);
  const { handleAuthClick } = useAuthClickHandler();

  return (
    <Menu isLazy placement="bottom-end">
      <MenuButton
        aria-label={t('profile_menu.aria_label')}
        as={IconButton}
        icon={<Icon as={HiMenu} boxSize="18px" />}
        variant="outline"
      />

      <MenuList>
        <MenuItem
          as="a"
          href={`${config.marketingUrl}`}
          icon={<Icon as={FaRegCompass} />}
        >
          {t('readonly.gallery')}
        </MenuItem>
        <MenuItem
          icon={<Icon as={AiOutlineLogin} />}
          onClick={() => handleAuthClick('login')}
        >
          {t('auth:sign_in')}
        </MenuItem>
        <MenuItem
          _hover={{ bg: 'pink.50' }}
          color="pink.500"
          icon={<Icon as={AiOutlinePlus} />}
          onClick={() => handleAuthClick('register')}
        >
          {t('auth:sign_up')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
