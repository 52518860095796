import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import type {
  LabelSortOrderInput,
  LabelType,
  Label,
} from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  UpdateLabelSortOrderMutation,
  UpdateLabelSortOrderMutationVariables,
} from './useUpdateLabelSortOrder.generated';

const query = gql`
  mutation UpdateLabelSortOrder(
    $scheduleId: ID!
    $labels: [LabelSortOrderInput!]!
  ) {
    updateLabelSortOrder(scheduleId: $scheduleId, labels: $labels)
  }
`;

const attachText = (list: Label[], label: LabelSortOrderInput): Label => {
  const existingLabel = list.find((item) => item.id === label.id);

  return {
    ...label,
    text: existingLabel?.text ?? '',
  };
};

type ScheduleCache = ReturnType<typeof useScheduleCache>;

const handleChange = (
  labelType: LabelType,
  scheduleCache: ScheduleCache,
  cachedLabels: Label[],
  { labels }: UpdateLabelSortOrderMutationVariables
): void => {
  if (!Array.isArray(labels)) {
    labels = [labels];
  }
  scheduleCache.setLabels(
    labelType,
    labels.map((item) => attachText(cachedLabels, item))
  );
};

export const useUpdateLabelSortOrder = (
  labelType: LabelType,
  cachedLabels: Label[]
) => {
  const scheduleCache = useScheduleCache();

  return useMutation<
    UpdateLabelSortOrderMutation,
    QueryError,
    UpdateLabelSortOrderMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateLabelSortOrderMutation,
        UpdateLabelSortOrderMutationVariables
      >(query, variables),
    onMutate: (variables) => {
      handleChange(labelType, scheduleCache, cachedLabels, variables);
    },
    onSuccess: (_, variables) => {
      handleChange(labelType, scheduleCache, cachedLabels, variables);
    },
  });
};
