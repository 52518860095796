import { MotionFlex, Box, forwardRef } from '@/ui';
import { useIsColumnLayout } from '../../hooks/useIsColumnLayout';
import type { Job } from '../../types';
import { PromptPanel } from './PromptPanel';
import { ResultsPanel } from './ResultsPanel';

type Props = {
  isPublishLoading: boolean;
  job: Job;
  onPublish: () => void;
};

export const ResultsState = forwardRef(
  ({ isPublishLoading, job, onPublish, ...props }: Props, ref) => {
    const { isColumnLayout } = useIsColumnLayout();

    const overflowY = isColumnLayout
      ? 'auto' // allow the results state to scroll on mobile
      : job
        ? 'hidden' // allow individual panels to scroll on desktop
        : 'initial';

    return (
      <MotionFlex
        animate={{ opacity: 1 }}
        bg="var(--add-content-modal-bg-light)"
        direction={isColumnLayout ? 'column' : 'row'}
        flex="1"
        gap={isColumnLayout ? 3 : 10}
        initial={{ opacity: 0 }}
        overflowY={overflowY}
        ref={ref}
        transition={{ duration: 0.1 }}
        w="100%"
        {...props}
      >
        <PromptPanel job={job} />

        {!isColumnLayout && (
          <Box bg="var(--add-content-modal-border-color)" my="6" w="1px" />
        )}

        <ResultsPanel
          isPublishLoading={isPublishLoading}
          job={job}
          onPublish={onPublish}
        />
      </MotionFlex>
    );
  }
);
