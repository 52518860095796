import { useTranslation } from 'react-i18next';
import { config } from '@/config';
import { Box, Link } from '@/ui';
import { PanelContent } from '../../components/PanelContent';
import { PanelHeader } from '../../components/PanelHeader';
import { SectionHeader } from '../../components/SectionHeader';

export const Help = () => {
  const { t } = useTranslation('settingsModal', { keyPrefix: 'help' });

  return (
    <>
      <PanelHeader>{t('heading')}</PanelHeader>
      <PanelContent>
        <SectionHeader first>{t('support')}</SectionHeader>
        <Link href={`mailto:${config.supportEmail}`} target="_blank">
          {config.supportEmail}
        </Link>

        <SectionHeader>{t('version')}</SectionHeader>
        <Box color="customgray.mid" fontSize="small">
          {t('release', {
            version: config.version || 0,
            release: config.release,
          })}
        </Box>
      </PanelContent>
    </>
  );
};
