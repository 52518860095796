import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { getEntryCategoryColors } from '@/pages/Schedule/utils/entry';
import {
  Flex,
  type FlexProps,
  IconButton,
  Tooltip,
  type RadioProps,
  Icon,
  useColorMode,
} from '@/ui';
import { useCategorySelectContext } from '../hooks/useCategorySelectContext';
import { Row } from './Row';

type Category = NonNullable<DecoratedEntry['category']>;

type Props = Omit<FlexProps, 'onSelect'> & {
  category: Category;
  radioProps?: RadioProps;
  isChecked: boolean;
  onSelect: (category: Category) => void;
};

export const CategoryRow = ({
  category,
  isChecked,
  radioProps,
  onSelect,
}: Props) => {
  const { text, color } = category;

  const { editCategoryClick } = useCategorySelectContext();
  const { t } = useTranslation('categorySelect');
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.id });
  const { colorMode } = useColorMode();

  const colors = getEntryCategoryColors(color, colorMode);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Row
      _hover={{ bg: colors.hoverBackgroundColor }}
      bg={colors.backgroundColor}
      borderWidth="2px"
      color="customgray.dark"
      flex="1"
      isChecked={isChecked}
      paddingLeft={3}
      paddingRight={1}
      radioProps={radioProps}
      ref={setNodeRef}
      style={style}
      transition="background-color 0.2s ease"
      {...attributes}
      {...listeners}
      onClick={() => onSelect(category)}
    >
      <Flex
        align="center"
        flex="1"
        h="100%"
        mr={2}
        overflow="hidden"
        whiteSpace="nowrap"
      >
        {text}
      </Flex>

      <Tooltip label={t('edit_tooltip')} maxW="300px">
        <IconButton
          aria-label={t('edit')}
          as="div"
          color="customgray.dark"
          icon={<Icon icon="MoreHorizontal" size="3.5" />}
          size="xs"
          variant="dark"
          _hover={{
            bg: colors.hoverBackgroundColor,
            svg: {
              fill: 'black',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
            editCategoryClick(category);
          }}
        />
      </Tooltip>
    </Row>
  );
};
