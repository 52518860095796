import isEqual from 'lodash.isequal';
import { createWithEqualityFn } from 'zustand/traditional';
import type { LaunchOption, LaunchParams, LaunchState } from './types';

export const useLauncherStore = createWithEqualityFn<LaunchState>()(
  (set) => ({
    launch: null,
    launchParams: {},
    setLaunch: (launch: LaunchOption, launchParams: LaunchParams = {}) =>
      set({ launch, launchParams }),
    reset: () => set({ launch: null, launchParams: {} }),
  }),
  isEqual
);
