import { useTranslation } from 'react-i18next';
import { useRegisterModalStore } from '@/components/RegisterModal';
import type { ButtonProps } from '@/ui';
import { Link } from './Link';

export const ResetPasswordLink = (props: ButtonProps): JSX.Element => {
  const { t } = useTranslation('auth');
  const { isOpen: isRegisterModalOpen, close: closeRegisterModal } =
    useRegisterModalStore();

  return (
    <Link
      to="/reset-password"
      onClick={() => {
        if (isRegisterModalOpen) {
          closeRegisterModal();
        }
      }}
      {...props}
    >
      {t('forgot_password')}
    </Link>
  );
};
