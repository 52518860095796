import { DateTime } from 'luxon';
import { getStartOfWeek } from '@/utils/dates';
import type {
  EndDateAdjuster,
  PanStartDateAdjuster,
  StartDateAdjuster,
  TodayEndDateAdjuster,
  TodayStartDateAdjuster,
} from '../types';

export const adjustStartDate: StartDateAdjuster = (requestedStartDate) => {
  return requestedStartDate.startOf('month');
};

export const adjustEndDate: EndDateAdjuster = (startDate) => {
  return startDate.endOf('month').startOf('day').plus({ months: 2, days: 1 });
};

export const adjustTodayStartDate: TodayStartDateAdjuster = (
  scheduleTimeZone
) => {
  return adjustStartDate(DateTime.local({ zone: scheduleTimeZone }));
};

export const adjustTodayEndDate: TodayEndDateAdjuster = () => null;

export const adjustPreviousStartDate: PanStartDateAdjuster = (startDate) => {
  return startDate.minus({ months: 1 });
};

export const adjustNextStartDate: PanStartDateAdjuster = (startDate) => {
  return startDate.plus({ months: 1 });
};

export const getVisibleStartDate: StartDateAdjuster = (startDate) => {
  return getStartOfWeek(startDate);
};
