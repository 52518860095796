import { useTranslation } from 'react-i18next';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useTimeZonedDateUtils } from '@/hooks/useTimeZonedDateUtils';
import {
  useEntriesContext,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import { useLastSelectedView, useViewModules } from '@/pages/Schedule/hooks';
import type { ScheduleView } from '@/pages/Schedule/types';
import { VIEW_TO_MODULE } from '@/pages/Schedule/views';
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  type BoxProps,
  Portal,
  Icon,
} from '@/ui';
import weekButtonIcon from './weekIcon@2x.png';

export const ViewSelect = (boxProps: BoxProps) => {
  const { viewModules } = useViewModules();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const {
    scheduleId,
    setEndDate,
    viewModule: activeViewModule,
    schedule,
  } = useScheduleContext();
  const { entryInstances } = useEntriesContext();
  const { setQueryParams } = useQueryParams();
  const { local } = useTimeZonedDateUtils();
  const { t } = useTranslation('actionBar', { keyPrefix: 'view_select' });
  const { setLastSelectedView } = useLastSelectedView(scheduleId);

  const changeView = (view: ScheduleView) => {
    const params = { view, endDate: null } as Record<string, string | null>;
    const selectedViewModule = VIEW_TO_MODULE[view];
    const viewModule =
      isMobileBreakpoint && selectedViewModule.mobileViewModule
        ? selectedViewModule.mobileViewModule
        : selectedViewModule;

    if (
      selectedViewModule.id !== activeViewModule.id &&
      entryInstances.length > 0
    ) {
      params.startDate = viewModule
        .adjustStartDate(entryInstances[0].startDate)
        .toISODate();
    } else {
      params.startDate = viewModule.adjustStartDate(local()).toISODate();
    }

    setLastSelectedView(view);
    setQueryParams(params);

    // Unset a custom end date that might have been set in other views,
    // which may or may not even be reflected in the URL.
    // The reason this is here and not somewhere else like useScheduleDates is
    // so the state updates happen in the same event loop as the URL updates.
    // Otherwise when switching views you'll see a funky state until the `view`
    // param update is recognized and another state change is kicked off.
    setEndDate(null);
  };

  return (
    // TODO Box wrapper exists to silence Popper warning
    // https://github.com/chakra-ui/chakra-ui/issues/3440
    <Box display={{ base: 'none', md: 'block' }} {...boxProps}>
      <Menu isLazy>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              textTransform="capitalize"
              variant="secondary"
              leftIcon={
                <Image
                  h="16px"
                  opacity="70%"
                  src={weekButtonIcon}
                  transform="translateY(-1px)"
                  w="16px"
                />
              }
              rightIcon={
                <Icon
                  icon="ChevronDown"
                  transform={isOpen ? 'rotate(-180deg)' : undefined}
                  transition="transform 0.2s ease-in-out"
                />
              }
            >
              {activeViewModule.viewSelect?.label ?? t('select')}
            </MenuButton>
            <Portal>
              <MenuList>
                {viewModules.map((viewModule) => {
                  if (
                    !viewModule.isEnabled ||
                    !viewModule.viewSelect ||
                    !(
                      schedule &&
                      viewModule.scheduleTypes.includes(schedule?.type)
                    )
                  ) {
                    return null;
                  }
                  return (
                    <MenuItem
                      icon={<viewModule.viewSelect.Icon />}
                      key={viewModule.id}
                      value={viewModule.id}
                      onClick={() => changeView(viewModule.id)}
                    >
                      {viewModule.viewSelect.label}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Portal>
          </>
        )}
      </Menu>
    </Box>
  );
};
