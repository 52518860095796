import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { i18n } from '@/i18n';
import { forwardRef, Text } from '@/ui';
import { StateCard, type StateCardProps } from '../StateCard';
import { SlideText } from './SlideText';
import classes from './styles.module.scss';

const slides = [
  {
    emoji: '🦄',
    message: i18n.t('addContent:loading_state.slide_1_message'),
  },
  {
    emoji: '🔮',
    message: i18n.t('addContent:loading_state.slide_2_message'),
  },
  {
    emoji: '✨',
    message: i18n.t('addContent:loading_state.slide_3_message'),
  },
  {
    emoji: '🪄',
    message: i18n.t('addContent:loading_state.slide_4_message'),
  },
  {
    emoji: '🚙',
    message: i18n.t('addContent:loading_state.slide_5_message'),
  },
];

export const LoadingState = forwardRef((props: StateCardProps, ref) => {
  const { t } = useTranslation('addContent', { keyPrefix: 'loading_state' });
  const [slideIndex, setSlideIndex] = useState(0);
  const slide = slides[slideIndex];

  useEffect(() => {
    const timerId = setInterval(() => {
      setSlideIndex((prevSlideIndex) =>
        prevSlideIndex === slides.length - 1 ? 0 : prevSlideIndex + 1
      );
    }, 5_000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <StateCard gap="0" mt="0" ref={ref} w="100%" {...props}>
      <AnimatePresence mode="popLayout">
        <SlideText fontSize="48px" key={slide.emoji}>
          {slide.emoji}
        </SlideText>
      </AnimatePresence>

      <Text
        className={classes.loading}
        fontSize="xl"
        fontWeight="extrabold"
        mx="auto"
        my="3"
        textAlign="left"
        w="194px"
      >
        {t('title')}
      </Text>

      <AnimatePresence mode="popLayout">
        <SlideText
          fontWeight="medium"
          h="50px"
          key={slide.message}
          maxW="328px"
        >
          {slide.message}
        </SlideText>
      </AnimatePresence>
    </StateCard>
  );
});
