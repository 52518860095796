import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import type { QueryError } from '@/utils/errors';
import type {
  ToggleRsvpMutation,
  ToggleRsvpMutationVariables,
} from './useToggleRsvp.generated';

const query = gql`
  mutation ToggleRsvp($scheduleId: ID!, $entryId: ID!, $enabled: Boolean!) {
    toggleRsvp(scheduleId: $scheduleId, entryId: $entryId, enabled: $enabled)
  }
`;

export const useToggleRsvp = () => {
  const { scheduleId } = useScheduleContext();

  const { draftEntry, updateDraftEntry } = useDraftEntryStore((store) => ({
    draftEntry: store.draftEntry,
    updateDraftEntry: store.updateDraftEntry,
  }));

  const { mutate, ...rest } = useMutation<
    ToggleRsvpMutation,
    QueryError,
    ToggleRsvpMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<ToggleRsvpMutation, ToggleRsvpMutationVariables>(
        query,
        variables
      ),
    onMutate: (variables) => {
      if (draftEntry?.id === variables.entryId) {
        updateDraftEntry({
          ...draftEntry,
          rsvpSettings: {
            ...draftEntry.rsvpSettings,
            enabled: variables.enabled,
          },
        });
      }

      return {
        optimisticData: {
          contextId: draftEntry?.contextId,
          rsvpSettings: {
            enabled: variables.enabled,
          },
        },
        rollbackData: {
          contextId: draftEntry?.contextId,
          rollbackData: {
            enabled: !variables.enabled,
          },
        },
      };
    },
  });

  const toggleRsvp = (
    entryId: string,
    enabled: boolean,
    options?: MutateOptions<
      ToggleRsvpMutation,
      QueryError,
      ToggleRsvpMutationVariables
    >
  ) => {
    return mutate({ scheduleId, entryId, enabled }, options);
  };

  return {
    ...rest,
    toggleRsvp,
  };
};
