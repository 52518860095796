import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  CreateSnippetMutation,
  CreateSnippetMutationVariables,
} from './useCreateSnippet.generated';

const query = gql`
  mutation CreateSnippet(
    $scheduleId: ID!
    $name: String!
    $categories: [ID!]!
    $whoLabels: [LabelIdInput!]!
    $labels: [LabelIdInput!]!
    $feeds: [ID!]!
  ) {
    createSnippet(
      scheduleId: $scheduleId
      name: $name
      categories: $categories
      whoLabels: $whoLabels
      labels: $labels
      feeds: $feeds
    ) {
      id
    }
  }
`;

export const useCreateSnippet = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, data, isPending, isSuccess, reset } = useMutation<
    CreateSnippetMutation,
    QueryError,
    CreateSnippetMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<CreateSnippetMutation, CreateSnippetMutationVariables>(
        query,
        variables
      ),
  });

  const createSnippet = (
    variables: Omit<CreateSnippetMutationVariables, 'scheduleId'>,
    options?: MutateOptions<
      CreateSnippetMutation,
      QueryError,
      CreateSnippetMutationVariables
    >
  ) => mutate({ scheduleId, ...variables }, options);

  return {
    createSnippet,
    data,
    reset,
    isPending,
    isSuccess,
  };
};
