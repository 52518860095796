import type { DateTime } from 'luxon';
import type { DecoratedInstance } from '@/pages/Schedule/types';
import { shallowCompare } from './shallowCompare';

export const areInstancesEqual = (
  instance1: DecoratedInstance,
  instance2: DecoratedInstance
): boolean => {
  return (
    instance1.id === instance2.id &&
    instance1.title === instance2.title &&
    instance1.description === instance2.description &&
    instance1.notes === instance2.notes &&
    instance1.emoji === instance2.emoji &&
    instance1.feed?.id === instance2.feed?.id &&
    instance1.isHidden === instance2.isHidden &&
    instance1.sequence === instance2.sequence &&
    instance1.isOnDay === instance2.isOnDay &&
    instance1.locationWithPlace?.name === instance2.locationWithPlace?.name &&
    instance1.timeZone === instance2.timeZone &&
    instance1.isFollowing === instance2.isFollowing &&
    +instance1.startDate === +instance2.startDate &&
    +instance1.endDate === +instance2.endDate &&
    areObjectsEqual(instance1.category, instance2.category) &&
    areCollectionsEqual(instance1.labels, instance2.labels) &&
    areCollectionsEqual(instance1.whoLabels, instance2.whoLabels) &&
    areCollectionsEqual(instance1.messages, instance2.messages) &&
    areObjectsEqual(instance1.rsvpInfo, instance2.rsvpInfo) &&
    areObjectsEqual(instance1.rsvpSettings, instance2.rsvpSettings)
  );
};

export const areObjectsEqual = (
  obj1: Record<string, unknown> | undefined | null,
  obj2: Record<string, unknown> | undefined | null
): boolean => {
  if (!obj1 && !obj2) {
    return true;
  }
  return !!obj1 && !!obj2 && shallowCompare(obj1, obj2);
};

const areCollectionsEqual = (
  arr1: Record<string, unknown>[] | undefined,
  arr2: Record<string, unknown>[] | undefined
): boolean => {
  if (!arr1 && !arr2) {
    return true;
  }
  return (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length &&
    arr1.every((item, index) => shallowCompare(item, arr2[index]))
  );
};

export const areInstanceArraysEqual = (
  instances1: DecoratedInstance[],
  instances2: DecoratedInstance[]
): boolean => {
  return (
    instances1.length === instances2.length &&
    instances1.every((instance, index) =>
      areInstancesEqual(instance, instances2[index])
    )
  );
};

export const areDateArraysEqual = (
  dates1: DateTime[],
  dates2: DateTime[]
): boolean => {
  return (
    dates1.length === dates2.length &&
    dates1.every((date, index) => date.toMillis() === dates2[index].toMillis())
  );
};
