import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input,
  InputGroup,
  InputRightElement,
  type InputProps,
  forwardRef,
  IconButton,
  Icon,
  FormLabel,
} from '@/ui';

export const PasswordInput = forwardRef((props: InputProps, ref) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation('auth');
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        pr="3rem"
        ref={ref}
        type={show ? 'text' : 'password'}
        {...props}
        placeholder=""
      />
      {props.placeholder && (
        <FormLabel color="customgray.mid">{props.placeholder}</FormLabel>
      )}
      <InputRightElement bottom="0" h="100%" top="0" w="3rem">
        <IconButton
          aria-label={show ? t('password_hide') : t('password_show')}
          color="gray.500"
          colorScheme="gray"
          display="inline-flex"
          icon={<Icon icon={show ? 'EyeOff' : 'Eye'} />}
          variant="unstyled"
          _hover={{
            color: 'customgray.dark',
          }}
          onClick={handleClick}
        />
      </InputRightElement>
    </InputGroup>
  );
});
