import actionBar from './actionBar.json';
import addContent from './addContent.json';
import addItemToCalendar from './addItemToCalendar.json';
import addToCalendarModal from './addToCalendarModal.json';
import auth from './auth.json';
import categorySelect from './categorySelect.json';
import common from './common.json';
import createAiSchedule from './createAiSchedule.json';
import createSnippetPopover from './createSnippetPopover.json';
import dashboard from './dashboard.json';
import editSnippetModal from './editSnippetModal.json';
import entryMessageForm from './entryMessageForm.json';
import entryModal from './entryModal.json';
import filterPopover from './filterPopover.json';
import header from './header.json';
import importEntries from './import.json';
import labelSelect from './labelSelect.json';
import leaveScheduleConfirm from './leaveScheduleConfirm.json';
import members from './members.json';
import membersSelect from './membersSelect.json';
import messageBubble from './messageBubble.json';
import quickCreatePopover from './quickCreatePopover.json';
import resetPassword from './resetPassword.json';
import resetPasswordRequest from './resetPasswordRequest.json';
import richTextEditor from './richTextEditor.json';
import rsvpBar from './rsvpBar.json';
import schedule from './schedule.json';
import scheduleMessages from './scheduleMessages.json';
import scheduleSettingsModal from './scheduleSettingsModal.json';
import settingsModal from './settingsModal.json';
import shareModal from './shareModal.json';
import snippetIngredientInUseModal from './snippetIngredientInUseModal.json';
import sources from './sources.json';
import updateAvailableCallout from './updateAvailableCallout.json';

export const en = {
  common,
  resetPasswordRequest,
  resetPassword,
  entryModal,
  header,
  schedule,
  dashboard,
  filterPopover,
  actionBar,
  sources,
  import: importEntries,
  categorySelect,
  shareModal,
  members,
  entryMessageForm,
  richTextEditor,
  updateAvailableCallout,
  scheduleMessages,
  createAiSchedule,
  createSnippetPopover,
  editSnippetModal,
  snippetIngredientInUseModal,
  addToCalendarModal,
  labelSelect,
  leaveScheduleConfirm,
  settingsModal,
  membersSelect,
  messageBubble,
  scheduleSettingsModal,
  rsvpBar,
  addItemToCalendar,
  auth,
  addContent,
  quickCreatePopover,
};
