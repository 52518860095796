import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import type { CreateScheduleMutationVariables } from '@/hooks/useCreateSchedule/useCreateSchedule.generated';
import { useNavigateToSchedule } from '@/hooks/useNavigateToSchedule';
import { useAddContentModalStore } from '@/pages/Schedule/components/AddContentModal';
import { useOnboardingModalStore } from '@/pages/Schedule/components/OnboardingModal';
import { ScheduleType } from '@/types/gql.generated';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  type ModalProps,
} from '@/ui';
import { IndexState } from './components/IndexState';
import { LoadingState } from './components/LoadingState';
import { useCreateScheduleWrapper } from './hooks/useCreateScheduleWrapper';
import { useCreateScheduleModalStore } from './store';
import type { ScheduleOption } from './types';

const variants = {
  enter: {
    opacity: 1,
    transition: { duration: 0.1 },
  },
  exit: {
    opacity: 0,
    transition: { duration: 0.1 },
  },
};

type Props = Pick<ModalProps, 'onCloseComplete'>;

export const CreateScheduleModal = ({ onCloseComplete }: Props) => {
  const { isOpen, close } = useCreateScheduleModalStore();
  const { navigateToSchedule } = useNavigateToSchedule();
  const { trackEvent } = useAnalytics();
  const { createSchedule, debouncedIsPending, debouncedIsSuccess } =
    useCreateScheduleWrapper();
  const { open: openAddContentModal } = useAddContentModalStore();
  const { open: openOnboardingModal } = useOnboardingModalStore();
  const [scheduleOption, setScheduleOption] = useState<ScheduleOption | null>(
    null
  );

  // after we've navigated and after the artificial delay on isSuccess,
  // launch into the next workflow based on the selected option
  useEffect(() => {
    if (!debouncedIsSuccess) {
      return;
    }
    if (scheduleOption === 'blank') {
      openAddContentModal({
        launchState: 'create-schedule',
      });
    } else {
      openOnboardingModal({
        flow: 'family',
      });
    }

    close();
  }, [
    debouncedIsSuccess,
    close,
    scheduleOption,
    openAddContentModal,
    openOnboardingModal,
  ]);

  const handleClick = (option: ScheduleOption) => {
    const type = ScheduleType.Classic;
    trackEvent('schedule:create', { type });
    setScheduleOption(option);

    const variables: Partial<CreateScheduleMutationVariables> = {
      type,
    };
    if (option === 'family') {
      variables.title = 'Our Family Calendar';
      variables.headerEnabled = false;
    }

    createSchedule(variables, {
      onSuccess: (schedule) => {
        if (option === 'family') {
          navigateToSchedule(schedule, {
            state: {
              launch: 'onboarding',
            },
          });
        } else {
          navigateToSchedule(schedule);
        }
      },
    });
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      returnFocusOnClose={false}
      variant="primary"
      onClose={close}
      onCloseComplete={onCloseComplete}
    >
      <ModalContent
        bg="#F5EFFB"
        minH={{ base: 'var(--app-height)', md: '665px' }}
        minW={{ base: '100vw', md: '750px' }}
      >
        <ModalCloseButton />
        <ModalBody display="flex" flexDirection="column">
          <AnimatePresence mode="popLayout">
            {debouncedIsPending || debouncedIsSuccess ? (
              <LoadingState
                animate="enter"
                exit="exit"
                initial="enter"
                key="loading"
                variants={variants}
              />
            ) : (
              <IndexState
                animate="enter"
                exit="exit"
                initial="exit"
                key="index"
                variants={variants}
                onClick={handleClick}
              />
            )}
          </AnimatePresence>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
