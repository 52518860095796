import type { Prompt } from './types';

export const INITIAL_TEXT_PROMPT: Pick<Prompt, 'text' | 'html'> = {
  html: '',
  text: '',
};

export const INITIAL_FILE_PROMPT: Pick<Prompt, 'file' | 'base64'> = {
  file: null,
  base64: null,
};

export const INITIAL_PROMPT: Prompt = {
  ...INITIAL_TEXT_PROMPT,
  ...INITIAL_FILE_PROMPT,
};
