import { useState } from 'react';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import type { EntryModalMessage } from '../types';
import { createEntryMessage } from './createEntryMessage';

export const useMessagesPanelState = () => {
  const [activeMessage, setActiveMessage] = useState<EntryModalMessage>();
  const [mode, setMode] = useState<'create' | 'edit' | null>(null);

  const createNewMessage = (
    entry: DecoratedEntry,
    instance?: DecoratedInstance
  ) => {
    const message = createEntryMessage(entry, instance);
    setActiveMessage(message);
    setMode('create');
  };

  const editMessage = (message: EntryModalMessage) => {
    setActiveMessage(message);
    setMode('edit');
  };

  const clearActiveMessage = () => {
    setActiveMessage(undefined);
    setMode(null);
  };

  const reset = () => {
    clearActiveMessage();
  };

  return {
    messageMode: mode,
    activeMessage,
    createNewMessage,
    editMessage,
    clearActiveMessage,
    reset,
  };
};
