import { type ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AppLoader } from '@/components/AppLoader';

type Props = {
  fallback?: JSX.Element;
  children?: ReactNode;
};

export const SuspenseContainer = ({
  fallback = <AppLoader />,
  children,
}: Props) => {
  return (
    <Suspense fallback={fallback}>{children ? children : <Outlet />}</Suspense>
  );
};
