import { MotionFlex, type MotionFlexProps } from '@/ui';
import { forwardRef } from '@/ui';

export type StateCardProps = MotionFlexProps;

export const StateCard = forwardRef((props: StateCardProps, ref) => {
  return (
    <MotionFlex
      align="center"
      animate={{ opacity: 1 }}
      bg="var(--add-content-modal-bg-light)"
      borderRadius="xl"
      direction="column"
      exit={{ opacity: 0 }}
      gap="5"
      h="calc(100% - var(--chakra-space-12))"
      initial={{ opacity: 0 }}
      justify="center"
      mt="6"
      py="10"
      ref={ref}
      textAlign="center"
      transition={{ duration: 0.1 }}
      w="calc(100% - var(--chakra-space-12))"
      {...props}
    />
  );
});
