import { Fragment, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCopy } from 'react-icons/ai';
import { BiExit } from 'react-icons/bi';
import { BsChatTextFill, BsLightningChargeFill } from 'react-icons/bs';
import { HiScissors } from 'react-icons/hi';
import {
  IoCalendarSharp,
  IoHome,
  IoSettingsOutline,
  IoTrashOutline,
} from 'react-icons/io5';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useCopySchedule,
  useSchedulePermissions,
} from '@/pages/Schedule/hooks';
import { ScheduleType } from '@/types/gql.generated';
import { useDisclosure } from '@/ui';
import { LeaveConfirmation } from '../../LeaveConfirmation';
import { ScheduleSettingsModal } from '../../ScheduleSettingsModal';
import { MoreMenu } from '../components/MoreMenu';
import { DeleteConfirmation } from '../components/MoreMenu/DeleteConfirmation';
import { ToolbarButton } from '../components/ToolbarButton';
import type { PageButton } from '../types';

type HeaderToolbarButton =
  | PageButton
  | {
      key: string;
      element: ReactElement;
    };

export const useToolbarButtons = () => {
  const { pathname } = useLocation();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { scheduleId, scheduleSlug, isEditorRole, isViewerRole, schedule } =
    useScheduleContext();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { value: snippetsEnabled } = useFeatureFlag('snippetsEnabled');
  const { value: sourcesEnabled } = useFeatureFlag('sourcesEnabled');
  const { value: copyScheduleEnabled } = useFeatureFlag('copyScheduleEnabled');
  const { copySchedule } = useCopySchedule();
  const navigate = useNavigate();
  const { t } = useTranslation(['header', 'schedule']);
  const toast = useToast();
  const isStandard = [ScheduleType.Classic, ScheduleType.List].includes(
    schedule?.type as ScheduleType
  );

  const {
    isOpen: isSettingsModalOpen,
    onOpen: openSettingsModal,
    onClose: closeSettingsModal,
  } = useDisclosure();

  const {
    isOpen: isDeleteConfirmOpen,
    onOpen: openDeleteConfirm,
    onClose: closeDeleteConfirm,
  } = useDisclosure();

  const {
    isOpen: isLeaveModalOpen,
    onOpen: openLeaveModal,
    onClose: closeLeaveModal,
  } = useDisclosure();

  const matchesPaths = (matches: string[]): boolean => {
    return matches.some((match) => !!matchPath(match, pathname));
  };

  const handleCopyClick = () => {
    copySchedule(scheduleId, {
      onError: () => toast.error(t('schedule:copy_schedule.error_toast')),
      onSuccess: (data) => {
        navigate(`/schedule/${data.copySchedule}`);

        toast.notify({
          title: t('schedule:copy_schedule.success_toast_title'),
          description: t('schedule:copy_schedule.success_toast_description'),
        });
      },
    });
  };

  const pageButtons: PageButton[] = (
    [
      // Dashboard
      {
        label: t('schedule.toolbar.dashboard'),
        isVisible: true,
        isActive: false,
        icon: <IoHome />,
        href: '/',
        desktopPlacement: 'inline',
        moreMenuGroup: 0,
      },
      // Schedule
      {
        label: t('schedule.toolbar.schedule'),
        isVisible: true,
        isActive: matchesPaths([
          '/schedule/:id',
          '/schedule/:id/entries-job/*',
        ]),
        icon: <IoCalendarSharp />,
        href: `/schedule/${scheduleSlug}`,
        desktopPlacement: 'inline',
        moreMenuGroup: 1,
      },
      // Snippets
      {
        label: t('schedule.toolbar.snippets'),
        icon: <HiScissors />,
        isVisible:
          isStandard &&
          snippetsEnabled &&
          hasSchedulePermission('SCHEDULE_VIEW'),
        isActive: matchesPaths(['/schedule/:id/snippets', '/snippet/:id']),
        href: `/schedule/${scheduleSlug}/snippets`,
        desktopPlacement: 'inline',
        moreMenuGroup: 1,
      },
      // Sources
      {
        label: t('schedule.toolbar.sources'),
        icon: <BsLightningChargeFill />,
        isVisible:
          isStandard &&
          sourcesEnabled &&
          hasSchedulePermission('SCHEDULE_IMPORT'),
        isInlineOnDesktop: true,
        isActive: !!matchPath('/schedule/:id/sources/*', pathname),
        href: `/schedule/${scheduleSlug}/sources`,
        desktopPlacement: 'inline',
        moreMenuGroup: 1,
      },
      // Messages
      {
        label: t('schedule.toolbar.messages'),
        icon: <BsChatTextFill />,
        isVisible: isStandard && hasSchedulePermission('MESSAGES'),
        isInlineOnDesktop: true,
        isActive: matchesPaths(['/schedule/:id/messages/*']),
        href: `/schedule/${scheduleSlug}/messages/scheduled`,
        desktopPlacement: 'inline',
        moreMenuGroup: 1,
      },
      // Settings
      {
        label: t('schedule.toolbar.settings'),
        icon: <IoSettingsOutline />,
        isVisible: hasSchedulePermission('SCHEDULE_MODIFY_SETTINGS'),
        isActive: false,
        onClick: openSettingsModal,
        desktopPlacement: 'menu',
        moreMenuGroup: 1,
      },
      // Copy schedule
      {
        label: t('schedule.toolbar.copy'),
        icon: <AiOutlineCopy />,
        isVisible:
          isStandard &&
          copyScheduleEnabled &&
          hasSchedulePermission('SCHEDULE_COPY'),
        isActive: false,
        onClick: handleCopyClick,
        desktopPlacement: 'menu',
        moreMenuGroup: 2,
      },
      // Delete schedule
      {
        label: t('schedule.toolbar.delete'),
        icon: <IoTrashOutline />,
        isVisible: hasSchedulePermission('SCHEDULE_DELETE'),
        isActive: false,
        onClick: openDeleteConfirm,
        desktopPlacement: 'menu',
        moreMenuGroup: 2,
      },
      // Leave schedule
      {
        label: t('schedule.toolbar.leave'),
        icon: <BiExit />,
        isVisible: isEditorRole || isViewerRole,
        isActive: false,
        onClick: openLeaveModal,
        desktopPlacement: 'menu',
        moreMenuGroup: 1,
      },
    ] as const
  ).filter((button) => button.isVisible);

  // buttons placed inline outside of the more menu
  const inlineButtons: HeaderToolbarButton[] = isMobileBreakpoint
    ? []
    : pageButtons.filter((button) => button.desktopPlacement === 'inline');

  // buttons placed inside the more menu
  const moreMenuButtons = pageButtons.reduce<PageButton[][]>((acc, button) => {
    if (
      isMobileBreakpoint ||
      (!isMobileBreakpoint && button.desktopPlacement === 'menu')
    ) {
      if (!acc[button.moreMenuGroup]) {
        acc[button.moreMenuGroup] = [];
      }
      acc[button.moreMenuGroup].push(button);
    }
    return acc;
  }, []);

  // both mobile and desktop recieve a more menu button inline in the toolbar
  if (moreMenuButtons.length) {
    inlineButtons.push({
      key: 'more',
      element: <MoreMenu pageButtons={moreMenuButtons} />,
    });
  }

  return (
    <>
      <ScheduleSettingsModal
        isOpen={isSettingsModalOpen}
        onClose={closeSettingsModal}
      />

      <DeleteConfirmation
        isOpen={isDeleteConfirmOpen}
        onCancel={closeDeleteConfirm}
      />

      <LeaveConfirmation isOpen={isLeaveModalOpen} onCancel={closeLeaveModal} />

      {inlineButtons.map((button) => {
        if ('element' in button) {
          return <Fragment key={button.key}>{button.element}</Fragment>;
        }
        return <ToolbarButton key={button.label} {...button} />;
      })}
    </>
  );
};
