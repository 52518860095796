import { DeleteIcon } from '@chakra-ui/icons';
import {
  PlatformIcon,
  type Props as PlatformIconProps,
} from '@/components/PlatformIcon';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import {
  Flex,
  Icon,
  IconButton,
  ListIcon,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@/ui';
import { CalendarList } from './CalendarList';
import { useDisconnectDialog } from './DisconnectDialog';
import { MagicControl } from './MagicControl';

type ItemProps = {
  integration: DecoratedIntegration;
};

const GoogleIcon = (props: PlatformIconProps) => (
  <PlatformIcon {...props} platform={PlatformTypes.GoogleIntegration} />
);

export const IntegrationItem = ({ integration }: ItemProps) => {
  const { open: openDisconnectDialog } = useDisconnectDialog();

  return (
    <ListItem>
      <Flex align="center" fontWeight="normal" key={integration.id}>
        <ListIcon as={GoogleIcon} />

        <Text as="span" flexGrow="1">
          {integration.name}
        </Text>

        <Flex align="center" gap="2" justify="flex-start">
          <MagicControl integration={integration} />

          <Menu isLazy placement="bottom-end">
            <MenuButton
              aria-label="Options"
              as={IconButton}
              colorScheme="dark"
              h="5"
              icon={<Icon icon="MoreHorizontal" />}
              variant="unstyled"
            />

            <MenuList>
              <MenuItem
                icon={<DeleteIcon />}
                onClick={() => openDisconnectDialog(integration)}
              >
                Disconnect
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <CalendarList integration={integration} />
    </ListItem>
  );
};
