import { WarningTwoIcon } from '@chakra-ui/icons';
import { VscCircleFilled } from 'react-icons/vsc';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { Badge, Flex, Icon, Text, Tooltip } from '@/ui';
import { isSuitableIntegration } from '@/utils/googleCalendar';
import { ReconnectButton } from './ReconnectButton';

type Props = {
  integration: DecoratedIntegration;
};

export const MagicControl = ({ integration }: Props) => {
  if (!isSuitableIntegration(integration)) {
    return (
      <Flex align="center" as="span" gap="2">
        <Tooltip
          label={`We aren't able to send events to this Google Calendar. Please click "Enable Magic" to reconnect this Google account.`}
        >
          <WarningTwoIcon color="red.600" />
        </Tooltip>
        <ReconnectButton integration={integration} />
      </Flex>
    );
  }
  return (
    <Tooltip label="Events imported from Magic can be sent directly to calendars in this account">
      <Badge
        alignItems="center"
        colorScheme="blackAlpha"
        cursor="help"
        display="flex"
        gap="1"
      >
        <Icon as={VscCircleFilled} boxSize="20px" color="green.300" />
        <Text as="span">Magic On</Text>
      </Badge>
    </Tooltip>
  );
};
