import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import type {
  PublishJobEntriesMutation,
  PublishJobEntriesMutationVariables,
} from './usePublishJobEntries.generated';

const query = gql`
  mutation PublishJobEntries($scheduleId: ID!, $entriesJobId: ID!) {
    publishJobEntries(scheduleId: $scheduleId, entriesJobId: $entriesJobId)
  }
`;

export const usePublishJobEntries = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    PublishJobEntriesMutation,
    QueryError,
    PublishJobEntriesMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        PublishJobEntriesMutation,
        PublishJobEntriesMutationVariables
      >(query, variables),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });
    },
  });

  const publishJobEntries = useCallback(
    (
      entriesJobId: string,
      options?: MutateOptions<
        PublishJobEntriesMutation,
        QueryError,
        PublishJobEntriesMutationVariables
      >
    ) => mutate({ entriesJobId, scheduleId }, options),
    [mutate, scheduleId]
  );

  return { publishJobEntries, ...rest };
};
