import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useColorModeValue, Text, Button, Flex, Box } from '@/ui';
import { usePopoverContext } from './usePopoverContext';

type Props = {
  title: ReactNode;
  action?: ReactNode;
  secondaryAction?: ReactNode;
};

export const PopoverHeader = ({ title, action, secondaryAction }: Props) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobileBreakpoint();
  const color = useColorModeValue('currentcolor', 'white');
  const { onClose } = usePopoverContext();

  const doneAction = (
    <Button
      colorScheme="dark"
      fontWeight="400"
      size="sm"
      variant="ghost"
      onClick={onClose}
    >
      {t('done')}
    </Button>
  );

  return (
    <Flex align="center" px="3" py="1.5">
      <Box flex="1">
        {isMobile ? secondaryAction || action : secondaryAction}
      </Box>
      <Text
        color={color}
        flex="2"
        fontSize="sm"
        fontWeight="medium"
        lineHeight="1"
        pos="relative"
        textAlign="center"
        top="1px"
      >
        {title}
      </Text>
      <Box flex="1" textAlign="right">
        {!isMobile && action}
        {isMobile && (secondaryAction ? action : doneAction)}
      </Box>
    </Flex>
  );
};
