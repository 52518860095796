import { MINIMUM_ENTRY_MESSAGE_RELATIVE_MINUTES } from '@/pages/Schedule/constants/entry';
import {
  DeliveryTimeType,
  HybridRelativeTime,
  type SuggestTextMessageInput,
  TimeDirection,
  TimeUnit,
} from '@/types/gql.generated';
import type { DecoratedEntry, DecoratedInstance } from '../../types';
import type { EntryModalMessage } from '../EntryModal';
import type { FormState, When } from './types';

export type WhenPropertyMap = {
  [key in When]: key extends
    | 'minutes-before'
    | 'minutes-after'
    | 'hours-before'
    | 'hours-after'
    ? {
        deliveryTimeType: DeliveryTimeType;
        relativeTimeToEntryStartUnit: TimeUnit;
        relativeTimeToEntryStartDirection: TimeDirection;
      }
    : {
        deliveryTimeType: DeliveryTimeType;
        hybridRelativeTime: HybridRelativeTime;
      };
};

export const whenTimePropertiesMap: WhenPropertyMap = {
  'minutes-before': {
    deliveryTimeType: DeliveryTimeType.Relative,
    relativeTimeToEntryStartUnit: TimeUnit.Minutes,
    relativeTimeToEntryStartDirection: TimeDirection.Before,
  },
  'minutes-after': {
    deliveryTimeType: DeliveryTimeType.Relative,
    relativeTimeToEntryStartUnit: TimeUnit.Minutes,
    relativeTimeToEntryStartDirection: TimeDirection.After,
  },
  'hours-before': {
    deliveryTimeType: DeliveryTimeType.Relative,
    relativeTimeToEntryStartUnit: TimeUnit.Hours,
    relativeTimeToEntryStartDirection: TimeDirection.Before,
  },
  'hours-after': {
    deliveryTimeType: DeliveryTimeType.Relative,
    relativeTimeToEntryStartUnit: TimeUnit.Hours,
    relativeTimeToEntryStartDirection: TimeDirection.After,
  },
  'day-of': {
    deliveryTimeType: DeliveryTimeType.Hybrid,
    hybridRelativeTime: HybridRelativeTime.DayOf,
  },
  'day-before': {
    deliveryTimeType: DeliveryTimeType.Hybrid,
    hybridRelativeTime: HybridRelativeTime.DayBefore,
  },
  'day-after': {
    deliveryTimeType: DeliveryTimeType.Hybrid,
    hybridRelativeTime: HybridRelativeTime.DayAfter,
  },
} as const;

export const getWhenFromValues = (values: Record<string, unknown>) => {
  if (
    values.deliveryTimeType === DeliveryTimeType.Relative &&
    values.relativeTimeToEntryStartUnit === TimeUnit.Minutes &&
    values.relativeTimeToEntryStartDirection === TimeDirection.After
  ) {
    return 'minutes-after';
  }
  if (
    values.deliveryTimeType === DeliveryTimeType.Relative &&
    values.relativeTimeToEntryStartUnit === TimeUnit.Hours &&
    values.relativeTimeToEntryStartDirection === TimeDirection.Before
  ) {
    return 'hours-before';
  }
  if (
    values.deliveryTimeType === DeliveryTimeType.Relative &&
    values.relativeTimeToEntryStartUnit === TimeUnit.Hours &&
    values.relativeTimeToEntryStartDirection === TimeDirection.After
  ) {
    return 'hours-after';
  }
  if (
    values.deliveryTimeType === DeliveryTimeType.Hybrid &&
    values.hybridRelativeTime === HybridRelativeTime.DayOf
  ) {
    return 'day-of';
  }
  if (
    values.deliveryTimeType === DeliveryTimeType.Hybrid &&
    values.hybridRelativeTime === HybridRelativeTime.DayBefore
  ) {
    return 'day-before';
  }
  if (
    values.deliveryTimeType === DeliveryTimeType.Hybrid &&
    values.hybridRelativeTime === HybridRelativeTime.DayAfter
  ) {
    return 'day-after';
  }

  return 'minutes-before';
};

export const getFormValuesFromMessage = (
  message: EntryModalMessage,
  scheduleTimeZone: string
): FormState => {
  const formValues: FormState = {
    ...message,
    when: 'minutes-before',
    sendToEveryone: message?.recipients.length > 0 ? false : true,
    hybridDeliveryTime: message?.hybridDeliveryTime ?? '09:00',
    hybridRelativeTime: message?.hybridRelativeTime ?? HybridRelativeTime.DayOf,
    hybridTimeZone: message?.hybridTimeZone ?? scheduleTimeZone,
    relativeTimeToEntryStart:
      message.relativeTimeToEntryStart ??
      MINIMUM_ENTRY_MESSAGE_RELATIVE_MINUTES,
    relativeTimeToEntryStartUnit:
      message?.relativeTimeToEntryStartUnit ?? TimeUnit.Minutes,
    relativeTimeToEntryStartDirection:
      message?.relativeTimeToEntryStartDirection ?? TimeDirection.Before,
    recipients: message?.recipients.map(({ id }) => id) ?? [],
  };

  formValues.when = getWhenFromValues(formValues);

  return formValues;
};

export const toSuggestMessageInput = (
  entry: DecoratedEntry,
  instance?: DecoratedInstance
): SuggestTextMessageInput => ({
  title: entry.title ?? '',
  description: entry.description,
  timeZone: entry.timeZone,
  location: entry.locationWithPlace?.name,
  startDate: instance
    ? instance.startDate.toUTC().toISO()
    : entry.recurrences[0].startDate.toUTC().toISO(),
  endDate: instance
    ? instance.startDate.toUTC().toISO()
    : entry.recurrences[0].startDate.toUTC().toISO(),
  isOnDay: instance ? instance.isOnDay : entry.recurrences[0].isOnDay,
});
