import { useMemo } from 'react';
import type { QuickEntriesJobFile } from '@/types/gql.generated';
import { Link, Image, Flex } from '@/ui';
import { isPDF } from '@/utils/file';
import { PdfRenderer } from './PdfRenderer';

type Props = {
  file: File | QuickEntriesJobFile;
  pdfWidth: number;
  maxImageWidth?: number;
  maxImageHeight?: number;
};

export const FilePreview = ({
  file,
  pdfWidth,
  maxImageWidth,
  maxImageHeight,
}: Props) => {
  const src = useMemo(() => {
    if (file instanceof File) {
      return URL.createObjectURL(file);
    }
    return file.url;
  }, [file]);

  return (
    <Flex justify="center" overflow="hidden">
      {isPDF(file) ? (
        <PdfRenderer file={file} width={pdfWidth} />
      ) : (
        <Link
          display="flex"
          href={src}
          justifyContent="center"
          maxH={maxImageHeight ? `${maxImageHeight}px` : 'none'}
          maxW={maxImageWidth ? `${maxImageWidth}px` : '100%'}
          target="_blank"
        >
          <Image objectFit="contain" src={src} />
        </Link>
      )}
    </Flex>
  );
};
