import { Box, Flex, type FlexProps } from '@/ui';
import { RemoveButton } from './RemoveButton';

export type PillProps = FlexProps & {
  onRemove?: () => void;
};

export const Pill = ({ children, onRemove, ...props }: PillProps) => {
  return (
    <Box
      pos="relative"
      sx={{
        '&:hover button': {
          opacity: 1,
        },
      }}
    >
      <Flex
        align="center"
        borderColor="rgba(0,0,0,0.1)"
        borderRadius="md"
        borderWidth="1px"
        fontSize="sm"
        fontWeight="bold"
        gap="1"
        h="26px"
        justify="center"
        lineHeight="1"
        minW="0"
        overflow="hidden"
        px="1.5"
        py="1"
        {...props}
      >
        {children}
      </Flex>

      {onRemove && <RemoveButton onClick={onRemove} />}
    </Box>
  );
};
