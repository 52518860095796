import { useMemo, type ReactNode } from 'react';
import { useUpdateSchedule } from '../../../hooks';
import { CreateScheduleHeader } from '../components/CreateScheduleHeader';
import { ModalHeader } from '../components/ModalHeader';
import { OnboardingHeader } from '../components/OnboardingHeader';
import { type LaunchState, useAddContentModalStore } from '../store';
import type { Job } from '../types';

type RenderHeaderProps = {
  pollJobSuccess: boolean;
};

type LaunchStateConfig = {
  /** How tall should the modal be on desktop? */
  initialModalHeightDesktop: string;
  /** Show the multiple items tip in the body of the modal under the header? */
  showTipInBody: boolean;
  /** Underneath the prompt form, show a close link in place of the create manually link */
  showCloseInsteadOfManualEntry: boolean;
  /** Render prop to render the header */
  renderHeader: (props: RenderHeaderProps) => ReactNode;
  /** Optional callback when the user clicks publish */
  onPublish?: (job: Job) => void;
};

/**
 * Customize the look and feel of the modal based on the value
 * of the modal's launch state.
 */
export const useLaunchStateConfig = () => {
  const { updateSchedule } = useUpdateSchedule();
  const { config } = useAddContentModalStore();

  return useMemo(() => {
    const variants: Record<LaunchState, LaunchStateConfig> = {
      default: {
        initialModalHeightDesktop: '564px',
        showTipInBody: true,
        showCloseInsteadOfManualEntry: false,
        renderHeader: () => <ModalHeader />,
      },

      'create-schedule': {
        initialModalHeightDesktop: '670px',
        showTipInBody: false,
        showCloseInsteadOfManualEntry: true,
        renderHeader: ({ pollJobSuccess }) => {
          return pollJobSuccess ? <ModalHeader /> : <CreateScheduleHeader />;
        },
        onPublish: (job) => {
          const scheduleProps: Record<string, string> = {};
          if (job.title) {
            scheduleProps.title = job.title;
          }
          if (job.headerId) {
            scheduleProps.headerId = job.headerId;
          }
          updateSchedule(scheduleProps);
        },
      },

      onboarding: {
        initialModalHeightDesktop: '670px',
        showTipInBody: false,
        showCloseInsteadOfManualEntry: true,
        renderHeader: ({ pollJobSuccess }) => {
          return pollJobSuccess ? <ModalHeader /> : <OnboardingHeader />;
        },
      },
    };

    const launchStateConfig = variants[config.launchState];
    return { launchStateConfig };
  }, [config, updateSchedule]);
};
