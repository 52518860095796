import { useQueryNormalizer } from '@normy/react-query';
import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import type { DateTime } from 'luxon';
import { useCallback } from 'react';
import { EntryFragment } from '@/fragments';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { undecorateEntry } from '@/pages/Schedule/utils/undecorateEntry';
import type { UpdateEntryInput } from '@/types/gql.generated';
import { datePartInZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import type {
  UpdateEntryMutation,
  UpdateEntryMutationVariables,
} from './useUpdateEntry.generated';

const query = gql`
  ${EntryFragment}
  mutation UpdateEntry($input: UpdateEntryInput!) {
    updateEntry(input: $input) {
      ...Entry
    }
  }
`;

const createInput = (
  scheduleId: string,
  entry: DecoratedEntry
): UpdateEntryInput => ({
  scheduleId,
  timeZone: entry.timeZone,
  entryId: entry.id,
  title: entry.title,
  emoji: entry.emoji,
  locationWithPlace: entry.locationWithPlace,
  description: entry.description,
  notes: entry.notes,
  categoryId: entry.category?.id ?? null,
  whoLabels: entry.whoLabels.map(({ id }) => ({ id })),
  labels: entry.labels.map(({ id }) => ({ id })),
  exclusions: entry.exclusions.map((date) => date.toISO()),
  hidden: entry.hidden.map((date) =>
    entry.recurrences[0].isOnDay
      ? datePartInZone(date, 'UTC').toISO()
      : date.toUTC().toISO()
  ),
  recurrences: entry.recurrences.map(
    ({ startDate, endDate, rule, isOnDay }) => ({
      startDate: isOnDay
        ? datePartInZone(startDate, 'UTC').toISO()
        : startDate.toUTC().toISO(),
      endDate: isOnDay
        ? datePartInZone(endDate, 'UTC').toISO()
        : endDate.toUTC().toISO(),
      isOnDay,
      rule,
    })
  ),
});

type MutateFnOptions = MutateOptions<
  UpdateEntryMutation,
  QueryError,
  DecoratedEntry
>;

export const useUpdateEntry = () => {
  const { scheduleId } = useScheduleContext();
  const queryNormalizer = useQueryNormalizer();

  const { mutate, isPending } = useMutation<
    UpdateEntryMutation,
    QueryError,
    DecoratedEntry
  >({
    mutationFn: (entry) => {
      const input = createInput(scheduleId, entry);

      return gqlClient.request<
        UpdateEntryMutation,
        UpdateEntryMutationVariables
      >(query, { input });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });
    },
  });

  const updateEntry = useCallback(
    (entry: DecoratedEntry, mutateOptions?: MutateFnOptions) => {
      /**
       * Optimistically updating via `onMutate` is deliberately avoided because
       * `onMutate` is called async. This is problematic because the optimistic
       * updates won't have been made before the entry modal closes. This causes
       * the following scenario which is very visible to the user:
       *
       * 1. Open an entry in the modal and make a change
       * 2. Press the done button
       * 3. The modal closes before the optimsitic update is made, causing the
       *    schedule to revert back to the state it was in before the modal opened
       * 4. A split second later, the schedule is updated again optimistically with
       *    the changes in step 1
       */
      const rollbackEntry = queryNormalizer.getObjectById(entry.contextId);
      const undecoratedEntry = undecorateEntry(entry);
      queryNormalizer.setNormalizedData(undecoratedEntry);

      return mutate(entry, {
        ...mutateOptions,
        onError: (...args) => {
          queryNormalizer.setNormalizedData(rollbackEntry);
          mutateOptions?.onError?.(...args);
        },
      });
    },
    [mutate, queryNormalizer]
  );

  const addExclusion = useCallback(
    (entry: DecoratedEntry, exclusion: DateTime, options?: MutateFnOptions) => {
      return updateEntry(
        {
          ...entry,
          exclusions: [
            ...entry.exclusions,
            entry.recurrences[0].isOnDay
              ? datePartInZone(exclusion, entry.timeZone).toUTC()
              : exclusion.toUTC(),
          ],
        },
        options
      );
    },
    [updateEntry]
  );

  const hideEntry = useCallback(
    (
      entry: DecoratedEntry,
      instance: DecoratedInstance,
      options?: MutateFnOptions & { onMutate?: (hidden: DateTime[]) => void }
    ) => {
      const startDate =
        typeof instance.sequence === 'number'
          ? entry.recurrences[0].startDate
          : instance.startDate;

      const hidden = entry.hidden.concat(startDate);
      options?.onMutate?.(hidden);
      return updateEntry({ ...entry, hidden }, options);
    },
    [updateEntry]
  );

  const unhideEntry = useCallback(
    (
      entry: DecoratedEntry,
      instance: DecoratedInstance,
      options?: MutateFnOptions & { onMutate?: (hidden: DateTime[]) => void }
    ) => {
      const startDate =
        typeof instance.sequence === 'number'
          ? entry.recurrences[0].startDate
          : instance.startDate;

      const hidden = entry.hidden.filter((date) => +date !== +startDate);
      options?.onMutate?.(hidden);
      return updateEntry({ ...entry, hidden }, options);
    },
    [updateEntry]
  );

  return {
    updateEntry,
    addExclusion,
    hideEntry,
    unhideEntry,
    isPending,
  };
};
