import { type KeyboardEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOptimisticValue } from '@/hooks/useOptimisticValue';
import {
  Box,
  type BoxProps,
  Button,
  Heading,
  Input,
  type TypographyProps,
  useControllableState,
  useOutsideClick,
} from '@/ui';
import { useScheduleContext } from '../../contexts';
import { useRenameSchedule, useSchedulePermissions } from '../../hooks';

const fontStyle: TypographyProps = {
  fontSize: { base: '2xl', md: '4xl' },
  fontWeight: 900,
  lineHeight: { base: '24px', md: '36px', lg: '59px' },
};

type ControlledProps =
  | {
      isEditing: boolean;
      onEditChange: (editing: boolean) => void;
    }
  | {
      isEditing?: never;
      onEditChange?: never;
    };

type Props = BoxProps &
  ControlledProps & {
    defaultTitle?: string;
  };

export const ScheduleTitle = ({
  defaultTitle,
  isEditing: controlledIsEditing,
  onEditChange: controlledOnEditChange,
  ...props
}: Props): JSX.Element => {
  const { t } = useTranslation(['common', 'header']);
  const { schedule } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const canEdit = hasSchedulePermission('SCHEDULE_RENAME');
  const inputRef = useRef<HTMLInputElement>(null);
  const { renameSchedule } = useRenameSchedule();
  const [localTitle, setLocalTitle] = useOptimisticValue(schedule?.title ?? '');

  const [isEditing, setEditing] = useControllableState(
    typeof controlledIsEditing === 'boolean'
      ? {
          value: controlledIsEditing,
          onChange: controlledOnEditChange,
        }
      : {
          defaultValue: false,
        }
  );

  const handleSubmit = () => {
    if (isEditing && localTitle !== schedule?.title) {
      renameSchedule(localTitle);
    }
    setEditing(false);
  };

  useOutsideClick({
    ref: inputRef,
    handler: handleSubmit,
  });

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    } else if (event.key === 'Escape') {
      setLocalTitle(schedule?.title ?? '');
      setEditing(false);
    }
  };

  if (!canEdit) {
    return (
      <Heading as="h1" {...fontStyle}>
        {schedule?.title || defaultTitle || t('common:untitled_schedule')}
      </Heading>
    );
  }

  return (
    <Box pos="relative" {...props}>
      <Button
        aria-label={t('header:aria.rename_schedule')}
        borderColor="transparent"
        borderRadius="md"
        borderWidth={2}
        colorScheme="dark"
        display="inline-block"
        h={{ base: '36px', md: '48px', lg: '60px' }}
        maxW="100%"
        minW="auto"
        overflow="hidden"
        pt={{ base: '2px', lg: 0 }}
        px="2"
        textOverflow="ellipsis"
        variant="ghost"
        visibility={isEditing ? 'hidden' : 'visible'}
        {...fontStyle}
        _active={{
          borderColor: 'gray.200',
        }}
        _hover={{
          cursor: 'text',
          borderColor: 'gray.200',
        }}
        onClick={() => setEditing(true)}
      >
        {localTitle || defaultTitle || t('common:untitled_schedule')}
      </Button>

      {isEditing && (
        <Input
          _focus={{ borderWidth: 2, boxShadow: 'none' }}
          autoFocus
          borderColor="transparent"
          borderRadius="md"
          borderWidth={2}
          display="block"
          focusBorderColor="brand.500"
          h={{ base: '36px', md: '48px', lg: '60px' }}
          minW="80px"
          placeholder={defaultTitle || t('common:untitled_schedule')}
          pos="absolute"
          pt={{ base: '2px', lg: '3px' }}
          px="2"
          ref={inputRef}
          top={0}
          transition="none"
          value={localTitle}
          {...fontStyle}
          onChange={(event) => setLocalTitle(event.currentTarget.value)}
          onFocus={(event) => event.target.select()}
          onKeyDown={handleKeyPress}
        />
      )}
    </Box>
  );
};
