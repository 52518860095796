import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  ChangePasswordMutation,
  ChangePasswordMutationVariables,
} from './useChangePassword.generated';

const query = gql`
  mutation ChangePassword(
    $currentPassword: String!
    $updatedPassword: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      updatedPassword: $updatedPassword
    )
  }
`;

export const useChangePassword = () => {
  return useMutation<
    ChangePasswordMutation,
    QueryError,
    ChangePasswordMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        ChangePasswordMutation,
        ChangePasswordMutationVariables
      >(query, variables),
  });
};
