import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdRefresh } from 'react-icons/io';
import { useLocalStorage } from 'react-use';
import semiver from 'semiver';
import { config } from '@/config';
import { useCurrentUserContext } from '@/contexts';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Box, Button, Text } from '@/ui';

export const UpdateAvailableCallout = () => {
  const { value: requiredVersion, loading: loadingRequiredVersion } =
    useFeatureFlag('requiredVersion');
  const { isAuthenticated } = useCurrentUserContext();
  const { t } = useTranslation('updateAvailableCallout');
  const [refreshing, setRefreshing] = useState(false);
  const { trackEvent } = useAnalytics();

  const [lastUpdate, setLastUpdate] = useLocalStorage<DateTime>(
    '_ah_last_update_callout',
    undefined,
    {
      raw: false,
      serializer: (value) => value.toISO(),
      deserializer: (value) => DateTime.fromISO(value),
    }
  );

  const showBanner =
    refreshing ||
    (typeof requiredVersion === 'string' &&
      config.version &&
      !config.isPreviewDeploy &&
      !loadingRequiredVersion &&
      isAuthenticated &&
      // The client's current version is outdated
      semiver(requiredVersion, config.version) === 1 &&
      // It's been >24 hours since the last update
      (!lastUpdate || DateTime.now().diff(lastUpdate, 'hours').hours > 24));

  useEffect(() => {
    if (showBanner) {
      trackEvent('update-banner:view');
    }
  }, [showBanner, trackEvent]);

  if (!showBanner) {
    return null;
  }

  return (
    <Box
      bg="brand.500"
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      bottom="0"
      color="white"
      left="8"
      maxW="350px"
      p="8"
      pos="fixed"
      shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.1)"
    >
      <Text fontSize="lg" fontWeight="bold">
        {t('title')}
      </Text>
      <Text mb="5" mt="3">
        {t('description')}
      </Text>
      <Button
        colorScheme="whiteAlpha"
        isLoading={refreshing}
        leftIcon={<IoMdRefresh />}
        onClick={() => {
          setRefreshing(true);
          setLastUpdate(DateTime.now());
          trackEvent('update-banner:reload');
          window.location.reload();
        }}
      >
        {t('reload')}
      </Button>
    </Box>
  );
};
