import { getEntryCategoryColors } from '@/pages/Schedule/utils';
import { Text } from '@/ui';
import type { Category as CategoryType } from '../../Category';
import { Pill } from './Pill';

type Props = {
  category: CategoryType;
  onRemove?: () => void;
};

export const Category = ({ category, onRemove }: Props) => {
  const colors = getEntryCategoryColors(category?.color);
  const hasCategoryText = Boolean(category?.text);

  return (
    <Pill
      bg={colors.backgroundColor}
      borderColor={colors.borderColor}
      h="26px"
      w={hasCategoryText ? 'auto' : '50px'}
      onRemove={onRemove}
    >
      {category?.text && (
        <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
          {category.text}
        </Text>
      )}
    </Pill>
  );
};
