import type { MouseEventHandler } from 'react';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';
import { useOpenRegisterModal } from '@/components/RegisterModal';
import { useCurrentUserContext } from '@/contexts';
import { useRequestAccessModal } from '@/pages/Schedule/components/RequestAccessModal';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { AttendeeStatus } from '@/types/gql.generated';
import { Button, type ButtonProps } from '@/ui';
import { useMemberModal } from '../../MemberModal';

type Props = ButtonProps & {
  status: AttendeeStatus;
  entry: DecoratedEntry;
};

export const RsvpButton = ({ entry, status, onClick, ...props }: Props) => {
  const { isAuthenticated } = useCurrentUserContext();
  const { schedule } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { openRegisterModal } = useOpenRegisterModal();
  const { open: openRequestAccessModal } = useRequestAccessModal();
  const { open: openMemberModal } = useMemberModal();
  const form = schedule?.memberForm;
  const formSubmission = schedule?.selfMemberFormSubmission;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    if (!isAuthenticated) {
      return openRegisterModal({
        action: 'rsvp_item',
        launch: `rsvp-item:${entry.id}:${status}`,
        onAuthenticated: () => {
          if (!hasSchedulePermission('RSVP')) {
            return openRequestAccessModal();
          }
          onClick?.(event);
        },
      });
    }

    if (!hasSchedulePermission('RSVP')) {
      return openRequestAccessModal();
    }

    if (!form || formSubmission) {
      return onClick?.(event);
    }

    openMemberModal((closeReason) => {
      if (closeReason === 'submit') {
        onClick?.(event);
      }
    });
  };

  const icon =
    status === AttendeeStatus.Declined ? (
      <FaRegThumbsDown />
    ) : (
      <FaRegThumbsUp />
    );

  return (
    <Button
      colorScheme="dark"
      leftIcon={icon}
      size={{ base: 'xs', sm: 'sm' }}
      variant="shadow"
      onClick={handleClick}
      {...props}
    />
  );
};
