import {
  Icon as ChakraIcon,
  forwardRef,
  type IconProps as ChakraIconProps,
} from '@chakra-ui/react';
import { Icons } from './icons';

type CustomIconProps =
  | {
      icon: keyof typeof Icons;
      size?: IconProps['boxSize'];
      boxSize?: never;
    }
  | {
      icon?: never;
      size?: never;
    };

export type IconProps = ChakraIconProps & CustomIconProps;

export const Icon = forwardRef<IconProps, typeof ChakraIcon>(
  function IconWithRef({ icon, size = 4, ...props }: IconProps, ref) {
    if (icon) {
      return (
        <ChakraIcon as={Icons[icon]} boxSize={size} ref={ref} {...props} />
      );
    }
    return <ChakraIcon ref={ref} {...props} />;
  }
);
