import { Trans } from 'react-i18next';
import { Box, type BoxProps, Link } from '@/ui';

export const Terms = (props: BoxProps) => {
  return (
    <Box
      alignSelf="center"
      color="customgray.mid"
      fontSize="14"
      maxW="90%"
      textAlign="center"
      {...props}
    >
      <Trans i18nKey="terms" ns="auth">
        By signing in, you agree to our{' '}
        <Link colorScheme="dark" href="/tos">
          Terms
        </Link>{' '}
        and have read and acknowledge our{' '}
        <Link colorScheme="dark" href="/privacy">
          Privacy Statement
        </Link>
        .
      </Trans>
    </Box>
  );
};
