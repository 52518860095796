import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  RenameScheduleMutation,
  RenameScheduleMutationVariables,
} from './useRenameSchedule.generated';

const query = gql`
  mutation RenameSchedule($scheduleId: ID!, $title: String) {
    updateSchedule(scheduleId: $scheduleId, title: $title) {
      id
      title
    }
  }
`;

export const useRenameSchedule = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    RenameScheduleMutation,
    QueryError,
    RenameScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        RenameScheduleMutation,
        RenameScheduleMutationVariables
      >(query, variables),
  });

  const renameSchedule = (
    title: string,
    options?: MutateOptions<
      RenameScheduleMutation,
      QueryError,
      RenameScheduleMutationVariables
    >
  ) => {
    return mutate({ scheduleId, title }, options);
  };

  return {
    renameSchedule,
  };
};
