import { AiOutlineCheck } from 'react-icons/ai';
import { Pill } from '@/pages/Schedule/components';
import { Box, Flex, type FlexProps } from '@/ui';

type Props<T> = Omit<FlexProps, 'onClick'> & {
  labels: T[];
  selected?: T[];
  direction?: 'row' | 'column';
  showSelectedCheckmark?: boolean;
  onClick?: (label: T) => void;
};

export const LabelList = <T extends { id: string; text: string }>({
  labels,
  selected,
  direction = 'column',
  showSelectedCheckmark = true,
  onClick,
  ...flexProps
}: Props<T>) => {
  return (
    <Flex direction={direction} gap="2" {...flexProps}>
      {labels.map((label) => {
        const isSelected = !!selected?.find(({ id }) => id === label.id);

        return (
          // Box container ensures the pill doesn't take up 100%
          // width due to the flex container
          <Box key={label.id}>
            <Pill
              disabled={!isSelected}
              onClick={onClick ? () => onClick(label) : undefined}
            >
              {showSelectedCheckmark ? (
                <Flex align="center" gap="2">
                  <Box>{label.text}</Box>
                  {isSelected && showSelectedCheckmark && <AiOutlineCheck />}
                </Flex>
              ) : (
                label.text
              )}
            </Pill>
          </Box>
        );
      })}
    </Flex>
  );
};
