import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  RequestResetPasswordMutation,
  RequestResetPasswordMutationVariables,
} from './useResetPasswordRequest.generated';

const query = gql`
  mutation RequestResetPassword($email: LowercaseString!) {
    requestPasswordReset(email: $email)
  }
`;

export const useResetPasswordRequest = () => {
  return useMutation<
    RequestResetPasswordMutation,
    QueryError,
    RequestResetPasswordMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        RequestResetPasswordMutation,
        RequestResetPasswordMutationVariables
      >(query, variables),
  });
};
