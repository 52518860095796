import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type LaunchState = 'create-schedule' | 'onboarding' | 'default';

type ModalConfig = {
  launchState: LaunchState;
};

type State = {
  isOpen: boolean;
  open: (config?: ModalConfig) => void;
  close: () => void;
  reset: () => void;
  resetConfig: () => void;
  config: ModalConfig;
};

const initialConfig: ModalConfig = {
  launchState: 'default',
};

export const useAddContentModalStore = createWithEqualityFn<State>()(
  (set) => ({
    isOpen: false,
    open: (config) =>
      set({ isOpen: true, config: { ...initialConfig, ...config } }),
    close: () => set({ isOpen: false }),
    reset: () => set({ isOpen: false, config: initialConfig }),
    resetConfig: () => set({ config: initialConfig }),
    config: initialConfig,
  }),
  shallow
);
