import { useContext } from 'react';
import { createContextHook } from '@/utils';
import { ScheduleContext } from '../ScheduleContext';
import type { ScheduleContextValue } from '../types';

export const useScheduleContext = createContextHook(ScheduleContext);

export const useUnsafeScheduleContext = ():
  | ScheduleContextValue
  | undefined => {
  return useContext(ScheduleContext);
};
