import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useQueryParams } from '@/hooks/useQueryParams';
import type { ViewModule } from '@/pages/Schedule/views';
import { getStartOfWeek } from '@/utils/dates';

/**
 * A note on the end date:
 *
 * The end date is usually calculated based on the start date and the view
 * you've selected but sometimes we need to fetch data outside of the
 * view's parameters. Example: infinite scrolling on the month view
 * or the summary view where you can explicitly choose an end date.
 *
 * We also don't always want the endDate to appear in the URL. On the agenda
 * view it should because users can explicitly select an end date, but it
 * shouldn't sync with the URL on the month view because users are just scrolling
 * through and it's not a conscious selection.
 *
 * Therefore, the URL is NOT the source of truth for the end date. The URL
 * and the customEndDate state are two ingredients used to derive the end date.
 *
 * In general, we want to derive the dates because storing them in state
 * can result in a jankly experience when a new state is triggered.
 */

export const useScheduleDates = (
  viewModule: ViewModule,
  scheduleTimeZone: string
) => {
  const { setQueryParams } = useQueryParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const queryStartDate = searchParams.get('startDate');
  const queryEndDate = searchParams.get('endDate');

  const [customEndDate, setCustomEndDate] = useState<DateTime | null>(null);
  useEffect(() => {
    setCustomEndDate(null);
  }, [viewModule?.id]);

  const startDate = useMemo(() => {
    let startDate = viewModule.adjustStartDate(
      DateTime.local({ zone: scheduleTimeZone })
    );

    if (queryStartDate) {
      const parsedStartDate = DateTime.fromISO(queryStartDate, {
        zone: scheduleTimeZone,
      });
      if (parsedStartDate.isValid) {
        startDate = viewModule.adjustStartDate(parsedStartDate);
      }
    }

    return startDate;
  }, [queryStartDate, viewModule, scheduleTimeZone]);

  const endDate = useMemo(() => {
    const endDateObject = queryEndDate
      ? DateTime.fromISO(queryEndDate, { zone: scheduleTimeZone })
      : null;

    if (endDateObject?.isValid) {
      return endDateObject;
    }
    if (customEndDate) {
      return customEndDate;
    }

    return viewModule.adjustEndDate(startDate);
  }, [viewModule, customEndDate, startDate, queryEndDate, scheduleTimeZone]);

  const moveToDate = useCallback(
    (startDate: DateTime, endDate?: DateTime) => {
      const params = createSearchParams(Object.fromEntries(searchParams));
      params.set('startDate', startDate.toISODate());

      if (endDate) {
        params.set('endDate', endDate.toISODate());
      } else {
        params.delete('endDate');
      }

      setSearchParams(params);
    },
    [setSearchParams, searchParams]
  );

  const moveToWeekOf = useCallback(
    (date: DateTime) => {
      const startOfWeek = getStartOfWeek(date);
      moveToDate(startOfWeek);
    },
    [moveToDate]
  );

  const moveToToday = useCallback(() => {
    const startDate = viewModule.adjustTodayStartDate(scheduleTimeZone);
    const endDate = viewModule.adjustTodayEndDate(scheduleTimeZone);

    setQueryParams({
      startDate: startDate.toISODate(),
      endDate: endDate?.toISODate() || null,
    });
  }, [setQueryParams, viewModule, scheduleTimeZone]);

  const movePrevious = useCallback(() => {
    if (viewModule.adjustPreviousStartDate) {
      const date = viewModule.adjustPreviousStartDate(startDate);
      moveToDate(date);
    }
  }, [viewModule, startDate, moveToDate]);

  const moveNext = useCallback(() => {
    if (viewModule.adjustNextStartDate) {
      const date = viewModule.adjustNextStartDate(startDate);
      moveToDate(date);
    }
  }, [viewModule, startDate, moveToDate]);

  const api = useMemo(
    () => ({
      startDate,
      endDate,
      setEndDate: setCustomEndDate,
      moveToToday,
      moveToDate,
      moveToWeekOf,
      movePrevious,
      moveNext,
    }),
    [
      startDate,
      endDate,
      setCustomEndDate,
      moveToToday,
      moveToDate,
      moveToWeekOf,
      movePrevious,
      moveNext,
    ]
  );

  return api;
};
