import { useTranslation } from 'react-i18next';
import { useIsMobile } from '@/hooks/useIsBreakpoint';
import { Button, Text, Icon } from '@/ui';

type Props = {
  isActive: boolean;
  onClick: () => void;
};

export const DeleteButton = ({ isActive, onClick }: Props) => {
  const { t } = useTranslation('addContent');
  const { isMobile } = useIsMobile();

  return (
    <Button
      aria-label={t('aria_delete')}
      borderRadius="full"
      className={isActive ? 'is-active' : undefined}
      color="white"
      colorScheme="purple"
      h="8"
      minW="8"
      opacity={isActive || isMobile ? 1 : 0}
      p="0"
      pos="absolute"
      px="2"
      right="-2"
      shadow="-2px 2px 4px 0px rgba(0, 0, 0, 0.16)"
      top="-2"
      transition="all 0.2s ease-in-out"
      transitionDelay="0.02s"
      _groupHover={{
        transitionDelay: '0s', // ensure the enter animation happens immediately
        opacity: 1,
      }}
      sx={
        isMobile
          ? undefined
          : {
              '&:hover, &.is-active': {
                minW: 16,
                '.icon': {
                  opacity: 0,
                },
                '.delete': {
                  opacity: 1,
                },
              },
            }
      }
      onClick={onClick}
    >
      <Icon
        className="icon"
        icon="Trash"
        opacity="1"
        pos="absolute"
        transition="opacity 0.1s ease-in-out"
      />
      <Text
        className="delete"
        opacity="0"
        pos="absolute"
        transition="opacity 0.1s ease-in-out"
      >
        {t('aria_delete')}
      </Text>
    </Button>
  );
};
