import { Flex, type FlexProps } from '@/ui';

export const OptionButton = (props: FlexProps) => {
  return (
    <Flex
      _active={{ transform: 'scale(0.98)' }}
      _focusVisible={{ outline: 'none', shadow: 'outline' }}
      _hover={{ shadow: '0px 0px 10px 0px var(--chakra-colors-purple-300)' }}
      align="center"
      as="button"
      bg="white"
      border="1px solid rgba(113, 106, 221, 0.20)"
      borderRadius="lg"
      flex="1"
      flexDirection="column"
      fontSize="xl"
      fontWeight="bold"
      justify="center"
      lineHeight="normal"
      minH={{ base: '180px', md: '240px' }}
      my="auto"
      p="3"
      pos="relative"
      transition="all 0.1s ease-in-out"
      {...props}
    />
  );
};
