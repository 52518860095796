import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobile } from '@/hooks/useIsBreakpoint';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  useToggleScheduleHeaderEnabled,
  useSchedulePermissions,
} from '@/pages/Schedule/hooks';
import { MotionFlex, useDisclosure } from '@/ui';
import { useOpenScheduleHeaderModal } from '../ScheduleHeaderModal';
import { BannerMenu } from './components/BannerMenu';

export const Banner = () => {
  const { schedule } = useScheduleContext();
  const { hasSchedulePermission } = useSchedulePermissions();
  const { toggleScheduleHeaderEnabled } = useToggleScheduleHeaderEnabled();
  const { t } = useTranslation('schedule', { keyPrefix: 'banner' });
  const { isMobile } = useIsMobile();
  const { open: openModal } = useOpenScheduleHeaderModal();
  const { trackEvent } = useAnalytics();
  const toast = useToast();

  const {
    isOpen: isMenuOpen,
    onOpen: openMenu,
    onClose: closeMenu,
  } = useDisclosure();

  const isEnabled = schedule?.header.enabled ?? false;
  const hasEditPermissions = hasSchedulePermission('SCHEDULE_RENAME');

  const handleRemove = () => {
    toggleScheduleHeaderEnabled(false, {
      onError: () =>
        toast.error(isEnabled ? t('error_removing') : t('error_adding')),
    });
    trackEvent('schedule:click remove header');
  };

  if (!isEnabled || !schedule?.header.imageBaseUrl) {
    return null;
  }

  return (
    <AnimatePresence mode="wait">
      <MotionFlex
        animate={{ opacity: 1 }}
        backgroundImage={schedule.header.imageBaseUrl + 'header'}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        bgColor="white"
        borderTopRadius={{ xl: 'xl' }}
        data-group
        exit={{ opacity: 0 }}
        h="0"
        initial={{ opacity: 0 }}
        key={schedule.header.imageBaseUrl}
        position="relative"
        pt="calc(600 / 3000 * 100%)"
        transition={{ duration: 0.2 }}
        w="100%"
      >
        {hasEditPermissions && (
          <BannerMenu
            _groupHover={{ opacity: 1 }}
            isMenuOpen={isMenuOpen}
            opacity={isMobile || isMenuOpen ? 1 : 0}
            pos="absolute"
            right={{ base: 3, md: 4 }}
            top={{ base: 3, md: 4 }}
            transition="opacity 0.1s ease-in-out"
            onChange={openModal}
            onMenuClose={closeMenu}
            onMenuOpen={openMenu}
            onRemove={handleRemove}
          />
        )}
      </MotionFlex>
    </AnimatePresence>
  );
};
