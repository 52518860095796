import { AnimatePresence } from 'framer-motion';
import { useCreateScheduleModalStore } from '@/pages/Dashboard/components/CreateScheduleModal';
import { useAddContentModalStore } from '@/pages/Schedule/components/AddContentModal';
import { useOnboardingModalStore } from '@/pages/Schedule/components/OnboardingModal';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import { fadeConfig, MotionBox } from '@/ui';

export const SharedModalOverlay = () => {
  const { isOpen: isCreateScheduleModalOpen } = useCreateScheduleModalStore();
  const { isOpen: isAddContentModalOpen } = useAddContentModalStore();
  const { isOpen: isOnboardingModalOpen } = useOnboardingModalStore();
  const isEntryModalOpen = useDraftEntryStore(
    (store) => Boolean(store.draftEntry) && store.draftComposer === 'modal'
  );

  const renderSharedModalOverlay =
    isCreateScheduleModalOpen ||
    isAddContentModalOpen ||
    isOnboardingModalOpen ||
    isEntryModalOpen;

  return (
    <AnimatePresence>
      {renderSharedModalOverlay && (
        // @ts-expect-error Ignore TS error on `fadeConfig`.
        // This component follows the same pattern as Chakra and Chakra itself works around the problem by using the `any` type.
        // https://github.com/chakra-ui/chakra-ui/blob/4e2df65d03d3ac236371e9dc6430902b5b7a3465/packages/components/modal/src/modal-overlay.tsx#L47
        <MotionBox
          bg="blackAlpha.600"
          h="100vh"
          inset="0"
          pos="fixed"
          w="100vw"
          zIndex="modal"
          {...fadeConfig}
        />
      )}
    </AnimatePresence>
  );
};
