import type { DateTime } from 'luxon';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { getEntryInstances } from '@/pages/Schedule/utils';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import type { ScheduleFilters } from '../ScheduleFiltersContext';
import type { GetScheduleEntriesQueryVariables } from './hooks/useEntries.generated';
import type { EntriesContextOptions } from './types';

export const getEntryInstancesWithDraft = (
  instances: DecoratedInstance[],
  draftEntry: DecoratedEntry,
  startDate: DateTime,
  endDate: DateTime,
  scheduleTimeZone: string
) => {
  const draftInstances = getEntryInstances(
    draftEntry,
    startDate,
    endDate,
    scheduleTimeZone
  );
  return instances
    .filter((entry) => entry.parentId !== draftEntry.id)
    .concat(draftInstances);
};

export const createEntriesQueryConfig = (
  scheduleId: string,
  startDate: DateTime,
  endDate: DateTime,
  filters: ScheduleFilters,
  options?: EntriesContextOptions
) => {
  const startDateString = startDate.toUTC().toISO();
  const endDateString = endDate.toUTC().toISO();
  const dateRange = { startDate: startDateString, endDate: endDateString };
  const queryKey = createEntriesQueryKey(scheduleId, dateRange, filters);

  const variables: GetScheduleEntriesQueryVariables = {
    scheduleId,
    startDate: startDateString,
    endDate: endDateString,
    includeMessages: options?.includeMessages ?? false,
    includeRsvps: options?.includeRsvps ?? false,
  };

  if (filters.categories.length) {
    variables.categories = filters.categories;
  }
  if (filters.labels.length) {
    variables.labels = filters.labels;
  }
  if (filters.whoLabels.length) {
    variables.whoLabels = filters.whoLabels;
  }
  if (filters.whoLabels.length) {
    variables.whoLabels = filters.whoLabels;
  }
  if (filters.feeds.length) {
    variables.feeds = filters.feeds;
  }

  return {
    queryKey,
    variables,
  };
};

export const createRecurrenceKey = (
  entry?: DecoratedEntry
): string | undefined => {
  const recurrence = entry?.recurrences[0];

  if (recurrence) {
    return [
      recurrence.startDate.toISO(),
      recurrence.endDate.toISO(),
      recurrence.isOnDay,
      recurrence.rule,
    ].join(',');
  }

  return undefined;
};
