import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import type { EntryModalMessage } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import type { QueryError } from '@/utils/errors';
import {
  createEntriesQueryKey,
  createEntryMessagesQueryKey,
} from '@/utils/queryKeys';
import type {
  CreateEntryMessageMutation,
  CreateEntryMessageMutationVariables,
} from './useCreateEntryMessage.generated';

const query = gql`
  mutation CreateEntryMessage(
    $scheduleId: ID!
    $entryId: ID!
    $entryMessageId: ID
    $instance: DateTime!
    $body: String!
    $deliveryTimeType: DeliveryTimeType!
    $hybridDeliveryTime: LocalTime
    $hybridRelativeTime: HybridRelativeTime
    $hybridTimeZone: TimeZone
    $relativeTimeToEntryStart: Int
    $relativeTimeToEntryStartUnit: TimeUnit
    $relativeTimeToEntryStartDirection: TimeDirection
    $recipients: [ID!]
    $sendToEveryone: Boolean
  ) {
    createEntryMessage(
      scheduleId: $scheduleId
      entryId: $entryId
      entryMessageId: $entryMessageId
      instance: $instance
      body: $body
      deliveryTimeType: $deliveryTimeType
      hybridDeliveryTime: $hybridDeliveryTime
      hybridRelativeTime: $hybridRelativeTime
      hybridTimeZone: $hybridTimeZone
      relativeTimeToEntryStart: $relativeTimeToEntryStart
      relativeTimeToEntryStartUnit: $relativeTimeToEntryStartUnit
      relativeTimeToEntryStartDirection: $relativeTimeToEntryStartDirection
      recipients: $recipients
      sendToEveryone: $sendToEveryone
    ) {
      id
    }
  }
`;

export const useCreateEntryMessage = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...rest } = useMutation<
    CreateEntryMessageMutation,
    QueryError,
    CreateEntryMessageMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        CreateEntryMessageMutation,
        CreateEntryMessageMutationVariables
      >(query, variables),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });

      return queryClient.invalidateQueries({
        queryKey: createEntryMessagesQueryKey(scheduleId, variables.entryId),
      });
    },
  });

  const createEntryMessage = useCallback(
    (
      entry: DecoratedEntry,
      message: EntryModalMessage,
      options?: MutateOptions<
        CreateEntryMessageMutation,
        QueryError,
        CreateEntryMessageMutationVariables
      >
    ) => {
      const { recipients, instance, ...rest } = message;

      return mutate(
        {
          scheduleId,
          entryId: entry.id,
          entryMessageId: message.id,
          recipients: recipients.map(({ id }) => id),
          instance: instance.toUTC().toISO(),
          sendToEveryone: !recipients.length,
          ...rest,
        },
        options
      );
    },
    [scheduleId, mutate]
  );

  return { createEntryMessage, ...rest };
};
