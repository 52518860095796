import { useConditionallyMount } from '@/hooks/useConditionallyMount';
import { CreateScheduleModal } from './CreateScheduleModal';
import { useCreateScheduleModalStore } from './store';

export const CreateScheduleModalLoader = () => {
  const { isOpen } = useCreateScheduleModalStore();
  const { mounted, setMounted } = useConditionallyMount(isOpen);

  if (!mounted) {
    return null;
  }

  return <CreateScheduleModal onCloseComplete={() => setMounted(false)} />;
};
