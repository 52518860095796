import { useEffect } from 'react';
import { useLauncher } from '@/hooks/useLauncher';
import { Flex, Modal, ModalContent, ModalOverlay } from '@/ui';
import { Sidebar } from './components/Sidebar';
import { isSettingsModalTab, useSettingsModal } from './store';
import { Account } from './tabs/Account';
import { Calendars } from './tabs/Calendars';
import { Help } from './tabs/Help';

export const SettingsModal = () => {
  const { isOpen, open, close, tab } = useSettingsModal();
  const { launch, clearLaunch } = useLauncher();

  useEffect(() => {
    if (launch?.startsWith('settings:')) {
      const tab = launch.substring('settings:'.length);
      if (isSettingsModalTab(tab)) {
        open(tab);
        clearLaunch();
      }
    }
  }, [launch, clearLaunch, open]);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      returnFocusOnClose={false}
      variant="primary"
      onClose={close}
    >
      <ModalOverlay />
      <ModalContent
        display="flex"
        flexDir="row"
        h="calc(var(--app-height) - 50px)"
        maxH={{ md: '640px' }}
        minH={{ base: 'var(--app-height)', md: 'auto' }}
        minW={{ base: '100vw', md: '90%', lg: '900px' }}
        overflow="hidden" // 👇 allows rounded corners with a scrollbar
      >
        <Sidebar display={{ base: 'none', md: 'flex' }} />

        <Flex direction="column" flex="1">
          {tab === 'account' ? (
            <Account />
          ) : tab === 'help' ? (
            <Help />
          ) : tab === 'calendars' ? (
            <Calendars />
          ) : null}
        </Flex>
      </ModalContent>
    </Modal>
  );
};
