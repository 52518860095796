import type { ReactNode } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

type Props = {
  paths?: string[];
  pathsNot?: string[];
  children: ReactNode;
};

export const RenderIf = ({ paths, pathsNot, children }: Props) => {
  const location = useLocation();

  const matches =
    (!paths ||
      paths.some((path) => matchPath(path, location.pathname) !== null)) &&
    (!pathsNot ||
      !pathsNot.some((path) => matchPath(path, location.pathname) !== null));

  return matches ? children : null;
};
