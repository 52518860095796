import type { RawEntry } from '../contexts';
import type { DecoratedEntry } from '../types';

export const undecorateEntry = (entry: DecoratedEntry): RawEntry => ({
  ...entry,
  createdAt: entry.createdAt.toUTC().toISO(),
  recurrences: entry.recurrences.map((recurrence) => ({
    ...recurrence,
    startDate: recurrence.startDate.toUTC().toISO(),
    endDate: recurrence.endDate.toUTC().toISO(),
  })),
  hidden: entry.hidden.map((date) => date.toUTC().toISO()),
  exclusions: entry.exclusions.map((date) => date.toUTC().toISO()),
  messages: entry.messages?.map((message) => ({
    ...message,
    instance: message.instance.toUTC().toISO(),
  })),
});
