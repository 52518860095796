import { useTranslation } from 'react-i18next';
import { Button, forwardRef, Text } from '@/ui';
import { Confetti } from './Confetti';
import { StateCard, type StateCardProps } from './StateCard';

type Props = StateCardProps & {
  onClose: () => void;
  onReset: () => void;
};

export const SuccessState = forwardRef(
  ({ onClose, onReset, ...props }: Props, ref) => {
    const { t } = useTranslation('addContent', { keyPrefix: 'success_state' });

    return (
      <StateCard ref={ref} {...props}>
        <Confetti mx="auto" />

        <Text fontSize="48px" lineHeight="1">
          🎉
        </Text>
        <Text fontSize="lg" fontWeight="bold">
          {t('title')}
        </Text>
        <Button colorScheme="dark" onClick={onClose}>
          {t('close')}
        </Button>
        <Text>
          {t('or')}{' '}
          <Button
            colorScheme="dark"
            fontSize="md"
            textDecor="underline"
            variant="link"
            onClick={onReset}
          >
            {t('add_more')}
          </Button>
        </Text>
      </StateCard>
    );
  }
);
