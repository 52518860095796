import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { shouldRedirectOnLogout } from '@/components/auth';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useLogout } from '@/hooks/useLogout';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Divider,
  Button,
  Icon,
  IconButton,
  MenuDivider,
} from '@/ui';
import { useSettingsModal } from '../store';
import { Tab } from './Tab';

export const Navigation = () => {
  const { tab, close, changeTab } = useSettingsModal();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { t } = useTranslation(['settingsModal', 'auth']);
  const { logout } = useLogout();
  const navigate = useNavigate();

  const handleAccountClick = () => {
    changeTab('account');
  };

  const handleHelpClick = () => {
    changeTab('help');
  };

  const handleCalendarsClick = () => {
    changeTab('calendars');
  };

  const handleLogoutClick = () => {
    logout({
      onSuccess: () => {
        close();

        const { shouldRedirect, path } = shouldRedirectOnLogout();
        if (shouldRedirect) {
          navigate(path);
        }
      },
    });
  };

  if (isMobileBreakpoint) {
    return (
      <Menu isLazy>
        <MenuButton
          as={IconButton}
          colorScheme="dark"
          icon={<Icon icon="Menu" size="5" />}
          ml="-2"
          size="sm"
          variant="ghost"
        />
        <MenuList fontSize="md">
          <MenuItem icon={<Icon icon="User" />} onClick={handleAccountClick}>
            {t('navigation.account')}
          </MenuItem>
          <MenuItem
            icon={<Icon icon="Calendar" />}
            onClick={handleCalendarsClick}
          >
            {t('navigation.calendars')}
          </MenuItem>
          <MenuItem icon={<Icon icon="HelpCircle" />} onClick={handleHelpClick}>
            {t('navigation.help')}
          </MenuItem>
          <MenuDivider />
          <MenuItem icon={<Icon icon="Logout" />} onClick={handleLogoutClick}>
            {t('auth:sign_out')}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <>
      <Tab
        isActive={tab === 'account'}
        leftIcon={<Icon icon="User" />}
        onClick={handleAccountClick}
      >
        {t('navigation.account')}
      </Tab>
      <Tab
        isActive={tab === 'calendars'}
        leftIcon={<Icon icon="Calendar" />}
        onClick={handleCalendarsClick}
      >
        {t('navigation.calendars')}
      </Tab>
      <Tab
        isActive={tab === 'help'}
        leftIcon={<Icon icon="HelpCircle" />}
        onClick={handleHelpClick}
      >
        {t('navigation.help')}
      </Tab>

      <Divider my="4" />

      <Button
        justifyContent="flex-start"
        leftIcon={<Icon icon="Logout" />}
        pl="4"
        variant="link"
        onClick={handleLogoutClick}
      >
        {t('auth:sign_out')}
      </Button>
    </>
  );
};
