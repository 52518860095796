import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMeasure, useUpdateEffect } from 'react-use';
import { chakra, Input, useColorModeValue } from '@/ui';
import { isValidLabel } from '../utils';

type Props = {
  value?: string;
  validate?: boolean;
  onAdd: (text: string) => void;
  onCancel: () => void;
};

export const PlainLabelInput = ({
  value = '',
  validate = false,
  onAdd,
  onCancel,
}: Props) => {
  const { t } = useTranslation('labelSelect');
  const [measure, { width: contentWidth }] = useMeasure<HTMLSpanElement>();
  const color = useColorModeValue('customgray.dark', 'customgray.light');

  const [text, setText] = useState(value);
  useUpdateEffect(() => {
    setText(value);
  }, [value]);

  const handleSubmit = () => {
    if (!validate || isValidLabel(text)) {
      onAdd(text);
    }
  };

  const styleProps = {
    borderRadius: 'full',
    fontSize: 'sm',
    fontWeight: 'medium',
    px: '3',
    py: '3.5',
  };

  // Resize input to fit text value, measure text with a hidden span
  const paddingX = 24 + 12; // 12px to avoid flickering since the width is calculated after text is already in the input, and the width reflects the previous input
  const inputWidth =
    text.length > 0
      ? contentWidth + paddingX
      : Math.min(contentWidth + paddingX, 270);

  return (
    <>
      <chakra.span
        left={-1000}
        position="absolute"
        ref={measure}
        top={-1000}
        {...styleProps}
        aria-hidden="true"
        whiteSpace="pre"
      >
        {text || t('placeholder')}
      </chakra.span>

      <Input
        autoComplete="off"
        autoFocus
        h="34px"
        placeholder={t('placeholder')}
        size="xs"
        value={text}
        w={`${inputWidth}px`}
        {...styleProps}
        color={color}
        _placeholder={{
          fontWeight: 400,
        }}
        onBlur={() => handleSubmit()}
        onChange={(event) => setText(event.currentTarget.value)}
        onFocus={(event) => event.target.select()}
        onKeyDown={(event) => {
          if (event.key === 'Escape') {
            // Prevent closing the containing modal
            event.stopPropagation();
            onCancel();
          }
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
    </>
  );
};
