import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ListItem,
  UnorderedList,
} from '@/ui';
import { useSnippetIngredientInUseModal } from './useSnippetIngredientInUseModal';

export const SnippetIngredientInUseModal = () => {
  const { t } = useTranslation('snippetIngredientInUseModal');
  const state = useSnippetIngredientInUseModal();
  const cancelRef = useRef(null);

  return (
    <AlertDialog
      isCentered
      isOpen={state.isOpen}
      leastDestructiveRef={cancelRef}
      onClose={state.close}
    >
      {/* z-index is 1 higher than global popover z-index */}
      <AlertDialogOverlay zIndex="calc(var(--chakra-zIndices-popover) + 1)">
        <AlertDialogContent minW={{ md: '550px' }} mx="2">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {t('title', { ingredient: state.ingredient })}
          </AlertDialogHeader>

          <AlertDialogBody>
            {t('message', {
              ingredient: state.ingredient,
              count: state.snippets.length,
            })}
            <UnorderedList mt="2">
              {state.snippets?.map((snippet) => (
                <ListItem key={snippet.id}>{snippet.name}</ListItem>
              ))}
            </UnorderedList>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={state.close}>
              {t('close')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
