import { Component, type ReactNode } from 'react';
import { type WithTranslation, withTranslation } from 'react-i18next';
import { Sentry } from '@/lib/sentry';
import { Button, Center, Heading, Text, VStack } from '@/ui';

type ErrorBoundaryState = {
  error: unknown;
  errorInfo: unknown;
};

type Props = WithTranslation & {
  children: ReactNode;
};

class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: unknown, errorInfo: unknown): void {
    this.setState({
      error,
      errorInfo,
    });
    Sentry.captureException(error, {
      tags: {
        runtime: true,
      },
      extra: {
        errorInfo,
      },
    });
  }

  reload(): void {
    window.location.reload();
  }

  render(): ReactNode {
    if (!this.state.error) {
      return this.props.children;
    }
    return (
      <Center flex="1">
        <VStack
          borderRadius="12"
          px="12"
          py="8"
          transform="translateY(-100%)"
          w="500px"
        >
          <Heading fontSize="24px">
            {this.props.t('error_boundary.title')}
          </Heading>
          <Text pb="4">{this.props.t('error_boundary.message')}</Text>
          <Button variant="secondary" onClick={this.reload}>
            {this.props.t('error_boundary.reload')}
          </Button>
        </VStack>
      </Center>
    );
  }
}

const EnhancedErrorBoundary = withTranslation('common')(ErrorBoundary);

export { EnhancedErrorBoundary as ErrorBoundary };
