import { useId } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import type { QuickEntriesJobFile } from '@/types/gql.generated';
import { Flex, Button, Text, Textarea, useDisclosure } from '@/ui';
import { FilePreview } from '../FilePreview';
import { ExamplesModal } from './ExamplesModal';

type Props = {
  file: File | QuickEntriesJobFile;
  instructions: string;
  isDisabled?: boolean;
  inputId: string;
  onInstructionsChange: (instructions: string) => void;
};

export const UploadPreview = ({
  file,
  instructions,
  isDisabled,
  inputId,
  onInstructionsChange,
}: Props) => {
  const textareaId = useId();
  const { isOpen, onClose: close, onOpen: open } = useDisclosure();

  return (
    <Flex
      bg="rgba(255, 255, 255, 0.5)"
      boxSize="100%"
      direction={{ base: 'column', sm: 'row' }}
      justify="center"
    >
      <ExamplesModal isOpen={isOpen} onClose={close} />

      <Flex
        align="center"
        borderBottomWidth={{ base: 1, sm: 0 }}
        borderColor="gray.200"
        borderRightWidth={{ sm: 1 }}
        direction="column"
        flexBasis="35%"
        gap="3"
        px="6"
        py="4"
      >
        <FilePreview
          file={file}
          maxImageHeight={175}
          maxImageWidth={120}
          pdfWidth={120}
        />

        {!isDisabled && (
          <Button
            as="label"
            colorScheme="dark"
            cursor="pointer"
            htmlFor={inputId}
            textDecor="underline"
            variant="link"
          >
            Replace
          </Button>
        )}
      </Flex>

      <Flex
        align="flex-start"
        direction="column"
        flex="1"
        fontSize="sm"
        gap="1.5"
        justifyItems="start"
        px="6"
        py="4"
      >
        <Text as="label" fontWeight="bold" htmlFor={textareaId}>
          Optional: add some additional context
        </Text>

        <Button
          colorScheme="dark"
          textDecor="underline"
          variant="link"
          onClick={open}
        >
          Helpful tips
        </Button>

        <Textarea
          as={TextareaAutosize}
          bg="white"
          fontSize="sm"
          id={textareaId}
          isDisabled={isDisabled}
          maxLength={500}
          mt="1"
          placeholder='Try "This event repeats every week"'
          value={instructions}
          onChange={(event) => onInstructionsChange(event.target.value)}
        />
      </Flex>
    </Flex>
  );
};
