import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib/gql-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  UpdateScheduleSettingsMutation,
  UpdateScheduleSettingsMutationVariables,
} from './useUpdateScheduleSettings.generated';

type UpdatableSettings = {
  timeZone: string;
  description: string;
  slug: string | null;
};

const query = gql`
  mutation UpdateScheduleSettings(
    $scheduleId: ID!
    $timeZone: TimeZone
    $slug: String
    $description: String!
    $socialSharingEnabled: Boolean
  ) {
    updateSchedule(
      scheduleId: $scheduleId
      timeZone: $timeZone
      slug: $slug
      description: $description
      socialSharingEnabled: $socialSharingEnabled
    ) {
      id
      timeZone
      description
      slug
      socialSharingEnabled
    }
  }
`;

export const useUpdateScheduleSettings = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, ...result } = useMutation<
    UpdateScheduleSettingsMutation,
    QueryError,
    UpdateScheduleSettingsMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateScheduleSettingsMutation,
        UpdateScheduleSettingsMutationVariables
      >(query, variables),
  });

  const updateScheduleSettings = (
    settings: UpdatableSettings,
    options?: MutateOptions<
      UpdateScheduleSettingsMutation,
      QueryError,
      UpdateScheduleSettingsMutationVariables
    >
  ) => {
    const variables = { scheduleId, ...settings };
    return mutate(variables, options);
  };

  return {
    ...result,
    updateScheduleSettings,
  };
};
