import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { Flex, type FlexProps, forwardRef } from '@/ui';
import { ItemCardContextProvider } from '../context/ItemCardContext';

type Props = FlexProps & {
  entry: DecoratedEntry;
  instance?: DecoratedInstance;
  isFocused?: boolean;
};

export const Card = forwardRef<Props, 'div'>(
  ({ entry, instance, isFocused, ...props }, ref) => {
    return (
      <ItemCardContextProvider entry={entry} instance={instance}>
        <Flex
          bg="white"
          borderColor={isFocused ? 'brand.500' : 'customgray.light'}
          borderRadius="xl"
          borderWidth="1px"
          data-entry-id={entry.id}
          data-group
          data-testid={entry.id}
          direction="column"
          flex="1"
          fontSize="16px"
          fontWeight="normal"
          pos="relative"
          py="6"
          ref={ref}
          transition="box-shadow 0.2s ease-in-out"
          w="100%"
          shadow={
            isFocused ? '0px 0px 28px 2px rgba(213, 37, 124, 0.2)' : 'none'
          }
          {...props}
        />
      </ItemCardContextProvider>
    );
  }
);
