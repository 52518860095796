import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAuthErrorMessage } from '@/components/auth';
import { useCurrentUserContext } from '@/contexts';
import { useToast } from '@/hooks/useToast';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from '@/ui';
import { useUpdateProfile } from '../hooks/useUpdateProfile';
import { ChangeAvatar } from './ChangeAvatar';

type FormData = {
  name: string;
  avatar: string | File | null;
};

export const ProfileForm = () => {
  const { saveProfile, isError, isPending, error } = useUpdateProfile();
  const { currentUser } = useCurrentUserContext();
  const toast = useToast();
  const { t } = useTranslation('settingsModal', {
    keyPrefix: 'account.profile_section',
  });

  const { register, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      name: currentUser?.name ?? '',
      avatar: currentUser?.avatar ?? '',
    },
  });

  return (
    <form
      noValidate
      onSubmit={handleSubmit((values) => {
        saveProfile(values.name, values.avatar, {
          onSuccess: () => {
            toast.closeAll();
            toast.notify(t('success_toast'));
          },
        });
      })}
    >
      <VStack alignItems="flex-start" spacing="24px">
        {isError && (
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>{getAuthErrorMessage(error)}</AlertTitle>
          </Alert>
        )}

        <ChangeAvatar
          user={currentUser}
          onChange={(file: File | null) => setValue('avatar', file)}
          onRemoveClick={() => setValue('avatar', '')}
        />

        <FormControl>
          <FormLabel htmlFor="name">{t('name')}</FormLabel>
          <Input autoFocus id="name" type="text" {...register('name')} />
        </FormControl>

        {currentUser?.email && (
          <FormControl>
            <FormLabel>{t('email')}</FormLabel>
            <Input disabled type="email" value={currentUser.email as string} />
          </FormControl>
        )}

        <Button isLoading={isPending} type="submit" variant="secondary">
          {t('save')}
        </Button>
      </VStack>
    </form>
  );
};
