import isEqual from 'lodash.isequal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaCog } from 'react-icons/fa';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { Accordion, Flex, FormControl, FormLabel, Icon, Input } from '@/ui';
import { useUpdateRsvpSettings } from '../hooks/useUpdateRsvpSettings';
import { AccordionSection } from './AccordionSection';

type Props = {
  entry: DecoratedEntry;
};

type InputProps = {
  value?: string | null;
  placeholder: string;
  onSave: () => void;
  onChange: (value: string | null) => void;
};

const SettingInput = ({
  placeholder,
  value,
  onChange,
  onSave,
}: InputProps): JSX.Element => (
  <Input
    placeholder={placeholder}
    value={value || ''}
    onBlur={onSave}
    onChange={(event) => onChange(event.target.value || null)}
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        onSave();
      }
    }}
  />
);

export const RsvpSettings = ({ entry }: Props): JSX.Element => {
  const toast = useToast();
  const { t } = useTranslation(['entryModal', 'rsvpBar']);
  const { schedule } = useScheduleContext();
  const { updateRsvpSettings } = useUpdateRsvpSettings();
  const [settings, setSettings] = useState(schedule?.rsvpSettings || {});

  const handleSave = () => {
    if (!isEqual(entry.rsvpSettings, settings)) {
      updateRsvpSettings(settings, {
        onError: () => toast.error(t('entryModal:rsvps_panel.settings_error')),
      });
    }
  };

  return (
    <Accordion allowToggle>
      <AccordionSection
        icon={<Icon as={FaCog} />}
        label={t('entryModal:rsvps_panel.settings')}
      >
        <Flex direction="column" gap="6">
          <FormControl>
            <FormLabel>{t('entryModal:rsvps_panel.question')}</FormLabel>
            <SettingInput
              placeholder={t('rsvpBar:prompt')}
              value={settings.question}
              onSave={handleSave}
              onChange={(value) =>
                setSettings({ ...settings, question: value })
              }
            />
          </FormControl>

          <FormControl>
            <FormLabel>{t('entryModal:rsvps_panel.confirm_label')}</FormLabel>
            <SettingInput
              placeholder={t('rsvpBar:going')}
              value={settings.yesLabel}
              onSave={handleSave}
              onChange={(value) =>
                setSettings({ ...settings, yesLabel: value })
              }
            />
          </FormControl>

          <FormControl>
            <FormLabel>{t('entryModal:rsvps_panel.decline_label')}</FormLabel>
            <SettingInput
              placeholder={t('rsvpBar:not_going')}
              value={settings.noLabel}
              onChange={(value) => setSettings({ ...settings, noLabel: value })}
              onSave={handleSave}
            />
          </FormControl>
        </Flex>
      </AccordionSection>
    </Accordion>
  );
};
