import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { type ForwardedRef, forwardRef } from 'react';
import ReactDatePicker, { type DatePickerProps } from 'react-datepicker';
import { useUnsafeScheduleContext } from '@/pages/Schedule/contexts';
import { DatePickerHeader } from './DatePickerHeader';
import classes from './styles.module.scss';
import { dateInLocalZone, dateInScheduleZone } from './utils';

type Props = Omit<
  DatePickerProps,
  | 'calendarClassName' // use className instead
  | 'selected'
  | 'highlightDates'
  | 'minDate'
  | 'startDate'
  | 'endDate'
  | 'onChange'
  // TS doesn't like these props since (I think) they're defined as part of a
  // mutually-exclusive union, but this wrapper doesn't support either a range or
  // multiple selection right now, so it's best to omit them anyway to prevent
  // them from being set.
  | 'selectsRange'
  | 'selectsMultiple'
> & {
  className?: string;
  theme?: 'light' | 'dark';
  selected?: DateTime | null;
  highlightDates?: Record<string, DateTime[]>[];
  minDate?: DateTime | null;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
  onChange: (date: Date | null) => void;
};

const DatePickerWrapper = (
  {
    theme,
    className,
    selected,
    startDate,
    endDate,
    minDate,
    highlightDates,
    onChange,
    ...props
  }: Props,
  ref: ForwardedRef<ReactDatePicker>
) => {
  const scheduleContext = useUnsafeScheduleContext();
  const timeZone = scheduleContext?.timeZone ?? DateTime.local().zoneName;

  const wrapperClassNames = clsx(classes.wrapper, className, {
    [classes.dark]: theme === 'dark',
  });

  return (
    <div className={wrapperClassNames}>
      <ReactDatePicker
        endDate={endDate ? dateInLocalZone(endDate) : undefined}
        formatWeekDay={(date) => date.charAt(0)}
        minDate={minDate ? dateInLocalZone(minDate) : undefined}
        popperPlacement="bottom-start"
        ref={ref}
        selected={selected ? dateInLocalZone(selected) : null}
        showPopperArrow={false}
        startDate={startDate ? dateInLocalZone(startDate) : undefined}
        highlightDates={
          highlightDates
            ? highlightDates.map((group) => {
                return Object.keys(group).reduce<Record<string, Date[]>>(
                  (acc, className) => {
                    acc[className] = group[className].map((date) =>
                      dateInLocalZone(date)
                    );
                    return acc;
                  },
                  {}
                );
              })
            : undefined
        }
        renderCustomHeader={({ date, changeMonth, changeYear }) => (
          <DatePickerHeader
            date={DateTime.fromJSDate(date)}
            onChangeMonth={changeMonth}
            onChangeYear={changeYear}
          />
        )}
        onChange={(date) => {
          onChange(date ? dateInScheduleZone(date, timeZone) : null);
        }}
        {...props}
      />
    </div>
  );
};

export const DatePicker = forwardRef<ReactDatePicker, Props>(DatePickerWrapper);
