import { BsChatTextFill } from 'react-icons/bs';
import { FaRegHeart } from 'react-icons/fa';
import { Box, Button, Flex, type FlexProps, Icon, Link } from '@/ui';
import {
  SchedulesWithCustomFeedCTA,
  SchedulesWithCustomCTA,
  hasCustomCTA,
} from '../../constants/custom-ctas';
import { useScheduleContext } from '../../contexts';
import { AddToCalendarModal } from '../AddToCalendarModal';

const Container = (props: FlexProps) => (
  <Flex
    align="center"
    gap="1"
    justify={{ base: 'center', md: 'end' }}
    {...props}
  />
);

export const CustomCta = (props: FlexProps) => {
  const { scheduleId } = useScheduleContext();

  const hasCustomCta =
    hasCustomCTA(scheduleId) || SchedulesWithCustomFeedCTA.includes(scheduleId);

  if (!hasCustomCta) {
    return null;
  }

  return (
    <Container {...props}>
      {scheduleId === SchedulesWithCustomCTA.SF_AI ? (
        <>
          <Icon color="brand.500" icon="Plus" />
          <Link
            _hover={{ textDecor: 'underline' }}
            fontSize="sm"
            href="https://forms.gle/dMVHz5WpMQGmhrix7"
            target="_blank"
          >
            Submit Event
          </Link>
        </>
      ) : scheduleId === SchedulesWithCustomCTA.FAIR_PLAY ? (
        <>
          <Icon color="brand.500" icon="Plus" />
          <Link
            _hover={{ textDecor: 'underline' }}
            href="https://docs.google.com/forms/d/e/1FAIpQLSckDgaAYzjVFpBfZ3eKTFf6emqbhucC3Ca_XVl9b34Si3cEKQ/viewform?usp=sf_link"
            target="_blank"
          >
            Submit Event
          </Link>
        </>
      ) : scheduleId === SchedulesWithCustomCTA.ELF_ON_A_SHELF ? (
        <Button
          _hover={{ textDecoration: 'none' }}
          as={Link}
          colorScheme="green"
          fontWeight="medium"
          href="https://forms.gle/fUkk4mjVshtLDLwCA"
          leftIcon={<Icon as={BsChatTextFill} />}
          lineHeight="1"
        >
          Get Daily Text Reminders
        </Button>
      ) : scheduleId === SchedulesWithCustomCTA.MRS_B_TARAS_NANNY_SITTING ? (
        <Button
          _hover={{ textDecoration: 'none', bg: '#641684' }}
          as={Link}
          bg="#4A1062"
          color="#EFDFE8"
          fontWeight="medium"
          href="https://forms.gle/xM9bZXCj75QFz3kT9"
          leftIcon={<Icon as={FaRegHeart} />}
          lineHeight="1"
        >
          Request Care
        </Button>
      ) : scheduleId ===
        SchedulesWithCustomCTA.MRS_B_TARAS_NANNY_GIFT_WRAPPING ? (
        <Button
          _hover={{ textDecoration: 'none' }}
          as={Link}
          colorScheme="green"
          fontWeight="medium"
          href="https://forms.gle/GTV2ZpLeVCrCuRTH6"
          leftIcon={<Box>{'🎁'}</Box>}
          lineHeight="1"
        >
          Request Gift Wrapping
        </Button>
      ) : SchedulesWithCustomFeedCTA.includes(scheduleId) ? (
        <AddToCalendarModal
          fetchSecretBeforeOpen
          renderButton={(props) => (
            <Button
              {...props}
              _hover={{ textDecoration: 'none' }}
              fontWeight="medium"
              lineHeight="1"
              variant="outline"
            >
              Subscribe to calendar
            </Button>
          )}
        />
      ) : null}
    </Container>
  );
};
