import { useCallback } from 'react';
import {
  type NavigateOptions,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom';

type UseQueryParams = {
  queryParams: URLSearchParams;
  setQueryParams: (params: Record<string, string | null>) => void;
  removeQueryParam: (
    param: string | string[],
    options?: NavigateOptions
  ) => void;
};

export const useQueryParams = (): UseQueryParams => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = useCallback(
    (params: Record<string, string | null>) => {
      const newParams = createSearchParams({
        ...Object.fromEntries(searchParams),
        ...(params as Record<string, string>),
      });
      for (const [key, value] of newParams) {
        if (value === 'null') {
          newParams.delete(key);
        }
      }
      setSearchParams(newParams);
    },
    [searchParams, setSearchParams]
  );

  const removeQueryParam = useCallback(
    (param: string | string[], options?: NavigateOptions) => {
      if (!Array.isArray(param)) {
        param = [param];
      }
      const newParams = createSearchParams(Object.fromEntries(searchParams));
      param.forEach((key) => newParams.delete(key));
      setSearchParams(newParams, options);
    },
    [searchParams, setSearchParams]
  );

  return {
    queryParams: searchParams,
    setQueryParams,
    removeQueryParam,
  };
};
