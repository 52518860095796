import type { LaunchParams } from './types';

export const LAUNCH_PARAM_PREFIX = 'launch_';

export const getLaunchParams = (data: Record<string, string>): LaunchParams => {
  return Object.entries(data).reduce((params: LaunchParams, [key, value]) => {
    if (!key.startsWith(LAUNCH_PARAM_PREFIX)) {
      return params;
    }
    const keyWithoutPrefix = key.substring(LAUNCH_PARAM_PREFIX.length);
    return {
      ...params,
      [keyWithoutPrefix]: value,
    };
  }, {});
};

export const toLaunchParams = (data: Record<string, unknown>): LaunchParams => {
  return Object.entries(data).reduce<Record<string, string>>(
    (idx, [key, value]) => {
      idx[`${LAUNCH_PARAM_PREFIX}${key}`] = String(value);
      return idx;
    },
    {}
  );
};
