import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Button,
  type ModalProps,
  Flex,
  FormControl,
  Input,
  FormHelperText,
  FormErrorMessage,
  Switch,
} from '@/ui';
import { isGQLErrorOfType } from '@/utils/errors';
import { ScheduleDescription } from './components/Description';
import { Label } from './components/Label';
import { TimeZoneSelect } from './components/TimeZoneSelect';
import { useUpdateScheduleSettings } from './hooks/useUpdateScheduleSettings';

type Props = Omit<ModalProps, 'children'>;

export const ScheduleSettingsModal = ({ onClose, ...props }: Props) => {
  const { t } = useTranslation('scheduleSettingsModal');
  const { timeZone, schedule } = useScheduleContext();
  const { updateScheduleSettings, isPending } = useUpdateScheduleSettings();
  const toast = useToast();
  const { value: updateScheduleMetadataEnabled } = useFeatureFlag(
    'updateScheduleMetadataEnabled'
  );

  const {
    register,
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    defaultValues: {
      timeZone,
      description: schedule?.description ?? '',
      slug: schedule?.slug ?? null,
      socialSharingEnabled: schedule?.socialSharingEnabled ?? false,
    },
  });

  const socialSharingEnabled = watch('socialSharingEnabled');

  return (
    <Modal isCentered variant="primary" onClose={onClose} {...props}>
      <ModalOverlay />

      <ModalContent
        as="form"
        noValidate
        onSubmit={handleSubmit((values) => {
          if (Object.keys(dirtyFields).length === 0) {
            onClose();
            return;
          }

          updateScheduleSettings(values, {
            onError: (err) => {
              if (isGQLErrorOfType(err, 'ScheduleSlugExists')) {
                setError(
                  'slug',
                  { message: t('error_slug') },
                  { shouldFocus: true }
                );
              } else {
                toast.error(t('error_generic'));
              }
            },
            onSuccess: () => {
              if (dirtyFields.timeZone) {
                window.location.reload();
              } else {
                toast.notify(t('update_successful'));
                onClose();
              }
            },
          });
        })}
      >
        <ModalCloseButton />
        <ModalHeader alignItems="flex-start" flexDir="column" gap="2">
          {t('heading')}
          <Text fontSize="md" fontWeight="normal">
            {schedule?.title}
          </Text>
        </ModalHeader>

        <ModalBody>
          <FormControl>
            <Label mb="0">{t('timezone_label')}</Label>
            <TimeZoneSelect mt="2" {...register('timeZone')} />
            <FormHelperText>{t('timezone_hint')}</FormHelperText>
          </FormControl>

          {updateScheduleMetadataEnabled && (
            <Flex direction="column" gap="6" mt="6">
              <Controller
                control={control}
                name="description"
                render={({ field }) => <ScheduleDescription {...field} />}
              />

              <FormControl isInvalid={!!errors.slug}>
                <Label>{t('slug_label')}</Label>
                <Input
                  type="text"
                  {...register('slug', {
                    setValueAs: (value) => {
                      return value && value.trim().length > 0 ? value : null;
                    },
                    validate: (value) => {
                      if (value && !/^[a-z\d-]+$/.test(value)) {
                        return t('slug_invalid');
                      }
                    },
                  })}
                />
                <FormErrorMessage>{errors.slug?.message}</FormErrorMessage>
                <FormHelperText>{t('slug_hint')}</FormHelperText>
              </FormControl>

              <FormControl>
                <Label>{t('social_sharing_label')}</Label>
                <Switch {...register('socialSharingEnabled')} />
                <Text as="span" ml="2">
                  {socialSharingEnabled
                    ? t('social_sharing_enabled')
                    : t('social_sharing_disabled')}
                </Text>
                <FormHelperText>{t('social_sharing_hint')}</FormHelperText>
              </FormControl>
            </Flex>
          )}
        </ModalBody>

        <ModalFooter alignItems="flex-end">
          <Button isLoading={isPending} type="submit">
            {t('submit')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
