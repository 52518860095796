import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { SuggestTextMessageInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  SuggestTextMessageMutation,
  SuggestTextMessageMutationVariables,
} from './useSuggestTextMessage.generated';

const query = gql`
  mutation SuggestTextMessage($input: SuggestTextMessageInput!) {
    suggestTextMessage(input: $input)
  }
`;

export const useSuggestTextMessage = () => {
  const { mutate, isPending, isSuccess } = useMutation<
    SuggestTextMessageMutation,
    QueryError,
    SuggestTextMessageMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        SuggestTextMessageMutation,
        SuggestTextMessageMutationVariables
      >(query, variables),
  });

  const suggestTextMessage = (
    input: SuggestTextMessageInput,
    options?: MutateOptions<
      SuggestTextMessageMutation,
      QueryError,
      SuggestTextMessageMutationVariables
    >
  ) => mutate({ input }, options);

  return {
    suggestTextMessage,
    isPending,
    isSuccess,
  };
};
