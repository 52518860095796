import {
  PencilIcon,
  type LucideIcon,
  TagIcon,
  UserRound,
  PaletteIcon,
  MessageSquareTextIcon,
  Trash2Icon,
  CalendarDays,
  MapPin,
  CopyIcon,
  CalendarPlus,
  MoreHorizontal,
  CalendarCheck,
  XIcon,
  Plus,
  ArrowLeft,
  Check,
  Search,
  WandSparkles,
  Clock,
  Link,
  ArrowRight,
  Scissors,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  Eye,
  EyeOff,
  Menu,
  HelpCircle,
  LogOut,
  FileSearch,
  Settings,
} from 'lucide-react';
import type { IconType as ReactIconsType } from 'react-icons';

export type IconType = LucideIcon | ReactIconsType;

export const Icons = {
  ArrowLeft,
  ArrowRight,
  Calendar: CalendarDays,
  CalendarCheck: CalendarCheck,
  CalendarPlus: CalendarPlus,
  Checkmark: Check,
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  Clock,
  Copy: CopyIcon,
  Eye,
  EyeOff,
  FileSearch,
  HelpCircle,
  Link,
  Logout: LogOut,
  MapPin,
  Menu,
  MoreHorizontal,
  Pencil: PencilIcon,
  Palette: PaletteIcon,
  Plus,
  Scissors,
  Search,
  Settings,
  Tag: TagIcon,
  TextMessage: MessageSquareTextIcon,
  Trash: Trash2Icon,
  User: UserRound,
  WandSparkles,
  X: XIcon,
} as const satisfies Record<string, IconType>;
