import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  UpdateScheduleMutation,
  UpdateScheduleMutationVariables,
} from './useUpdateSchedule.generated';

const query = gql`
  mutation UpdateSchedule(
    $scheduleId: ID!
    $description: String
    $title: String
    $headerId: ScheduleHeaderId
  ) {
    updateSchedule(
      scheduleId: $scheduleId
      description: $description
      title: $title
      headerId: $headerId
    ) {
      id
      description
      title
      header {
        id
      }
    }
  }
`;

export const useUpdateSchedule = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate } = useMutation<
    UpdateScheduleMutation,
    QueryError,
    UpdateScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateScheduleMutation,
        UpdateScheduleMutationVariables
      >(query, variables),
  });

  const updateSchedule = useCallback(
    (
      input: Omit<UpdateScheduleMutationVariables, 'scheduleId'>,
      options?: MutateOptions<
        UpdateScheduleMutation,
        QueryError,
        UpdateScheduleMutationVariables
      >
    ) => {
      return mutate({ scheduleId, ...input }, options);
    },
    [mutate, scheduleId]
  );

  return {
    updateSchedule,
  };
};
