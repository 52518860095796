import { Trans, useTranslation } from 'react-i18next';
import type { EntriesJobType } from '@/types/gql.generated';
import { Button, MotionFlex, forwardRef, Flex, Text } from '@/ui';
import { useLaunchStateConfig } from '../../hooks/useLaunchStateConfig';
import type { Prompt } from '../../types';
import { PromptForm } from './PromptForm';

type Props = {
  instructions: string;
  jobType: EntriesJobType;
  prompt: Prompt;
  promptRef: (node: HTMLDivElement | null) => void;
  onCancel: () => void;
  onChangeJobType: (jobType: EntriesJobType) => void;
  onInstructionsChange: (instructions: string) => void;
  onManualClick: () => void;
  onCloseClick: () => void;
  onPromptChange: (prompt: Partial<Prompt>) => void;
  onSubmit: () => void;
  onDropText: (text: string) => void;
};

export const PromptState = forwardRef(
  (
    {
      instructions,
      jobType,
      prompt,
      promptRef,
      onCancel,
      onChangeJobType,
      onInstructionsChange,
      onManualClick,
      onCloseClick,
      onPromptChange,
      onSubmit,
      onDropText,
    }: Props,
    ref
  ) => {
    const { t } = useTranslation('addContent');
    const { launchStateConfig } = useLaunchStateConfig();

    return (
      <MotionFlex
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 1 }}
        ref={ref}
        transition={{ duration: 0.1 }}
        w="100%"
      >
        <Flex direction="column" gap="5" ref={promptRef} w="100%">
          {launchStateConfig.showTipInBody && (
            <Text>
              <Trans i18nKey="tip" t={t} />
            </Text>
          )}

          <PromptForm
            instructions={instructions}
            jobType={jobType}
            prompt={prompt}
            onCancel={onCancel}
            onChangeJobType={onChangeJobType}
            onDropText={onDropText}
            onInstructionsChange={onInstructionsChange}
            onPromptChange={onPromptChange}
            onSubmit={onSubmit}
          />

          <Button
            colorScheme="dark"
            fontSize="md"
            fontWeight="medium"
            m="auto"
            variant="link"
            onClick={
              launchStateConfig.showCloseInsteadOfManualEntry
                ? onCloseClick
                : onManualClick
            }
          >
            {launchStateConfig.showCloseInsteadOfManualEntry
              ? t('do_later')
              : t('manual_entry')}
          </Button>
        </Flex>
      </MotionFlex>
    );
  }
);
