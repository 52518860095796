import {
  type ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, forwardRef, Input, type InputProps, useToken } from '@/ui';

export const AutoResizingTextInput = forwardRef(
  ({ onChange, minW, ...props }: InputProps, forwardedRef) => {
    const [width, setWidth] = useState(0);
    const measureRef = useRef<HTMLDivElement | null>(null);
    const content = props.value || props.placeholder;

    const [paddingLeft, paddingRight] = useToken('space', [
      String(props.px ?? props.pl ?? 0),
      String(props.px ?? props.pr ?? 0),
    ]);

    const setMeasureRef = useCallback((ref: HTMLDivElement) => {
      setWidth(ref?.offsetWidth ?? 0);
      measureRef.current = ref;
    }, []);

    useEffect(() => {
      setWidth(measureRef.current?.offsetWidth ?? 0);
    }, [content]);

    const changeHandler: ChangeEventHandler<HTMLInputElement> = (event) => {
      onChange?.(event);
    };

    return (
      <>
        <Box
          fontSize={props.fontSize}
          fontWeight={props.fontWeight}
          left="-9999px"
          pos="fixed"
          ref={setMeasureRef}
          whiteSpace="pre"
        >
          {content}
        </Box>
        <Input
          minW={content ? 'auto' : minW}
          ref={forwardedRef}
          type="text"
          w={`calc(${width}px + ${paddingLeft ? paddingLeft : '0px'} + ${
            paddingRight ? paddingRight : '0px'
          } + 8px)`}
          onChange={changeHandler}
          {...props}
        />
      </>
    );
  }
);
