import { Flex, type FlexProps } from '@/ui';

export const PanelContent = (props: FlexProps) => {
  return (
    <Flex
      bg="white"
      borderBottomRightRadius={{ base: 'none', lg: 'md' }}
      borderTopLeftRadius={{ base: 'md', lg: 'none' }}
      borderTopRightRadius="md"
      direction="column"
      flex="1"
      overflow="auto"
      {...props}
    />
  );
};
