import { useCallback } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { useUnsafeScheduleContext } from '@/pages/Schedule/contexts';
import {
  type EditorPermissions,
  EntryType,
  type OwnerPermissions,
  type PublicPermissions,
  type ViewerPermissions,
} from '@/types/gql.generated';
import type { DecoratedEntry } from '../types';

type RolePermission =
  | `${OwnerPermissions}`
  | `${EditorPermissions}`
  | `${ViewerPermissions}`
  | `${PublicPermissions}`;

export const useSchedulePermissions = () => {
  const { currentUser } = useCurrentUserContext();
  const context = useUnsafeScheduleContext();

  const hasSchedulePermission = useCallback(
    (permission: RolePermission) => {
      if (!currentUser) {
        return false;
      } else if (context?.isOwnerRole) {
        return currentUser.rolePermissions.owner.includes(
          permission as OwnerPermissions
        );
      } else if (context?.isEditorRole) {
        return currentUser.rolePermissions.editor.includes(
          permission as EditorPermissions
        );
      } else if (context?.isViewerRole) {
        return currentUser.rolePermissions.viewer.includes(
          permission as ViewerPermissions
        );
      } else if (context?.isPublicRole) {
        return currentUser.rolePermissions.public.includes(
          permission as PublicPermissions
        );
      }
      return false;
    },
    [currentUser, context]
  );

  const canDeleteEntry = (entry: DecoratedEntry): boolean => {
    return (
      hasSchedulePermission('ENTRY_DELETE') &&
      entry.type === EntryType.Schedule &&
      entry.feed?.primarySchedule?.id === context?.scheduleId
    );
  };

  return {
    hasSchedulePermission,
    canDeleteEntry,
  };
};
