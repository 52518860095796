import {
  DndContext,
  type DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { LabelType, Label } from '@/types/gql.generated';
import { VStack } from '@/ui';
import { useUpdateLabelSortOrder } from '../hooks/useUpdateLabelSortOrder';
import { LabelSelectItem } from './LabelSelectItem';

type Props = {
  labelType: LabelType;
  labels: Label[];
  selected?: Label[];
  onSelect: (label: Label) => void;
  onDeselect: (label: Label) => void;
  onUpdate: (label: Label) => void;
  onUpdateSortOrder: (labels: Label[]) => void;
  onDelete: (label: Label) => void;
};

export const LabelList = ({
  labelType,
  labels,
  selected,
  onSelect,
  onDeselect,
  onUpdate,
  onUpdateSortOrder,
  onDelete,
}: Props) => {
  const { scheduleId } = useScheduleContext();
  const { mutate: updateLabelSortOrder } = useUpdateLabelSortOrder(
    labelType,
    labels
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // move 5px before activating DnD - allows tags to be clicked
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = labels.findIndex(({ id }) => id === active.id);
      const newIndex = labels.findIndex(({ id }) => id === over?.id);
      const updatedLabels = arrayMove(labels, oldIndex, newIndex);
      onUpdateSortOrder(updatedLabels);

      updateLabelSortOrder({
        scheduleId,
        labels: updatedLabels.map(({ id }, index) => ({
          id,
          sortOrder: index,
        })),
      });
    }
  };

  const selectedIds = selected?.map((label) => label.id) ?? [];

  return (
    <VStack align="stretch" spacing="0">
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={labels} strategy={verticalListSortingStrategy}>
          {labels.map((label) => {
            const isSelected = selectedIds.includes(label.id);

            return (
              <LabelSelectItem
                key={label.id}
                label={label}
                labelType={labelType}
                selected={isSelected}
                onDelete={onDelete}
                onUpdate={onUpdate}
                onToggle={() => {
                  if (isSelected) {
                    onDeselect(label);
                  } else {
                    onSelect(label);
                  }
                }}
                onValidate={(updatedText) => {
                  const duplicateLabel = labels.find(
                    ({ id, text }) =>
                      id !== label.id &&
                      text.toLowerCase() === updatedText.toLowerCase()
                  );
                  return !duplicateLabel;
                }}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </VStack>
  );
};
