import type { DateTime } from 'luxon';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { getDateSummary } from '@/pages/Schedule/utils';
import {
  areDatesSame,
  areTimesInSameMeridian,
  isMultiDay,
} from '@/utils/dates';

type EntryLike = Pick<DecoratedEntry, 'recurrences'>;

type InstanceLike = Pick<
  DecoratedInstance,
  'startDate' | 'endDate' | 'isOnDay' | 'rule'
>;

export const toTimeFormat = (
  date: DateTime,
  includeMeridian: boolean = true
): string => {
  return includeMeridian
    ? date.toFormat('h:mm a').toLowerCase()
    : date.toFormat('h:mm');
};

export const toDateFormat = (date: DateTime): string => {
  return date.toFormat('ccc, MMM d, yyyy');
};

export const summarizeTime = (
  entry: EntryLike,
  instance?: InstanceLike
): string => {
  const {
    startDate: entryStartDate,
    endDate: entryEndDate,
    isOnDay: entryIsOnDay,
    rule: entryRule,
  } = entry.recurrences[0];

  // defer to our common summarization util for multi-day and recurring items
  if (isMultiDay(entryStartDate, entryEndDate) || (!instance && entryRule)) {
    return getDateSummary(
      entryStartDate,
      entryEndDate,
      entryIsOnDay,
      entryRule
    );
  }

  const recurrence = instance ? instance : entry.recurrences[0];
  const { startDate, endDate, isOnDay } = recurrence;

  if (isOnDay) {
    return toDateFormat(startDate);
  }

  const formattedDate = toDateFormat(startDate);

  // 9am on {date}
  if (areDatesSame(startDate, endDate)) {
    return `${toTimeFormat(startDate)} on ${formattedDate}`;
  }

  // 8:00 to 9:00 am on {date}
  if (areTimesInSameMeridian(startDate, endDate)) {
    return `${toTimeFormat(startDate, false)} to ${toTimeFormat(
      endDate
    )} on ${formattedDate}`;
  }

  // 9:00 am - 1:00 pm on {date}
  return `${toTimeFormat(startDate)} to ${toTimeFormat(
    endDate
  )} on ${formattedDate}`;
};
