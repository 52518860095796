import type { MutateOptions } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import type { AuthResult } from '@/components/auth/types';
import { AuthAction } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { CAMPAIGN_SESSION_STORAGE_KEY } from '../../../../constants';
import { useLogin } from './useLogin';
import { useRegister } from './useRegister';

type AuthValues = {
  email: string;
  name: string;
  password: string;
};

export const useAuthenticate = () => {
  const {
    login,
    isPending: isLoggingIn,
    data: loginData,
    error: loginError,
    reset: resetLogin,
  } = useLogin();

  const {
    register: registerUser,
    isPending: isRegistering,
    data: registerData,
    error: registerError,
    reset: resetRegister,
  } = useRegister();

  const authenticate = (
    action: Extract<AuthAction, AuthAction.LoginPassword | AuthAction.Register>,
    values: AuthValues,
    options?: MutateOptions<unknown, QueryError, unknown>
  ) => {
    if (action === AuthAction.LoginPassword) {
      login(values.email, values.password, options);
    } else {
      const timeZone = DateTime.local().zoneName;
      const campaign = sessionStorage.getItem(CAMPAIGN_SESSION_STORAGE_KEY);
      registerUser({ ...values, timeZone, campaign }, options);
    }
  };

  const isLoading = isLoggingIn || isRegistering;
  const data = loginData || registerData;
  const error = loginError || registerError;
  const reset = resetLogin || resetRegister;

  const result: AuthResult | null = loginData
    ? 'login'
    : registerData
      ? 'register'
      : null;

  return {
    authenticate,
    isLoading,
    data,
    error,
    result,
    reset,
  };
};
