import { Flex, type FlexProps } from '@/ui';

export const Footer = (props: FlexProps) => {
  return (
    <Flex
      borderBottomRadius="xl"
      borderColor="customgray.light"
      borderTopWidth="1px"
      gap="2"
      mt="5"
      px="24px"
      {...props}
    />
  );
};
