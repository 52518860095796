import { PopoverPanel } from '@/pages/Schedule/components/Popover';
import { Box } from '@/ui';
import { useCategorySelectContext } from '../hooks/useCategorySelectContext';
import type { Category } from '../types';
import { CategoryAdd } from './CategoryAdd';
import { CategoryEdit } from './CategoryEdit';
import { CategoryPanel } from './CategoryPanel';
import { PrimaryActionButton, SecondaryActionButton, Title } from './Header';

const MAX_HEIGHT = 443;

type Props = {
  selected?: Category | null;
  onSelect: (category: Category | null) => void;
  onClose?: () => void;
};

export const CategorySelect = ({
  selected,
  onSelect,
  onClose,
}: Props): JSX.Element => {
  const { page, goBack } = useCategorySelectContext();

  const handleClose = () => {
    onClose?.();
    goBack();
  };

  return (
    <>
      {page === 'index' ? (
        <PopoverPanel
          panelProps={{ maxH: MAX_HEIGHT }}
          title={<Title />}
          action={
            <PrimaryActionButton selected={selected} onSelect={onSelect} />
          }
        >
          <CategoryPanel
            px="3"
            py="2"
            selected={selected}
            onSelect={(category) => {
              if (category?.id === selected?.id) {
                onSelect(null);
              } else {
                onSelect(category);
              }
              handleClose();
            }}
          />
        </PopoverPanel>
      ) : (
        <PopoverPanel
          panelProps={{ maxH: MAX_HEIGHT }}
          secondaryAction={<SecondaryActionButton />}
          title={<Title />}
          action={
            <PrimaryActionButton selected={selected} onSelect={onSelect} />
          }
        >
          <Box pb="5" pt="3" px="3">
            {page === 'edit' ? (
              <CategoryEdit
                onUpdate={(category) => {
                  if (category?.id === selected?.id) {
                    onSelect(category);
                  }
                }}
              />
            ) : (
              <CategoryAdd onAdd={(category) => onSelect(category)} />
            )}
          </Box>
        </PopoverPanel>
      )}
    </>
  );
};
