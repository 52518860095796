import { useMemo } from 'react';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useQueryParams } from '@/hooks/useQueryParams';
import { useLastSelectedView, useViewModules } from '@/pages/Schedule/hooks';
import { getViewType } from '@/pages/Schedule/utils/view';
import { getActiveViewModule } from '@/pages/Schedule/views/utils';
import type { ScheduleContextValue } from '../types';

export const useActiveViewModule = (
  scheduleId: string,
  schedule: ScheduleContextValue['schedule'] | undefined
) => {
  const { queryParams } = useQueryParams();
  const { viewModules } = useViewModules();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const presentation = getViewType(schedule, queryParams);
  const { lastSelectedView } = useLastSelectedView(scheduleId);

  const viewModule = useMemo(
    () =>
      getActiveViewModule({
        isMobileBreakpoint,
        queryParams,
        scheduleType: presentation,
        viewModules,
        lastSelectedView,
      }),
    [
      isMobileBreakpoint,
      queryParams,
      presentation,
      lastSelectedView,
      viewModules,
    ]
  );

  return {
    viewModule,
  };
};
