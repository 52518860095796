import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { createVerifyTokenQueryKey } from '@/utils/queryKeys';
import type {
  VerifyResetTokenQuery,
  VerifyResetTokenQueryVariables,
} from './useVerifyToken.generated';

const query = gql`
  query VerifyResetToken($token: String!) {
    verifyPasswordReset(token: $token)
  }
`;

export const useVerifyToken = (token?: string) => {
  return useQuery({
    queryKey: createVerifyTokenQueryKey(token),
    queryFn: () =>
      gqlClient.request<VerifyResetTokenQuery, VerifyResetTokenQueryVariables>(
        query,
        { token: token! }
      ),
    enabled: !!token,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
