import { type LaunchParams, toLaunchParams } from '@/hooks/useLauncher';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import type { Calendar } from '@/types/gql.generated';
import type { Destination } from '../components/GoogleCalendar/types';
import type { EntryExport, PlatformExport } from '../hooks/usePlatformExports';
import type {
  DecoratedQuickCreateEntry,
  DecoratedQuickCreateEntryJob,
} from '../types';

/**
 * Use this for the Calendar button.
 * launch is omitted because it's added to the query by the button itself
 */
export const getRedirectQuery = (
  job: DecoratedQuickCreateEntryJob | null,
  params: LaunchParams = {}
) => {
  const query = toLaunchParams(params);

  if (job?.id) {
    query.e = job.id;
  }

  return query;
};

export const getCalendarLink = (
  integration: DecoratedIntegration,
  calendar: Calendar
): string => {
  // https://webapps.stackexchange.com/questions/116392/how-to-get-a-link-to-add-a-google-calendar
  const id = calendar.id.endsWith('@resource.calendar.google.com')
    ? calendar.id
    : btoa(calendar.id).replaceAll('=', '');
  return `https://calendar.google.com/calendar/u/${integration.externalId}/r?cid=${id}`;
};

export const getEventLink = (
  integration: DecoratedIntegration,
  itemExport: Pick<EntryExport, 'externalLink'>
): string => {
  const link = new URL(itemExport.externalLink);
  link.searchParams.set('authuser', integration.externalId);
  return link.toString();
};

export const hasMultiExported = (
  destination: Destination,
  platformExports: PlatformExport[],
  entries: DecoratedQuickCreateEntry[]
): boolean => {
  const platformExport =
    destination &&
    platformExports.find((item) => item.calendarId === destination.calendar.id);

  if (!platformExport) {
    return false;
  }

  return entries.every((entry) =>
    platformExport.entryExports.some((item) => item.entry.id === entry.id)
  );
};
