import { DateTime } from 'luxon';
import { getStartOfWeek } from '@/utils/dates';
import type {
  EndDateAdjuster,
  PanStartDateAdjuster,
  StartDateAdjuster,
  TodayEndDateAdjuster,
  TodayStartDateAdjuster,
} from '../types';

export const adjustStartDate: StartDateAdjuster = (requestedStartDate) => {
  return getStartOfWeek(requestedStartDate);
};

export const adjustEndDate: EndDateAdjuster = (startDate) => {
  return startDate.plus({ weeks: 1 });
};

export const adjustTodayStartDate: TodayStartDateAdjuster = (
  scheduleTimeZone: string
) => {
  return adjustStartDate(DateTime.local({ zone: scheduleTimeZone }));
};

export const adjustTodayEndDate: TodayEndDateAdjuster = () => null;

export const adjustPreviousStartDate: PanStartDateAdjuster = (startDate) => {
  return startDate.minus({ weeks: 1 });
};

export const adjustNextStartDate: PanStartDateAdjuster = (startDate) => {
  return startDate.plus({ weeks: 1 });
};
