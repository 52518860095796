import { gql } from 'graphql-request';
import type { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { gqlWsClient, handleError } from '@/lib/gql-client';
import { queryClient } from '@/lib/query-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createScheduleQueryKey } from '@/utils/queryKeys';
import type { ScheduleSubscription } from './useScheduleUpdatedSubscription.generated';

const query = gql`
  subscription Schedule($scheduleId: ID!) {
    scheduleUpdated(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const useScheduleUpdatedSubscription = () => {
  const { scheduleId, isPublicRole } = useScheduleContext();

  const handleData = useCallback(
    ({ data }: ExecutionResult<ScheduleSubscription>) => {
      if (data?.scheduleUpdated) {
        queryClient.invalidateQueries({
          queryKey: createScheduleQueryKey(scheduleId),
        });
      }
    },
    [scheduleId]
  );

  useEffect(() => {
    if (isPublicRole) {
      // Disabled for read only schedules
      return;
    }

    const unsubscribe = gqlWsClient.subscribe<ScheduleSubscription>(
      {
        query: query,
        variables: {
          scheduleId,
        },
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, isPublicRole, handleData]);
};
