import { Children, cloneElement, type MouseEventHandler } from 'react';

type Props = {
  children: JSX.Element;
  onClick: () => void;
};

export const Trigger = ({ children, onClick, ...rest }: Props): JSX.Element => {
  const child = Children.only(children);

  const handleClick: MouseEventHandler = (event) => {
    onClick();
    if (typeof child.props.onClick === 'function') {
      child.props.onClick(event);
    }
  };

  return (
    <>
      {cloneElement(child, {
        onClick: handleClick,
        'aria-haspopup': 'dialog',
        ...rest,
      })}
    </>
  );
};
