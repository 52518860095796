import {
  type ButtonProps,
  Button as ChakraButton,
  forwardRef,
} from '@chakra-ui/react';

/**
 * This wrapper works around compatibility issues with Google Translate
 * and Chakra's button component.
 * See https://github.com/chakra-ui/chakra-ui/issues/6004
 */
export const Button = forwardRef<ButtonProps, typeof ChakraButton>(
  function ButtonWithRef({ children, loadingText, ...props }, ref) {
    const wrappedLoadingText =
      typeof loadingText === 'string' ? (
        <span>{loadingText}</span>
      ) : (
        loadingText
      );

    const wrappedChildren =
      typeof props.isLoading === 'boolean' && typeof children === 'string' ? (
        <span>{children}</span>
      ) : (
        children
      );

    return (
      <ChakraButton loadingText={wrappedLoadingText} ref={ref} {...props}>
        {wrappedChildren}
      </ChakraButton>
    );
  }
);
