import { useCallback, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { useQueryParams } from '@/hooks/useQueryParams';
import type { ScheduleLocationState } from '@/pages/Schedule/types';
import type { LaunchOption, LaunchParams } from './types';
import { useLauncherStore } from './useLauncherStore';
import { LAUNCH_PARAM_PREFIX, getLaunchParams } from './util';

/**
 * Determines which workflow to launch when the app loads based on state
 * configured via the login page or query param values.
 */
export const useLauncher = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { queryParams, removeQueryParam } = useQueryParams();
  const { launch, setLaunch, launchParams, reset } = useLauncherStore();
  const state = location.state as undefined | ScheduleLocationState;

  useMount(() => {
    if (launch) {
      // The store already has a value. Another consumer of `useLaunchAction`
      // caused the store to hydrate so we don't need to do anything.
      return;
    }

    const launchParam = queryParams.get('launch') as LaunchOption;

    if (launchParam) {
      const params = getLaunchParams(Object.fromEntries(queryParams.entries()));
      setLaunch(launchParam, params);
      removeQueryParam(
        [
          'launch',
          ...Object.keys(params).map((key) => `${LAUNCH_PARAM_PREFIX}${key}`),
        ],
        { replace: true }
      );
    } else if (state?.launch) {
      setLaunch(state.launch, getLaunchParams((state as LaunchParams) || {}));
    }
  });

  // after the `launch` value is consumed, it should be cleared so another
  // render cycle doesn't re-trigger the launch action
  const clearLaunch = useCallback(() => {
    reset();

    // location state persists after reloads so clear that as well
    if (state?.launch) {
      const nextState = { ...state };
      delete nextState.launch;
      navigate(location, { replace: true, state: nextState });
    }
  }, [reset, navigate, location, state]);

  return { launch, clearLaunch, launchParams, setLaunch };
};

/**
 * Utility hook for performing a side effect on a particular `LaunchOption`.
 *
 * Ex:
 * useLauncher('onboarding', () => {
 *   openOnboardingModal();
 * });
 */
export const useLauncherAction = (
  action: LaunchOption,
  callback: (launchParams: LaunchParams) => void
) => {
  const { launch, clearLaunch, launchParams } = useLauncher();
  const callbackRef = useRef(callback);
  const fired = useRef(false);

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    if (!fired.current && action === launch) {
      callbackRef.current(launchParams);
      fired.current = true;
      clearLaunch();
    }
  }, [action, clearLaunch, launch, launchParams]);
};
