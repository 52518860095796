import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import { SnippetFragment } from '../../fragments/SnippetFragment';
import type { SnippetData } from '../useSnippet';
import type {
  UpdateSnippetMutation,
  UpdateSnippetMutationVariables,
} from './useUpdateSnippet.generated';

const query = gql`
  ${SnippetFragment}
  mutation UpdateSnippet(
    $scheduleId: ID!
    $snippetId: ID!
    $name: String!
    $categories: [ID!]!
    $whoLabels: [LabelIdInput!]!
    $labels: [LabelIdInput!]!
    $feeds: [ID!]!
  ) {
    updateSnippet(
      scheduleId: $scheduleId
      snippetId: $snippetId
      name: $name
      categories: $categories
      whoLabels: $whoLabels
      labels: $labels
      feeds: $feeds
    ) {
      ...Snippet
    }
  }
`;

export const useUpdateSnippet = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending, isSuccess } = useMutation<
    UpdateSnippetMutation,
    QueryError,
    UpdateSnippetMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<UpdateSnippetMutation, UpdateSnippetMutationVariables>(
        query,
        variables
      ),
  });

  const updateSnippet = (
    snippet: SnippetData,
    options?: MutateOptions<
      UpdateSnippetMutation,
      QueryError,
      UpdateSnippetMutationVariables
    >
  ) => {
    const categories = snippet.categories.map((category) => category.id);
    const whoLabels = snippet.whoLabels.map((label) => ({
      id: label.id,
    }));
    const labels = snippet.labels.map((label) => ({ id: label.id }));
    const feeds = snippet.feeds.map((feed) => feed.id);
    const variables: UpdateSnippetMutationVariables = {
      snippetId: snippet.id,
      scheduleId,
      name: snippet.name,
      categories,
      whoLabels,
      labels,
      feeds,
    };
    return mutate(variables, options);
  };

  return {
    updateSnippet,
    isPending,
    isSuccess,
  };
};
