import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  CreateQuickEntriesJobFromPromptMutation,
  CreateQuickEntriesJobFromPromptMutationVariables,
} from './useCreateQuickEntriesJobFromPrompt.generated';

const query = gql`
  mutation CreateQuickEntriesJobFromPrompt(
    $prompt: String!
    $timeZone: TimeZone!
  ) {
    createQuickEntriesJobFromPrompt(prompt: $prompt, timeZone: $timeZone) {
      id
    }
  }
`;

export const useCreateQuickEntriesJobFromPrompt = () => {
  return useMutation<
    string,
    QueryError,
    CreateQuickEntriesJobFromPromptMutationVariables
  >({
    mutationFn: async (variables) => {
      const result = await gqlClient.request<
        CreateQuickEntriesJobFromPromptMutation,
        CreateQuickEntriesJobFromPromptMutationVariables
      >(query, variables);

      return result.createQuickEntriesJobFromPrompt.id;
    },
  });
};
