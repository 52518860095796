import { useEffect, useId, useRef } from 'react';
import { useReward } from 'react-rewards';
import { Box, type BoxProps } from '@/ui';

type Props = BoxProps & {
  condition?: boolean;
};

export const Confetti = ({ condition = true, ...props }: Props) => {
  const rewardId = useId();
  const rewarded = useRef(false);

  const { reward } = useReward(rewardId, 'confetti', {
    startVelocity: 20,
    lifetime: 300,
    elementCount: 100,
    spread: 200,
  });

  useEffect(() => {
    if (!rewarded.current && condition) {
      reward();
      rewarded.current = true;
    }
  }, [condition, reward]);

  return (
    <Box
      zIndex="calc(var(--chakra-zIndices-modal) + 10)"
      {...props}
      id={rewardId}
    />
  );
};
