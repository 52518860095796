import { forwardRef, MotionFlex, type MotionFlexProps, Text } from '@/ui';

export const LoadingState = forwardRef<MotionFlexProps, typeof MotionFlex>(
  function LoadingStateWithRef(props, ref) {
    return (
      <MotionFlex
        align="center"
        direction="column"
        flex="1"
        gap="6"
        justify="center"
        ref={ref}
        {...props}
      >
        <Text fontSize="56px" lineHeight="1">
          ✨
        </Text>
        <Text fontSize="xl" fontWeight="bold">
          Creating your schedule
        </Text>
      </MotionFlex>
    );
  }
);
