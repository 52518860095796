import {
  useIsBreakpoint,
  useIsMobileBreakpoint,
} from '@/hooks/useIsBreakpoint';
import {
  Text,
  Icon,
  Button,
  type ButtonProps,
  forwardRef,
  type IconType,
} from '@/ui';
import { useIsColumnLayout } from '../../../../../hooks/useIsColumnLayout';

type Props = Omit<ButtonProps, 'leftIcon' | 'children'> & {
  label: string;
  leftIcon: IconType;
};

export const FooterButton = forwardRef<Props, 'button'>(
  function FooterButtonWithRef({ leftIcon, label, ...props }, ref) {
    const { isColumnLayout } = useIsColumnLayout();
    const isXlBreakpoint = useIsBreakpoint('xl');
    const isMobileBreakpoint = useIsMobileBreakpoint();

    let isIconOnlyButton = false;
    if (isColumnLayout && isMobileBreakpoint) {
      isIconOnlyButton = true;
    }
    if (!isColumnLayout && isXlBreakpoint) {
      isIconOnlyButton = true;
    }

    return (
      <Button
        aria-label={label}
        bg="white"
        leftIcon={<Icon as={leftIcon} boxSize="4" />}
        ref={ref}
        size="sm"
        variant="secondary"
        sx={
          isIconOnlyButton
            ? {
                px: 2,
                '> span': {
                  mr: 0,
                },
                '> .label': {
                  display: 'none',
                },
              }
            : undefined
        }
        {...props}
      >
        <Text
          className="label"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {label}
        </Text>
      </Button>
    );
  }
);
