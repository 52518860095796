import { Icon, IconButton, type IconButtonProps } from '@/ui';

type Props = Omit<IconButtonProps, 'aria-label'>;

export const RemoveButton = (props: Props) => {
  return (
    <IconButton
      aria-label="Remove"
      borderRadius="full"
      boxSize="5"
      color="white"
      colorScheme="purple"
      icon={<Icon icon="X" size="3.5" />}
      minW="auto"
      opacity="0"
      pos="absolute"
      right="-3"
      shadow="-2px 2px 4px 0px rgba(0, 0, 0, 0.25)"
      size="sm"
      top="-3"
      {...props}
    />
  );
};
