import type { ReactNode } from 'react';
import { Tooltip, type TooltipProps } from '@/ui';

type Props = Omit<TooltipProps, 'children'> & {
  icon: ReactNode;
};

/**
 * Use this component when wrapping a react-icons icon with tooltip.
 * react-icons icons do not use forwardRef
 * https://chakra-ui.com/docs/components/tooltip#with-an-icon
 */
export const IconTooltip = ({ icon, ...props }: Props) => {
  return (
    <Tooltip {...props}>
      <span>{icon}</span>
    </Tooltip>
  );
};
