import { gql } from 'graphql-request';
import type { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { useCurrentUserContext } from '@/contexts';
import { gqlWsClient, handleError } from '@/lib/gql-client'; // Must be standalone import for tests to pass
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import type { ScheduleOpenedSubscription } from './useScheduleOpenedSubscription.generated';

const query = gql`
  subscription ScheduleOpened($scheduleId: ID!) {
    scheduleOpened(scheduleId: $scheduleId)
  }
`;

export const useScheduleOpenedSubscription = () => {
  const { currentUser } = useCurrentUserContext();
  const { scheduleId, isPublicRole } = useScheduleContext();
  const cache = useScheduleCache();

  const handleData = useCallback(
    ({ data }: ExecutionResult<ScheduleOpenedSubscription>) => {
      if (data?.scheduleOpened && data.scheduleOpened !== currentUser?.id) {
        cache.addActiveUserToSchedule(data.scheduleOpened);
      }
    },
    [currentUser, cache]
  );

  useEffect(() => {
    if (isPublicRole) {
      // Disabled for read only schedules
      return;
    }

    const unsubscribe = gqlWsClient.subscribe<ScheduleOpenedSubscription>(
      {
        query: query,
        variables: {
          scheduleId,
        },
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, isPublicRole, handleData]);
};
