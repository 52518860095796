import { useRef } from 'react';
import { useToast } from '@/hooks/useToast';
import { factory } from '../categoryLib';
import { useCategorySelectContext } from '../hooks/useCategorySelectContext';
import { useCreateCategory } from '../hooks/useCreateCategory';
import type { Category } from '../types';
import { CategoryForm } from './CategoryForm';

type Props = {
  onAdd?: (category: Category) => void;
};

export const CategoryAdd = ({ onAdd }: Props) => {
  const { current: initialCategory } = useRef(factory());
  const { goBack } = useCategorySelectContext();
  const { createCategory, isPending } = useCreateCategory();
  const toast = useToast();

  return (
    <CategoryForm
      category={initialCategory}
      onCancel={goBack}
      onSubmit={(category) => {
        if (!isPending) {
          createCategory(category, {
            onSuccess: () => {
              onAdd?.(category);
              goBack();
            },
            onError: (err) => toast.error(err.message),
          });
        }
      }}
    />
  );
};
