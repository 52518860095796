import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AuthFormStack, AuthHeader, Link } from '@/components/auth';
import { usePageTitle } from '@/hooks/usePageTitle';
import { useTrackPageView } from '@/hooks/useTrackPageView';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Flex,
} from '@/ui';
import { isValidEmail } from '@/utils/validation';
import { useResetPasswordRequest } from './useResetPasswordRequest';

export const ResetPasswordRequest = (): JSX.Element => {
  const { t } = useTranslation(['auth', 'resetPasswordRequest']);
  const { mutate, isPending, isSuccess, isError } = useResetPasswordRequest();
  usePageTitle(t('resetPasswordRequest:page_title'));
  useTrackPageView('reset-password-request');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  return (
    <AuthFormStack
      header={
        <AuthHeader title={t('resetPasswordRequest:heading')}>
          {t('resetPasswordRequest:byline')}
        </AuthHeader>
      }
      onSubmit={handleSubmit((values) => {
        mutate(
          { email: values.email },
          {
            onSuccess: () => reset(),
          }
        );
      })}
    >
      {isSuccess ? (
        <Alert colorScheme="gray" status="success">
          <AlertTitle>{t('resetPasswordRequest:success')}</AlertTitle>
        </Alert>
      ) : isError ? (
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>{t('resetPasswordRequest:error')}</AlertTitle>
        </Alert>
      ) : null}

      <FormControl isInvalid={!!errors.email}>
        <FormLabel htmlFor="email">
          {t('resetPasswordRequest:inputs.email')}
        </FormLabel>
        <Input
          autoFocus
          id="email"
          type="email"
          {...register('email', {
            required: true,
            validate: {
              email: (value) => isValidEmail(value),
            },
          })}
        />
        <FormErrorMessage>
          {errors.email?.type === 'required'
            ? t('auth:validation.required')
            : errors.email?.type === 'email'
              ? t('auth:validation.email_invalid')
              : null}
        </FormErrorMessage>
      </FormControl>

      <Flex gap="6">
        <Button isLoading={isPending} type="submit">
          {t('resetPasswordRequest:buttons.submit')}
        </Button>

        <Link to="/auth">{t('resetPasswordRequest:buttons.login')}</Link>
      </Flex>
    </AuthFormStack>
  );
};
