import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoReturnDownForwardOutline } from 'react-icons/io5';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Text,
} from '@/ui';
import { validateText } from '../utils';
import { PlainLabelInput } from './PlainLabelInput';

type Props = {
  value?: string;
  validate?: boolean;
  onAdd: (text: string) => void;
  onCancel: () => void;
};

export const AddLabelInput = ({ onAdd, ...rest }: Props) => {
  const { t } = useTranslation('labelSelect');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const handleSubmit = (text: string) => {
    const errorMessages = validateText(text);
    setErrorMessages(errorMessages);

    if (errorMessages.length === 0) {
      onAdd(text);
    }
  };

  return (
    <FormControl isInvalid={errorMessages.length > 0}>
      <PlainLabelInput validate={false} {...rest} onAdd={handleSubmit} />

      <FormErrorMessage>
        <Flex gap="2">
          {errorMessages.map((message, i) => (
            <Text key={i}>{message}</Text>
          ))}
        </Flex>
      </FormErrorMessage>

      <FormHelperText
        alignItems="center"
        color="gray.500"
        justifyContent="start"
      >
        <Flex align="center" gap="2">
          <IoReturnDownForwardOutline size="18" />
          <Text fontSize="xs">{t('who.create_help')}</Text>
        </Flex>
      </FormHelperText>
    </FormControl>
  );
};
