import { type DateTime, Info } from 'luxon';
import { Select, type SelectProps } from '@/ui';

type Props = Omit<SelectProps, 'value' | 'onChange'> & {
  value: DateTime;
  onChange: (value: number) => void;
};

const months = Info.months('long');

const options = months.map((month, index) => (
  <option key={month} value={index}>
    {month}
  </option>
));

export const MonthSelect = ({ value, onChange, ...props }: Props) => {
  return (
    <Select
      size="sm"
      value={value.month - 1}
      w="auto"
      onChange={(event) => onChange(Number(event.target.value))}
      {...props}
    >
      {options}
    </Select>
  );
};
