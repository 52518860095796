import { v4 as uuidv4 } from 'uuid';
import { i18n } from '@/i18n';
import type { Label } from '@/types/gql.generated';

const MIN_LABEL_LENGTH = 1;

export const createDraftLabel = (text: string, sortOrder: number): Label => {
  return {
    id: uuidv4(),
    text,
    sortOrder,
  };
};

export const validateText = (text: string): string[] => {
  const messages: string[] = [];

  if (text.length < MIN_LABEL_LENGTH) {
    messages.push(i18n.t('labelSelect:validate_required'));
  }

  return messages;
};

export const isValidLabel = (text: string): boolean => {
  return text.length >= MIN_LABEL_LENGTH;
};
