import { notionVariantBaseStyles } from '@/theme/styles';
import { defineStyleConfig } from '@/ui';

export const Textarea = defineStyleConfig({
  baseStyle: {
    borderRadius: '8px',
    _placeholder: {
      color: 'customgray.alsolight',
    },
    _focusVisible: {
      borderColor: 'brand.500',
    },
    _focus: {
      borderColor: 'brand.500',
      borderWidth: '1px',
      boxShadow: '0px 0px 9px rgba(0, 0, 0, 0.1)',
      _hover: {
        borderColor: 'brand.500',
      },
    },
  },
  variants: {
    notion: {
      ...notionVariantBaseStyles,
      paddingTop: 1,
      paddingBottom: 1,
      minHeight: 9,
      transition: 'box-shadow 0.2s ease',
      _focus: {
        boxShadow: '0px 0px 6px 2px rgba(0, 0, 0, 0.1)',
        _hover: {
          bg: 'inherit',
        },
      },
    },
  },
});
