import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Logo } from '@/components/Logo';
import { ProfileMenu } from '@/components/ProfileMenu';
import { useCurrentUserContext } from '@/contexts';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { useUnsafeScheduleContext } from '@/pages/Schedule/contexts';
import { Flex, type FlexProps, Button, type ContainerProps } from '@/ui';
import { Layout } from './Layout';

type Props = FlexProps & {
  leftColumn?: ReactNode;
  leftColumnProps?: FlexProps;
  middleColumn?: ReactNode;
  rightColumn?: ReactNode;
  rightColumnProps?: FlexProps;
  logoContainerProps?: FlexProps;
  profileContainerProps?: FlexProps;
  hideProfileMenu?: boolean;
  containerProps?: ContainerProps;
  logoHref?: {
    to: string;
    external?: boolean;
  };
};

export const Header = ({
  leftColumn,
  leftColumnProps,
  middleColumn,
  rightColumn,
  rightColumnProps,
  logoContainerProps,
  profileContainerProps,
  hideProfileMenu,
  containerProps,
  logoHref,
  ...props
}: Props) => {
  const { t } = useTranslation('header');
  const isLgBreakpoint = useIsBreakpoint('lg');
  const { isAuthenticated } = useCurrentUserContext();
  const scheduleContext = useUnsafeScheduleContext();
  const shouldShowProfileMenu =
    !hideProfileMenu && (isAuthenticated || scheduleContext?.isPublicRole);

  if (!logoHref) {
    logoHref = {
      to: '/',
      external: !isAuthenticated,
    };
  }

  const logoLinkProp: Partial<Record<'to' | 'href', string>> = {};
  if (logoHref.external) {
    logoLinkProp.href = logoHref.to;
  } else {
    logoLinkProp.to = logoHref.to;
  }

  if (typeof leftColumn === 'undefined') {
    leftColumn = (
      <Flex
        as={logoHref.external ? 'a' : Link}
        {...logoLinkProp}
        _active={{ bg: 'gray.200' }}
        _hover={{ textDecor: 'none', bg: 'gray.100' }}
        align="center"
        borderRadius="xl"
        fontSize="17"
        fontWeight="medium"
        lineHeight="normal"
        ml={{ base: 1, md: 0 }}
        px="2"
        py="1.5"
      >
        {t('agendahero', { ns: 'common' })}
      </Flex>
    );
  }

  return (
    <Layout
      {...props}
      {...containerProps}
      middleColumn={middleColumn}
      leftColumn={
        <Flex
          align="center"
          display="flex"
          flex={isLgBreakpoint ? 0 : 1}
          h="100%"
          {...leftColumnProps}
        >
          {/* Logo */}
          <Flex
            align="center"
            borderRightColor="customgray.gray"
            borderRightWidth={{ md: '1px' }}
            h="100%"
            shrink="0"
            {...logoContainerProps}
          >
            <Button
              as={logoHref.external ? 'a' : Link}
              {...logoLinkProp}
              _active={{ bg: 'gray.200' }}
              _hover={{ bg: 'gray.100' }}
              aria-label={t('return_home')}
              borderRadius={{ base: 'full', md: 'none' }}
              display="flex"
              h={{ base: '40px', md: '100%' }}
              minW="auto"
              ml={{ base: 2, sm: 4, md: 0 }}
              py={{ base: 2, md: 0 }}
              variant="unstyled"
              w={{ base: '40px', md: '72px' }}
            >
              <Logo m="auto" width={24} />
            </Button>
          </Flex>

          {leftColumn}
        </Flex>
      }
      rightColumn={
        shouldShowProfileMenu && (
          <Flex
            align="center"
            flex="1"
            gap={isLgBreakpoint ? 3 : 0}
            h="100%"
            justify="flex-end"
            pr={{ base: 3, md: 0 }}
            {...rightColumnProps}
          >
            {rightColumn}

            {shouldShowProfileMenu && (
              <Flex
                align="center"
                borderLeftColor="customgray.gray"
                borderLeftWidth={{ md: '1px' }}
                h={{ base: 'auto', md: '100%' }}
                ml={{ base: 0, md: 5 }}
                pr={{ xl: 2 }}
                {...profileContainerProps}
              >
                <ProfileMenu />
              </Flex>
            )}
          </Flex>
        )
      }
    />
  );
};
