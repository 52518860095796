import {
  DndContext,
  type DragEndEvent,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import {
  type StackProps,
  VStack,
  type RadioProps,
  useColorModeValue,
} from '@/ui';
import { useUpdateCategorySortOrder } from '../hooks/useUpdateCategorySortOrder';
import type { Category } from '../types';
import { CategoryRow } from './CategoryRow';
import { Row } from './Row';

type Props = Omit<StackProps, 'onSelect'> & {
  selected?: Category | null;
  radioProps?: RadioProps;
  onSelect: (category: Category | null) => void;
};

export const CategoryPanel = ({ selected, onSelect, ...props }: Props) => {
  const { schedule } = useScheduleContext();
  const { t } = useTranslation('categorySelect');
  const radioColorScheme = useColorModeValue('blackAlpha', 'whiteAlpha');
  const { updateSortOrder } = useUpdateCategorySortOrder();
  const radioProps = { colorScheme: radioColorScheme };

  // store state locally for optimistic updates
  const [categories, setCategories] = useState(schedule?.categories ?? []);
  useUpdateEffect(() => {
    setCategories(schedule?.categories ?? []);
  }, [schedule]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // move 5px before activating DnD - allows categories to be clicked
        distance: 5,
      },
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = categories.findIndex(({ id }) => id === active.id);
      const newIndex = categories.findIndex(({ id }) => id === over?.id);
      const updatedCategories = arrayMove(categories, oldIndex, newIndex);
      setCategories(updatedCategories);
      updateSortOrder(updatedCategories);
    }
  };

  return (
    <VStack {...props}>
      <Row
        _hover={{ bg: 'gray.50' }}
        flex="1"
        isChecked={!selected}
        radioProps={radioProps}
        onClick={() => onSelect(null)}
      >
        {t('none')}
      </Row>

      <DndContext
        collisionDetection={closestCenter}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={categories}
          strategy={verticalListSortingStrategy}
        >
          {categories.map((category) => (
            <CategoryRow
              _notLast={{ mb: 2 }}
              category={category}
              isChecked={selected?.id === category.id}
              key={category.id}
              radioProps={radioProps}
              onSelect={onSelect}
            />
          ))}
        </SortableContext>
      </DndContext>
    </VStack>
  );
};
