import { type ReactNode, useEffect } from 'react';
import { config } from '@/config';
import { useAnalytics } from '@/hooks/useAnalytics';
import { Sentry, queryClient } from '@/lib';
import { createContext } from '@/utils';
import { createSchedulesQueryKey } from '@/utils/queryKeys';
import { useProfile } from './hooks';
import type { CurrentUserContextValue } from './types';

export const CurrentUserContext = createContext<CurrentUserContextValue>(
  undefined,
  'CurrentUserContext'
);

// This context mainly acts as a proxy to the useProfile hook while providing
// a logical space to perform one-time side-effects, like initializing LogRocket.
// If we didn't have side-effects then components could just use the useProfile
// hook directly.
export const CurrentUserContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const profile = useProfile();
  const { identify: identifyAnalytics } = useAnalytics();
  const { currentUser } = profile;

  useEffect(() => {
    // Invalidate any schedules that exist in cache since the user might
    // have new permissions post-authentication
    queryClient.invalidateQueries({
      queryKey: createSchedulesQueryKey(),
    });

    if (config.sentry.enabled) {
      const user = currentUser
        ? {
            ...currentUser,
            email: currentUser.email ?? undefined,
          }
        : null;
      Sentry.setUser(user);
    }

    if (currentUser) {
      identifyAnalytics(currentUser);
    }
  }, [currentUser, identifyAnalytics]);

  return (
    <CurrentUserContext.Provider value={profile}>
      {children}
    </CurrentUserContext.Provider>
  );
};
