import { useEffect, useState } from 'react';

export const useConditionallyMount = (shouldRender: boolean) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (shouldRender) {
      setMounted(true);
    }
  }, [shouldRender]);

  return { mounted, setMounted };
};
