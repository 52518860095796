import { Text } from '@/ui';
import Underline from './underline.svg?react';

export const FamilyCalendarText = () => {
  return (
    <Text>
      <Text as="span" pos="relative">
        <Underline
          style={{ position: 'absolute', bottom: -8, left: -8, right: 0 }}
        />
        family
      </Text>{' '}
      calendar
    </Text>
  );
};
