import type { DateTime } from 'luxon';
import { Flex } from '@/ui';
import { MonthSelect } from './MonthSelect';
import { YearSelect } from './YearSelect';

type Props = {
  date: DateTime;
  onChangeMonth: (month: number) => void;
  onChangeYear: (year: number) => void;
};

export const DatePickerHeader = ({
  date,
  onChangeYear,
  onChangeMonth,
}: Props) => {
  return (
    <Flex align="center" gap="2" pb="3" px="8px">
      <MonthSelect flex="1" value={date} onChange={onChangeMonth} />
      <YearSelect value={date} onChange={onChangeYear} />
    </Flex>
  );
};
