import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCreateSchedule } from '@/hooks/useCreateSchedule';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';

export const useCreateScheduleWrapper = () => {
  const {
    createSchedule,
    data: schedule,
    isPending,
    isSuccess,
  } = useCreateSchedule();
  const [didNavigate, setDidNavigate] = useState(false);
  const [debouncedIsSuccess, setDebouncedIsSuccess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isSuccess) {
      const timerId = setTimeout(() => setDebouncedIsSuccess(true), 500);
      return () => clearTimeout(timerId);
    }
  }, [isSuccess]);

  const debouncedIsPending = useDebounceLoading(
    isPending || (isSuccess && !debouncedIsSuccess),
    200
  );

  useEffect(() => {
    if (schedule && location.pathname.includes(schedule.id)) {
      setDidNavigate(true);
    }
  }, [location, schedule, isPending, didNavigate]);

  return {
    createSchedule,
    debouncedIsPending,
    debouncedIsSuccess,
  };
};
