import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '@/contexts';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { AttendeeStatus } from '@/types/gql.generated';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { Box, Button, Flex, Text, forwardRef } from '@/ui';
import { RsvpNotesButton } from './components/NotesButton';
import { RsvpButton } from './components/RsvpButton';
import { useRsvp } from './hooks/useRsvp';

type Props = {
  entry: DecoratedEntry;
};

export const RsvpBar = ({ entry }: Props) => {
  const { schedule } = useScheduleContext();
  const { rsvp, variables, isPending } = useRsvp(entry);
  const { t } = useTranslation('rsvpBar');
  const { currentUser } = useCurrentUserContext();
  const prompt = schedule?.rsvpSettings?.question || t('prompt');
  const yesLabel = schedule?.rsvpSettings?.yesLabel || t('going');
  const noLabel = schedule?.rsvpSettings?.noLabel || t('not_going');

  const isAccepting = useDebounceLoading(
    isPending && variables?.status === AttendeeStatus.Accepted
  );
  const isDeclining = useDebounceLoading(
    isPending && variables?.status === AttendeeStatus.Declined
  );

  const [showRsvpControls, setShowRsvpControls] = useState(
    !entry.rsvpInfo?.status
  );

  const handleRsvp = (value: AttendeeStatus) => () => {
    rsvp(value, {
      onSuccess: () => setShowRsvpControls(false),
    });
  };

  const handleChangeRsvp = () => {
    setShowRsvpControls(true);
  };

  useLauncherAction(`rsvp-item:${entry.id}:${AttendeeStatus.Accepted}`, () => {
    handleRsvp(AttendeeStatus.Accepted)();
  });

  useLauncherAction(`rsvp-item:${entry.id}:${AttendeeStatus.Declined}`, () => {
    handleRsvp(AttendeeStatus.Declined)();
  });

  return (
    <Box flex="1" overflow="hidden" pos="relative">
      <AnimatePresence initial={false} mode="popLayout">
        {!showRsvpControls && entry.rsvpInfo?.status ? (
          <Container
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            initial={{ y: '100%' }}
            key="rsvp"
          >
            <Flex
              align={{ base: 'flex-start', sm: 'center' }}
              direction={{ base: 'column', sm: 'row' }}
              gap={{ base: 0, sm: 5 }}
              lineHeight="normal"
            >
              <Text flexShrink={0} fontWeight="medium" noOfLines={1}>
                {t('rsvpd_as', { name: currentUser?.name })}
              </Text>
              <RsvpNotesButton entry={entry} />
            </Flex>
            <Flex gap="4">
              <Button
                color="customgray.mid"
                colorScheme="dark"
                size="sm"
                variant="link"
                onClick={handleChangeRsvp}
              >
                {t('change')}
              </Button>
              <RsvpButton
                colorScheme="green"
                entry={entry}
                pointerEvents="none"
                status={entry.rsvpInfo.status}
                {...((entry.rsvpInfo.status === AttendeeStatus.Declined && {
                  borderColor: 'customgray.mid',
                  bg: 'customgray.mid',
                  children: noLabel,
                }) || {
                  children: yesLabel,
                })}
              />
            </Flex>
          </Container>
        ) : (
          <Container
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            initial={{ y: '-100%' }}
            key="default"
          >
            <Box>
              <Text
                color="brand.500"
                fontSize="2xs"
                fontWeight="bold"
                textTransform="uppercase"
              >
                {t('rsvp_requested')}
              </Text>
              <Text fontWeight="medium">{prompt}</Text>
            </Box>
            <Flex gap="2">
              <RsvpButton
                entry={entry}
                isDisabled={isDeclining}
                isLoading={isAccepting}
                status={AttendeeStatus.Accepted}
                onClick={handleRsvp(AttendeeStatus.Accepted)}
              >
                {yesLabel}
              </RsvpButton>

              <RsvpButton
                entry={entry}
                isDisabled={isAccepting}
                isLoading={isDeclining}
                status={AttendeeStatus.Declined}
                onClick={handleRsvp(AttendeeStatus.Declined)}
              >
                {noLabel}
              </RsvpButton>
            </Flex>
          </Container>
        )}
      </AnimatePresence>
    </Box>
  );
};

const Container = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    align="center"
    h="70px"
    justify="space-between"
    ref={ref}
    transition={{ duration: 0.1 }}
    w="100%"
    {...props}
  />
));
