import { useState } from 'react';
import type { Label } from '@/types/gql.generated';

export const useOptimisticSelected = (selected: Label[]) => {
  const [optimisticSelected, setOptimisticSelected] = useState(selected ?? []);

  const addOptimisticSelected = (label: Label) =>
    setOptimisticSelected((labels) => labels.concat(label));

  const removeOptimisticSelected = (label: Label) =>
    setOptimisticSelected((selected) =>
      selected.filter(({ id }) => id !== label.id)
    );

  return {
    optimisticSelected,
    addOptimisticSelected,
    removeOptimisticSelected,
  };
};
