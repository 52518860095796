import { useTranslation } from 'react-i18next';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';
import { AppLoader } from '@/components/AppLoader';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { AttendeeStatus } from '@/types/gql.generated';
import { Accordion, Box, Icon } from '@/ui';
import { useEntryInvites } from '../hooks/useEntryInvites';
import { AccordionSection } from './AccordionSection';
import { InviteList } from './InviteList';

type Props = {
  entry: DecoratedEntry;
};

export const RsvpList = ({ entry }: Props) => {
  const { schedule } = useScheduleContext();
  const { invites, isLoading } = useEntryInvites(entry.id);
  const { t } = useTranslation('entryModal', { keyPrefix: 'rsvps_panel' });

  if (isLoading) {
    return <AppLoader />;
  }

  const yesLabel = schedule?.rsvpSettings?.yesLabel || t('going');
  const noLabel = schedule?.rsvpSettings?.noLabel || t('not_going');

  return (
    <Accordion allowToggle flex="1">
      {Object.entries(invites).map(([status, invites]) => {
        const isAccepted = status === AttendeeStatus.Accepted;

        return (
          <AccordionSection
            key={status}
            label={`${isAccepted ? yesLabel : noLabel} (${invites.length})`}
            icon={
              isAccepted ? (
                <Icon as={FaRegThumbsUp} color="green" />
              ) : (
                <Icon as={FaRegThumbsDown} />
              )
            }
          >
            {invites.length ? (
              <InviteList invites={invites} />
            ) : (
              <Box>{t('empty_state')}</Box>
            )}
          </AccordionSection>
        );
      })}
    </Accordion>
  );
};
