import type { Editor } from '@tiptap/react';
import { type FormEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadTarget } from '@/components/UploadTarget';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { EntriesJobType } from '@/types/gql.generated';
import { Flex, type FlexProps, Button } from '@/ui';
import { shakeElement } from '@/utils/shakeElement';
import {
  SegmentedControl,
  type SegmentedControlOption,
} from '../../../SegmentedControl';
import type { Prompt } from '../../types';
import { PromptTextArea } from './PromptTextArea';

const segments: SegmentedControlOption<EntriesJobType>[] = [
  {
    label: 'Text',
    value: EntriesJobType.Prompt,
  },
  {
    label: 'Image or PDF',
    value: EntriesJobType.File,
  },
];

type Props = FlexProps & {
  prompt: Prompt;
  instructions: string;
  jobType: EntriesJobType;
  onChangeJobType: (type: EntriesJobType) => void;
  onCancel: () => void;
  onSubmit: () => void;
  onPromptChange: (prompt: Partial<Prompt>) => void;
  onInstructionsChange: (instructions: string) => void;
  onDropText: (text: string) => void;
};

export const PromptForm = ({
  prompt,
  instructions,
  jobType,
  onCancel,
  onChangeJobType,
  onPromptChange,
  onSubmit,
  onInstructionsChange,
  onDropText,
  ...props
}: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('addContent');
  const textPromptRef = useRef<Editor | null>(null);
  const { file, html } = prompt;

  const { value: disableAllAiFeatures } = useFeatureFlag(
    'disableAllAiFeatures'
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (jobType === EntriesJobType.Prompt && !html.trim().length) {
      await shakeElement(containerRef);
      textPromptRef.current?.commands.focus();
    } else if (jobType === EntriesJobType.File && !file) {
      await shakeElement(containerRef);
    } else {
      onSubmit();
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onCancel();
    }
  };

  return (
    <Flex
      {...props}
      as="form"
      direction="column"
      display="flex"
      gap="3"
      onDragOver={() => onChangeJobType(EntriesJobType.File)}
      // @ts-expect-error - onSubmit is a valid prop with `as`
      onSubmit={handleSubmit}
    >
      <SegmentedControl
        options={segments}
        value={jobType}
        onChange={onChangeJobType}
      />

      {jobType === EntriesJobType.Prompt ? (
        <>
          <PromptTextArea
            autoFocus
            containerRef={containerRef}
            placeholder={t('placeholder')}
            readOnly={disableAllAiFeatures}
            ref={textPromptRef}
            value={html}
            onChange={(html) => onPromptChange({ html })}
            onKeyUp={handleKeyUp}
          />
          <Button
            colorScheme="dark"
            isDisabled={disableAllAiFeatures}
            lineHeight="1"
            type="submit"
          >
            {t('submit')}
          </Button>
        </>
      ) : jobType === EntriesJobType.File ? (
        <>
          <UploadTarget
            file={file}
            instructions={instructions}
            isDisabled={disableAllAiFeatures}
            minH="250px"
            onDropText={onDropText}
            onInstructionsChange={onInstructionsChange}
            onSelectFile={(file) => onPromptChange({ file })}
          />
          <Button
            colorScheme="dark"
            isDisabled={disableAllAiFeatures || !file}
            lineHeight="1"
            type="submit"
            w="100%"
          >
            {t('submit')}
          </Button>
        </>
      ) : null}
    </Flex>
  );
};
