import { ChevronDownIcon } from '@chakra-ui/icons';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useToast } from '@/hooks/useToast';
import type { ScheduleCollaborators } from '@/pages/Schedule/hooks';
import { Role } from '@/types/gql.generated';
import {
  Button,
  Collapse,
  Flex,
  FormControl,
  FormErrorMessage,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Portal,
  Text,
  Textarea,
} from '@/ui';
import { isGQLError } from '@/utils/errors';
import { isValidEmail } from '@/utils/validation';
import { useAddCollaborator } from '../../hooks/useAddCollaborator';
import { useRemoveCollaborator } from '../../hooks/useRemoveCollaborator';
import { useUpdateCollaborator } from '../../hooks/useUpdateCollaborator';
import { UserList } from './components/UserList';

type Props = {
  collaborators: ScheduleCollaborators;
  defaultEmail?: string;
};

type FormValues = {
  email: string;
  message: string;
  role: Role;
};

const emailAsArray = (value: string): string[] => {
  const emails = value
    .split(',')
    .map((email) => email.trim())
    .filter((email) => !!email);
  const unique = new Set(emails);
  return Array.from(unique);
};

export const CollaboratorsSection = ({
  collaborators,
  defaultEmail = '',
}: Props) => {
  const { t } = useTranslation('shareModal');
  const { addCollaborator } = useAddCollaborator();
  const { removeCollaborator } = useRemoveCollaborator();
  const { updateCollaborator } = useUpdateCollaborator();
  const { notify } = useToast();
  const isMobileBreakpoint = useIsMobileBreakpoint();

  const {
    register,
    control,
    handleSubmit: onSubmit,
    setError: setFieldError,
    reset: resetForm,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      email: defaultEmail,
      message: '',
      role: Role.Editor,
    },
  });

  const watchEmail = watch('email');

  const handleSubmit = (values: FormValues) => {
    const users = emailAsArray(values.email).map((email) => ({
      email,
      role: values.role,
    }));

    addCollaborator(
      { users, message: values.message },
      {
        onError: (err) => {
          setFieldError('email', {
            message: isGQLError(err)
              ? err.response.errors?.[0]?.message
              : t('generic_email_error'),
          });
        },
        onSuccess: () => {
          notify(
            t('invite_success_toast', {
              email: values.email,
              count: users.length,
            })
          );
          // Resets to the default form values provided in the useForm hook, but
          // force resetting email to an empty string in case an email was
          // specified as a default by the share modal launcher
          resetForm({
            email: '',
          });
        },
      }
    );
  };

  return (
    <Flex direction="column" flex="1">
      <Text fontWeight="medium">{t('byline')}</Text>
      <Text color="customgray.mid" fontSize="sm" mb="3">
        {t('hint')}
      </Text>

      <Flex
        as="form"
        direction="column"
        mb="8"
        onSubmit={onSubmit(handleSubmit)}
      >
        <Flex flex="1" gap="3">
          <FormControl isInvalid={!!errors.email}>
            <Textarea
              as={TextareaAutosize}
              autoFocus={!isMobileBreakpoint}
              placeholder={t('email_placeholder')}
              rows={1}
              {...register('email', {
                required: t('validation.required', { ns: 'auth' }),
                validate: {
                  email: (value) => {
                    const invalidEmail = emailAsArray(value).find(
                      (email) => !isValidEmail(email)
                    );
                    if (invalidEmail) {
                      return t('invalid_email', { email: invalidEmail });
                    }
                  },
                },
              })}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <Menu isLazy placement="bottom-end">
                <MenuButton
                  _active={{ boxShadow: 'none', bg: 'gray.200' }}
                  _hover={{ bg: 'gray.200' }}
                  as={Button}
                  bg="gray.100"
                  border="none"
                  flexShrink="0"
                  rightIcon={<ChevronDownIcon height="4" width="4" />}
                  shadow="none"
                  textTransform="capitalize"
                  variant="secondary"
                >
                  {field.value.toLowerCase()}
                </MenuButton>
                <Portal>
                  <MenuList minW="9rem" zIndex="var(--chakra-zIndices-modal)">
                    <MenuOptionGroup
                      type="radio"
                      value={field.value}
                      onChange={(value) => field.onChange(value as Role)}
                    >
                      <MenuItemOption value={Role.Viewer}>
                        {t('viewer')}
                      </MenuItemOption>
                      <MenuItemOption value={Role.Editor}>
                        {t('editor')}
                      </MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Portal>
              </Menu>
            )}
          />
        </Flex>

        <Collapse in={watchEmail.length > 0}>
          <Flex direction="column" gap="1.5" mt="2">
            <Textarea placeholder="Optional message" {...register('message')} />
            <Button ml="auto" type="submit">
              {t('invite_button')}
            </Button>
          </Flex>
        </Collapse>
      </Flex>

      {collaborators.length > 0 && (
        <UserList
          collaborators={collaborators}
          onChangeRole={(user, role) => updateCollaborator(user.id, role)}
          onRemove={(user) => removeCollaborator(user.id)}
        />
      )}
    </Flex>
  );
};
