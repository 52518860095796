import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Center, Grid, GridItem, Text, Icon } from '@/ui';
import { Arrow } from './Arrow';

type Props = {
  prompt: string;
};

export const EmptyCta = ({ prompt }: Props) => {
  const isMobile = useIsMobileBreakpoint();

  return (
    <Grid
      alignItems="center"
      color="customgray.mid"
      gap={4}
      templateColumns="repeat(7, 1fr)"
    >
      <GridItem>
        {isMobile && (
          <Arrow
            style={{
              transform: 'scaleX(-1)',
              height: 29,
              width: 28,
              position: 'relative',
              left: 30,
              top: -16,
            }}
          />
        )}
      </GridItem>
      <GridItem colSpan={5}>
        <Center fontSize="sm" py="6">
          <Text pr="1">{prompt}</Text>
          <Icon icon="Plus" size="3" />
        </Center>
      </GridItem>
      <GridItem>
        {!isMobile && (
          <Arrow
            style={{
              height: 29,
              width: 28,
              position: 'relative',
              left: -16,
              top: -18,
            }}
          />
        )}
      </GridItem>
    </Grid>
  );
};
