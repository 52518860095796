import { Flex, Heading, Link, Text } from '@/ui';
import { PlatformPicker } from './PlatformPicker';

export const DestinationsSection = () => {
  return (
    <Flex as="section" direction="column" gap="3">
      <Heading
        alignItems="center"
        as="h3"
        display="flex"
        fontWeight="600"
        gap="2"
        size="sm"
      >
        <Text as="span">Preferred calendar platform</Text>
      </Heading>

      <Text>
        Set your preferred calendar destination for faster access when adding
        items from{' '}
        <Link href="/magic" target="_blank">
          Magic
        </Link>
        .
      </Text>

      <PlatformPicker />
    </Flex>
  );
};
