import { type GraphQLError, getGQLErrorExtensionData } from '@/utils/errors';
import { type SnippetInUse, isSnippetsInUseErrorExtensionData } from './types';

export const getSnippetsInUse = (err: GraphQLError): SnippetInUse[] => {
  const extensionData = getGQLErrorExtensionData(err);

  return isSnippetsInUseErrorExtensionData(extensionData)
    ? extensionData.snippets
    : [];
};
