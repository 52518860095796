import { Suspense, lazy } from 'react';
import { AppLoader } from '@/components/AppLoader';
import type { PdfRendererProps } from './types';

const PdfRendererComponent = lazy(() => import('./PdfRenderer'));

export const PdfRenderer = (props: PdfRendererProps) => {
  return (
    <Suspense fallback={<AppLoader />}>
      <PdfRendererComponent {...props} />
    </Suspense>
  );
};
