import {
  MotionFlex,
  type MotionFlexProps,
  Flex,
  type FlexProps,
  Heading,
  type HeadingProps,
  forwardRef,
} from '@/ui';

const variants = {
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const Card = forwardRef((props: MotionFlexProps, ref) => {
  return (
    <MotionFlex
      animate="enter"
      bg="white"
      borderRadius="xl"
      exit="exit"
      initial="exit"
      ref={ref}
      transition={{ duration: 0.15 }}
      variants={variants}
      w="100%"
      {...props}
    />
  );
});

export const CardHeading = (props: HeadingProps) => {
  return (
    <Heading as="h2" fontSize="lg" fontWeight="medium" mb="2" {...props} />
  );
};

export const CardBody = (props: FlexProps) => {
  return <Flex flex="1" p={{ base: 3, md: 5 }} {...props} />;
};

export const CardFooter = (props: FlexProps) => {
  return (
    <Flex
      bg="customgray.bglight"
      borderBottomRadius="xl"
      borderTopColor="rgba(0, 0, 0, 0.10)"
      borderTopWidth="1px"
      px={{ base: 3, md: 5 }}
      py="4"
      {...props}
    />
  );
};
