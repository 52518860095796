import { useCallback, useRef, useState } from 'react';
import type {
  DecoratedQuickCreateEntryJob,
  Prompt,
} from '@/pages/QuickCreate/types';
import { createDecoratedQuickCreateEntryJob } from '@/pages/QuickCreate/utils/createDecoratedQuickCreateEntry';
import {
  type Base64FileInput,
  QuickEntriesJobType,
} from '@/types/gql.generated';
import { getLocalTimeZone } from '@/utils/dates';
import type { QueryError } from '@/utils/errors';
import { isPDF, toBase64 } from '@/utils/file';
import { useCreateQuickEntriesJobFromFile } from './useCreateQuickEntriesJobFromFile';
import { useCreateQuickEntriesJobFromPrompt } from './useCreateQuickEntriesJobFromPrompt';
import { usePollQuickEntriesJobsStatus } from './usePollQuickEntriesJobStatus';

type Options = {
  onSuccess?: (job: DecoratedQuickCreateEntryJob) => void;
  onError?: (err: Error | QueryError) => void;
};

export const useCreateQuickEntries = (promptType: QuickEntriesJobType) => {
  const promptMutation = useCreateQuickEntriesJobFromPrompt();
  const fileMutation = useCreateQuickEntriesJobFromFile();
  const pollQuery = usePollQuickEntriesJobsStatus();
  const [isPending, setPending] = useState(false);
  const canceledRef = useRef(false);

  const createQuickEntries = useCallback(
    async (prompt: Prompt, instructions: string, options?: Options) => {
      const timeZone = getLocalTimeZone();

      const onCreateJobSuccess = async (jobId: string) => {
        if (canceledRef.current) {
          // job was canceled in between creating the job and polling its status.
          // this is unlikely to happen but we should handle it to prevent
          // unnecessary polling.
          return;
        }
        try {
          const job = await pollQuery.pollUntilJobComplete(jobId);
          const decoratedJob = createDecoratedQuickCreateEntryJob(job);

          if (!canceledRef.current) {
            // job was canceled while polling - ignore the response
            options?.onSuccess?.(decoratedJob);
          }
        } catch (err) {
          if (!canceledRef.current) {
            // job was canceled while polling - ignore the error
            options?.onError?.(err as Error | QueryError);
          }
        } finally {
          setPending(false);
        }
      };

      const onCreateJobError = (err: QueryError) => {
        if (!canceledRef.current) {
          options?.onError?.(err);
          setPending(false);
        }
      };

      const mutateOptions = {
        onSuccess: onCreateJobSuccess,
        onError: onCreateJobError,
      };

      setPending(true);
      canceledRef.current = false;

      if (promptType === QuickEntriesJobType.File) {
        if (isPDF(prompt.file)) {
          const data = await toBase64(prompt.file);

          fileMutation.mutate(
            {
              base64File: {
                mimetype: 'application/pdf',
                filename: prompt.file.name,
                data: data.replace('data:application/pdf;base64,', ''),
              },
              instructions,
              timeZone,
            },
            mutateOptions
          );
        } else {
          fileMutation.mutate(
            { file: prompt.file, timeZone, instructions },
            mutateOptions
          );
        }
      } else if (prompt.base64) {
        const file: Base64FileInput = {
          data: prompt.base64,
          filename: 'screenshot.png',
          mimetype: 'image/png',
        };
        fileMutation.mutate({ base64File: file, timeZone }, mutateOptions);
      } else {
        promptMutation.mutate({ prompt: prompt.html, timeZone }, mutateOptions);
      }
    },
    [promptMutation, fileMutation, promptType, pollQuery]
  );

  const isError =
    promptMutation.isError || fileMutation.isError || !!pollQuery.error;
  const error = promptMutation.error || fileMutation.error || pollQuery.error;

  const reset = () => {
    canceledRef.current = true;
    setPending(false);
    pollQuery.reset();
    promptMutation.reset();
    fileMutation.reset();
  };

  return { createQuickEntries, reset, isPending, isError, error };
};
