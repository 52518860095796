const env = process.env;
const isProdEnv = process.env.NODE_ENV === 'production';
const isPreviewDeploy = env.VITE_CF_IS_PREVIEW_DEPLOY === '1';
const isLocalProdBuild = env.VITE_LOCAL_PROD_BUILD === '1';

const basePath = `${window.location.protocol}//${window.location.host}`;

export const config = {
  env: env.NODE_ENV || 'development',

  release: env.VITE_RELEASE_COMMIT_SHA || 'unknown',

  isPreviewDeploy,

  /** This is a production build running locally via the `npm preview` command */
  isLocalProdBuild,

  version: env.VITE_NPM_PACKAGE_VERSION || '0.0.0',

  basePath,

  marketingUrl: env.VITE_MARKETING_URL || '',

  docsUrl: env.VITE_DOCS_URL || '',

  supportEmail: 'support@agendahero.com',

  defaultScheduleBanner:
    'https://imagedelivery.net/BBGXExVVwzH6wQtxK9DCXQ/7c3b44ea-e79a-4ca5-d1cd-751ae9084c00',

  api: {
    url: env.VITE_API_URL || '',
  },

  server: {
    url: env.VITE_SERVER_URL || '',
    pr: '.pr.agendahero.com',
  },

  websockets: {
    url: env.VITE_WEBSOCKET_URL || '',
  },

  sentry: {
    enabled: isProdEnv && !isPreviewDeploy,
    dsn: env.VITE_SENTRY_DSN || '',
  },

  amplitude: {
    enabled: Boolean(env.VITE_AMPLITUDE_SDK_KEY),
    key: env.VITE_AMPLITUDE_SDK_KEY || '',
  },

  configCat: {
    key: env.VITE_CONFIG_CAT_SDK_KEY || '',
  },

  logrocket: {
    enabled: isProdEnv && !isPreviewDeploy,
    key: 'oocphd/agendahero',
  },

  googleAuth: {
    clientId: env.VITE_GOOGLE_CLIENT_ID || '',
  },

  googleCloud: {
    apiKey: env.VITE_GOOGLE_CLOUD_API_KEY || '',
  },

  magic: {
    chromeExtensionUrl:
      'https://chromewebstore.google.com/detail/agenda-hero-for-chrome/fglfichcambfnfmcjmppcnojneccedne',
  },

  feathery: {
    sdkKey: env.VITE_FEATHERY_SDK_KEY || '',
  },

  intercom: {
    appId: env.VITE_INTERCOM_APP_ID || '',
  },
} as const;
