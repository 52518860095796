import { Flex, type FlexProps } from '@/ui';
import { useIsColumnLayout } from '../../hooks/useIsColumnLayout';

export const Panel = (props: FlexProps) => {
  const { isColumnLayout } = useIsColumnLayout();

  return (
    <Flex
      direction="column"
      flex="1"
      px={{ base: 4, md: 10 }}
      py={{ base: 4, md: 6 }}
      _first={{
        pr: isColumnLayout ? undefined : 0,
        pb: isColumnLayout ? 0 : undefined,
      }}
      _last={{
        pl: isColumnLayout ? undefined : 0,
        pt: isColumnLayout ? 0 : undefined,
      }}
      {...props}
    />
  );
};
