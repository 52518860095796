import { ChevronDownIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useCurrentUserContext } from '@/contexts';
import { useToast } from '@/hooks/useToast';
import { i18n } from '@/i18n';
import { ConfirmDialog, LeaveConfirmation } from '@/pages/Schedule/components';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { ScheduleCollaborators } from '@/pages/Schedule/hooks';
import { Role } from '@/types/gql.generated';
import {
  Avatar,
  Box,
  Button,
  Flex,
  type FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useDisclosure,
} from '@/ui';
import { useTransferOwnership } from '../../../hooks/useTransferOwnership';

type Props = Omit<FlexProps, 'role'> & {
  collaborator: ScheduleCollaborators[number];
  onRemove: (user: ScheduleCollaborators[number]) => void;
  onChangeRole: (user: ScheduleCollaborators[number], role: Role) => void;
};

const roleLabelMap: Record<Role, string | null> = {
  [Role.Owner]: i18n.t('shareModal:owner'),
  [Role.Editor]: i18n.t('shareModal:editor'),
  [Role.Viewer]: i18n.t('shareModal:viewer'),
  [Role.Public]: null,
};

export const UserRow = ({
  collaborator,
  onRemove,
  onChangeRole,
  ...flexProps
}: Props) => {
  const { schedule, isOwnerRole } = useScheduleContext();
  const { currentUser } = useCurrentUserContext();
  const toast = useToast();
  const { t } = useTranslation('shareModal');
  const { transferOwnership, isPending: isTransferringOwnership } =
    useTransferOwnership();
  const isMe = currentUser?.id === collaborator.id;
  const isOwner = collaborator.role === Role.Owner;

  const {
    isOpen: isLeaveConfirmOpen,
    onOpen: openLeaveConfirm,
    onClose: closeLeaveConfirm,
  } = useDisclosure();

  const {
    isOpen: isRemoveConfirmOpen,
    onOpen: openRemoveConfirm,
    onClose: closeRemoveConfirm,
  } = useDisclosure();

  const {
    isOpen: isTransferConfirmOpen,
    onOpen: openTransferConfirm,
    onClose: closeTransferConfirm,
  } = useDisclosure();

  return (
    <>
      <LeaveConfirmation
        isOpen={isLeaveConfirmOpen}
        onCancel={closeLeaveConfirm}
      />

      <ConfirmDialog
        confirmButtonText={t('confirm_remove.confirm')}
        isOpen={isRemoveConfirmOpen}
        title={t('confirm_remove.title', {
          user: collaborator.name || collaborator.email,
          schedule: schedule?.title || t('untitled_schedule', { ns: 'common' }),
        })}
        onCancel={closeRemoveConfirm}
        onConfirm={() => onRemove(collaborator)}
      />

      <ConfirmDialog
        confirmButtonText={t('confirm_transfer.confirm')}
        isLoading={isTransferringOwnership}
        isOpen={isTransferConfirmOpen}
        message={t('confirm_transfer.message')}
        title={t('confirm_transfer.title', {
          user: collaborator.name || collaborator.email,
        })}
        onCancel={closeTransferConfirm}
        onConfirm={() => {
          transferOwnership(collaborator.id, {
            onError: () => toast.error(t('transfer_error_toast')),
            onSuccess: () => {
              closeTransferConfirm();
              toast.notify(t('transfer_success_toast'));
            },
          });
        }}
      />

      <Flex align="center" {...flexProps}>
        <Avatar
          mr="4"
          name={collaborator.name || collaborator.email || ''}
          size="sm"
          src={collaborator.avatar ?? undefined}
        />
        <Box flex="1" lineHeight="normal">
          <Text fontWeight="medium">
            {collaborator.name || collaborator.email} {isMe && t('you')}
          </Text>
          {collaborator.name && (
            <Text color="customgray.mid" fontSize="sm">
              {collaborator.email}
            </Text>
          )}
        </Box>
        {isOwner ? (
          <Text fontWeight="medium">{roleLabelMap[collaborator.role]}</Text>
        ) : (
          <Menu isLazy placement="bottom-end">
            <MenuButton
              as={Button}
              colorScheme="dark"
              fontSize="md"
              mr="-4"
              rightIcon={<ChevronDownIcon />}
              size="sm"
              variant="ghost"
            >
              {roleLabelMap[collaborator.role]}
            </MenuButton>
            <MenuList minW="9rem">
              {/* Editors can't change their own permissions */}
              {!isMe && (
                <>
                  <MenuOptionGroup
                    type="radio"
                    value={collaborator.role}
                    onChange={(value) => {
                      onChangeRole(collaborator, value as Role);
                    }}
                  >
                    <MenuItemOption value={Role.Viewer}>
                      {t('viewer')}
                    </MenuItemOption>
                    <MenuItemOption value={Role.Editor}>
                      {t('editor')}
                    </MenuItemOption>
                  </MenuOptionGroup>
                  <MenuDivider />
                </>
              )}
              {isMe ? (
                <MenuItem onClick={openLeaveConfirm}>
                  {t('leave_schedule')}
                </MenuItem>
              ) : (
                <MenuItem onClick={openRemoveConfirm}>
                  {t('remove_access')}
                </MenuItem>
              )}
              {/* Only owners can transfer ownership to another member */}
              {isOwnerRole && (
                <MenuItem onClick={openTransferConfirm}>
                  {t('transfer_owner')}
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </Flex>
    </>
  );
};
