import type { DateTime } from 'luxon';
import { TIME_ZONE_UTC } from '@/constants';
import { datePartInZone } from '@/utils/dates';

/**
 * All day events use the UTC time zone instead of the schedule's time zone.
 * The until date is in the schedule's time zone at 11:59pm on the selected day.
 * This date represented in UTC could push the date forwards a day, which will
 * cause the event to appear on the wrong day.
 *
 * What we want to do is make sure the until date is 11:59pm on the selected date
 * in UTC, not in the schedule's zone. We keep local time because we want to
 * preserve 11:59pm while converting the date part.
 */
export const getUntilDateForAllDayEntry = (untilDate: DateTime): DateTime => {
  return untilDate.setZone(TIME_ZONE_UTC, {
    keepLocalTime: true,
  });
};

/**
 * Until dates on a timed entry should be 11:59pm on the selected day
 * in the schedule's time zone.
 */
export const getUntilDateTimeForTimedEntry = (
  untilDate: DateTime,
  scheduleTimeZone: string
): DateTime => {
  return datePartInZone(untilDate, scheduleTimeZone).endOf('day');
};

/**
 * Remove the time part from the until date if this is an all day event.
 * This is consistent with how Google represents until dates.
 */
export const getUntilDateStringForAllDayEntry = (ruleStr: string): string => {
  return ruleStr.replace(/(UNTIL=\d+)T\d+Z/, '$1');
};
