import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosRefresh } from 'react-icons/io';
import { IoChevronDown, IoChevronUp } from 'react-icons/io5';
import { useToast } from '@/hooks/useToast';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { Box, Button, Collapse, Flex, Text, useDisclosure } from '@/ui';
import { ConfirmDialog } from '../../ConfirmDialog';
import { useGenerateScheduleFeedSecret } from '../hooks/useGenerateScheduleFeedSecret';

export const Advanced = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { t } = useTranslation('addToCalendarModal');
  const { schedule } = useScheduleContext();
  const { notify } = useToast();

  const { generateSecret, isPending: isGeneratingSecret } =
    useGenerateScheduleFeedSecret();

  const {
    isOpen: isResetConfirmOpen,
    onOpen: openResetConfirm,
    onClose: closeResetConfirm,
  } = useDisclosure();

  return (
    <>
      <ConfirmDialog
        confirmButtonText={t('confirm_refresh.refresh')}
        isLoading={isGeneratingSecret}
        isOpen={isResetConfirmOpen}
        message={t('confirm_refresh.message')}
        title={t('confirm_refresh.title', {
          schedule: schedule?.title || t('untitled_schedule', { ns: 'common' }),
        })}
        onCancel={closeResetConfirm}
        onConfirm={() => {
          generateSecret({
            onSuccess: () => notify(t('confirm_refresh.success_toast')),
          });
          closeResetConfirm();
        }}
      />

      <Flex
        align="center"
        as={Button}
        colorScheme="dark"
        ml="-3"
        size="sm"
        textAlign="left"
        variant="ghost"
        w="calc(100% + 28px)"
        onClick={() => setShowAdvanced((value) => !value)}
      >
        <Text flex="1" fontWeight="medium">
          {t('advanced')}
        </Text>

        {showAdvanced ? <IoChevronUp size="18" /> : <IoChevronDown size="18" />}
      </Flex>

      <Box sx={{ '> div': { width: '100%' } }} w="100%">
        <Collapse in={showAdvanced}>
          <Flex mt="4" w="100%">
            <Box flex="1">
              <Text
                color="customgray.mid"
                fontSize="sm"
                lineHeight="1.2"
                w="200px"
              >
                {t('refresh_instructions')}
              </Text>
            </Box>
            <Button
              leftIcon={<IoIosRefresh />}
              variant="secondary"
              onClick={openResetConfirm}
            >
              {t('refresh_button')}
            </Button>
          </Flex>
        </Collapse>
      </Box>
    </>
  );
};
