import pick from 'lodash.pick';
import { type ReactNode, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import { createContext } from '@/utils';
import { useCloneStatus } from './useCloneStatus';
import { useMessagesPanelState } from './useMessagesPanelState';
import { useSidebarState } from './useSidebarState';

type EntryModalState =
  | undefined
  | (ReturnType<typeof useSidebarState> &
      ReturnType<typeof useCloneStatus> & {
        messagesPanel: ReturnType<typeof useMessagesPanelState>;
      } & {
        isOpen: boolean;
        slideSidebarUpFromBottom: boolean;
      });

export const EntryModalContext = createContext<EntryModalState>(
  undefined,
  'EntryModalContext'
);

type Props = {
  children: ReactNode;
};

export const EntryModalContextProvider = ({ children }: Props) => {
  const isOpen = useDraftEntryStore((state) => !!state.draftEntry);
  const sidebarState = useSidebarState(isOpen);
  const cloneStatus = useCloneStatus();
  const messagesPanel = useMessagesPanelState();

  const { draftEntry, draftInstance, initOptions } = useDraftEntryStore(
    (state) => pick(state, ['draftEntry', 'draftInstance', 'initOptions'])
  );

  // reset states on close
  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      messagesPanel.reset();
      sidebarState.reset();
    }
  }, [prevIsOpen, isOpen, messagesPanel, sidebarState]);

  useEffect(() => {
    if (initOptions?.launchAction === 'create-message' && draftEntry) {
      messagesPanel.createNewMessage(draftEntry, draftInstance);
      sidebarState.openSidebar('messages');
    }
    // eslint-disable-next-line
  }, [initOptions, draftEntry, draftInstance]);

  const value: EntryModalState = {
    ...cloneStatus,
    ...sidebarState,
    messagesPanel,
    isOpen,
  };

  return (
    <EntryModalContext.Provider value={value}>
      {children}
    </EntryModalContext.Provider>
  );
};
