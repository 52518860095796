import type { ScheduleCollaborators } from '@/pages/Schedule/hooks';
import type { Role } from '@/types/gql.generated';
import { Flex, type FlexProps } from '@/ui';
import { UserRow } from './UserRow';

type Props = FlexProps & {
  collaborators: ScheduleCollaborators;
  onRemove: (user: ScheduleCollaborators[number]) => void;
  onChangeRole: (user: ScheduleCollaborators[number], role: Role) => void;
};

export const UserList = ({
  collaborators,
  onRemove,
  onChangeRole,
  ...props
}: Props) => {
  return (
    <Flex
      direction="column"
      gap="3"
      maxH={{ base: '100%', md: '368px' }}
      ml="-4"
      overflowX="hidden"
      overflowY="auto"
      px="4"
      w="calc(100% + 28px)"
      {...props}
    >
      {collaborators.map((collaborator) => (
        <UserRow
          collaborator={collaborator}
          data-testid="user-row"
          key={collaborator.id}
          onChangeRole={onChangeRole}
          onRemove={onRemove}
        />
      ))}
    </Flex>
  );
};
