import * as amplitude from '@amplitude/analytics-browser';
import LogRocket from 'logrocket';
import { useCallback } from 'react';
import { config } from '@/config';
import type { DecoratedProfile } from '@/contexts';
import { Sentry } from '@/lib';
import type { OnboardingFlow } from '@/pages/Schedule/components/OnboardingModal';
import type { DraftComposer } from '@/pages/Schedule/stores';
import type {
  PlatformTypes,
  ScheduleHeaderId,
  ScheduleType,
} from '@/types/gql.generated';
import type { ConversionType } from '@/utils/convertEntry';
import { getClientName } from '@/utils/getClientName';

type Page =
  | 'schedule:list'
  | 'schedule'
  | 'schedule:prompt'
  | 'schedule:members'
  | 'schedule:messages'
  | 'schedule:snippets'
  | 'schedule:snippet'
  | 'schedule:sources'
  | 'entry'
  | 'profile'
  | 'login'
  | 'register'
  | 'reset-password-request'
  | 'reset-password'
  | 'magic'
  | 'magic:detail';

// key = event name, value = event properties
type Events = {
  pageview: {
    page: Page;
    href: string;
  };
  'util:focus': never;
  'dashboard:click cta-magic': never;
  'dashboard:click cta-schedule': never;
  'schedule:create': {
    type: ScheduleType;
  };
  'schedule:click create blank schedule': never;
  'schedule:click create family schedule': never;
  'schedule:share': never;
  'schedule:click copy-share-link': never;
  'schedule:signup-cta': never;
  'schedule:click auth-link': {
    scheduleId: string;
    action: 'login' | 'register';
  };
  'schedule:change': never;
  'schedule:focus-update': never;
  'schedule:focus-clear': never;
  'schedule:click follow-schedule': {
    scheduleId: string;
    isAuthenticated: boolean;
  };
  'schedule:cta follow-schedule success': {
    scheduleId: string;
  };
  'schedule:click unfollow-schedule': {
    scheduleId: string;
  };
  'schedule:click copy-schedule': {
    scheduleId: string;
  };
  'schedule:click copy-schedule success': {
    scheduleId: string;
  };
  'schedule:click follow-entry': {
    scheduleId: string;
    isAuthenticated: boolean;
    variant: string;
  };
  'schedule:click follow-entry success': {
    scheduleId: string;
  };
  'schedule:click copy-link': {
    scheduleId: string;
    entryId: string;
  };
  'schedule:click change header': {
    headerId: ScheduleHeaderId;
  };
  'schedule:click add header': never;
  'schedule:click remove header': never;
  'prompt:click try-sample': never;
  'prompt:click back': never;
  'prompt:click create': {
    promptLength: number;
  };
  'prompt:click enter-schedule': never;
  'prompt:receive error': {
    code: string | null;
  };
  'entry:new': {
    composer: DraftComposer;
  };
  'entry:create': {
    composer: DraftComposer;
  };
  'entry:update': never;
  'entry:delete': never;
  'entry:delete-instance': never;
  'entry:hide': never;
  'entry:unhide': never;
  'entry:click': never;
  'ai-add:convert content': {
    conversionType: ConversionType | 'unknown';
  };
  'ai-add:click add to schedule': never;
  'ai-add:click entry edit': never;
  'ai-add:click entry delete': never;
  'ai-add:click entry messages': never;
  'ai-add:click add manually': never;
  'ai-add:inline edit': never;
  'update-banner:view': never;
  'update-banner:reload': never;
  'source:create': {
    type: 'ics' | 'google' | 'agendahero';
  };
  'source:update': never;
  'source:delete': never;
  'feed-export:click': {
    medium: 'apple' | 'google' | 'outlook' | 'other';
  };
  'snippet:create': never;
  'onboarding:next-step': {
    flow: OnboardingFlow | null;
    index: number;
  };
  'onboarding:prev-step': {
    flow: OnboardingFlow | null;
    index: number;
  };
  'onboarding:complete': {
    flow: OnboardingFlow | null;
  };
  'onboarding:start-flow': {
    flow: OnboardingFlow | null;
  };
  'onboarding:close': {
    flow: OnboardingFlow | null;
  };
  'quick-create:click schedules': {
    from: 'home' | 'settings' | 'shared';
  };
  'quick-create:click example': never;
  'quick-create:click generate': never;
  'quick-create:make edit': {
    field: 'emoji' | 'title' | 'datetime' | 'location' | 'description';
  };
  'quick-create:click send': never; // Confirm send email
  'quick-create:click send to agenda hero': never;
  'quick-create:click send to google-integration': never; // Click the google integration option
  'quick-create:click add to calendar': {
    platform: PlatformTypes;
    isPreferred: boolean;
    hasPreferred: boolean;
  };
  'quick-create:click add to preferred': {
    platform: PlatformTypes;
  };
  'quick-create:click add-many': {
    platform: PlatformTypes;
    path: string;
  };
  'quick-create:click again': never;
  'quick-create:click share': {
    channel: 'copy' | 'facebook' | 'linkedin' | 'x';
  };
  'quick-create:click copy item link': never;
  'quick-create:convert content': {
    conversionType: ConversionType | 'unknown';
  };
  'quick-create:click details cta': {
    jobId: string;
  };
  'conflict-schedule-success:click share': {
    channel: 'copy' | 'native' | 'facebook' | 'linkedin' | 'twitter';
  };
  'lead-gate:show': {
    reason: string | null;
  };
  'lead-gate:success': {
    reason: string | null;
  };
  'web-view:show warning': never;
  'web-view:click browser': never;
  'web-view:click dismiss': never;
};

type UserProperties = {
  scheduleCount: number;
  aiScheduleCount: number;
};

const DISABLED = window.location.search.includes('analyticsDisabled=1');

export const useAnalytics = () => {
  const { pathname, search } = window.location;

  const init = useCallback(() => {
    if (DISABLED) {
      return;
    }

    if (config.amplitude.enabled) {
      amplitude.init(config.amplitude.key, {
        defaultTracking: true,
      });
    }

    if (config.logrocket.enabled) {
      LogRocket.init(config.logrocket.key, {
        release: `${config.version}-${config.release}`,
        shouldParseXHRBlob: true,
      });
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
      });
    }
  }, []);

  const identify = useCallback((user: NonNullable<DecoratedProfile>) => {
    if (DISABLED) {
      return;
    }

    const { id, email, name, status } = user;

    if (config.amplitude.enabled) {
      amplitude.setUserId(id);

      const event = new amplitude.Identify();
      event.set('id', id);
      if (email) {
        event.set('email', email);
      }
      event.set('status', status);
      amplitude.identify(event);
    }

    if (config.logrocket.enabled) {
      const user: Record<string, string | number | boolean> = {
        name: name ?? '',
        status,
      };
      if (email) {
        user.email = email;
      }
      LogRocket.identify(id, user);
    }
  }, []);

  const trackEvent = useCallback(
    <E extends keyof Events>(
      eventName: E,
      ...args: Events[E] extends never ? [] : [properties: Events[E]]
    ) => {
      if (DISABLED) {
        return;
      }

      const [properties] = args;
      const client = getClientName();

      amplitude.track(eventName, {
        ...properties,
        env: config.env,
        client: client,
      });
    },
    []
  );

  const trackPageView = useCallback(
    (page: Page, properties?: Record<string, unknown>) =>
      trackEvent('pageview', {
        ...properties,
        page,
        href: `${pathname}${search}`,
      }),
    [trackEvent, pathname, search]
  );

  const incrementUserProperty = useCallback(
    <P extends keyof UserProperties>(propertyName: P, amount = 1) => {
      if (!DISABLED) {
        const event = new amplitude.Identify();
        event.add(propertyName, amount);
        amplitude.identify(event);
      }
    },
    []
  );

  return {
    init,
    identify,
    trackPageView,
    trackEvent,
    incrementUserProperty,
  };
};
