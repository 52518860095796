import type { DateTime } from 'luxon';
import type {
  EntryFragment,
  MinimumEntryFieldsFragment,
} from '@/fragments/entry.generated';
import type { Entry, Recurrence, RsvpInfo } from '@/types/gql.generated';
import type { GetScheduleEntriesQuery } from '../contexts/EntriesContext/hooks/useEntries.generated';

type Message = Required<
  GetScheduleEntriesQuery['getSchedule']['entries'][number]
>['messages'][number];

export type DecoratedMessage = Omit<Message, 'instance'> & {
  instance: DateTime;
};

export type DecoratedRecurrence = Omit<Recurrence, 'startDate' | 'endDate'> & {
  startDate: DateTime;
  endDate: DateTime;
  // 👇 Relevant to non-recurring multi-day entries
  sequence?: number;
  isFirstInSequence?: boolean;
  isLastInSequence?: boolean;
};

/**
 * A `DecoratedEntry` requires at least these props to create.
 * These props are necessary to expand the entry into individual instances.
 */
export type MinimumDecoratedEntryInputProps = MinimumEntryFieldsFragment & {
  messages?: Message[];
};

/**
 * A `DecoratedEntry` has at least these props
 */
export type MinimumDecoratedEntryProps = Pick<
  Entry,
  'id' | 'contextId' | 'timeZone' | 'title'
> & {
  exclusions: DateTime[];
  hidden: DateTime[];
  recurrences: DecoratedRecurrence[];
  createdAt: DateTime;
  messages?: DecoratedMessage[];
};

/**
 * A `DecoratedInstance` has at least these props
 */
export type MinimumDecoratedInstanceProps = Omit<
  MinimumDecoratedEntryProps,
  'recurrences' | 'hidden' | 'exclusions'
> &
  Omit<Recurrence, 'startDate' | 'endDate'> & {
    parentId: string;
    startDate: DateTime;
    endDate: DateTime;
    isHidden: boolean;
    messages?: DecoratedMessage[];
    // 👇 Relevant to non-recurring multi-day entries
    sequence?: number;
    isFirstInSequence?: boolean;
    isLastInSequence?: boolean;
  };

/**
 * Create a `DecoratedEntry` type from some subset of `Entry`
 */
export type CreateDecoratedEntryType<
  T extends MinimumDecoratedEntryInputProps,
> = Omit<
  T,
  'exclusions' | 'recurrences' | 'createdAt' | 'hidden' | 'messages'
> &
  MinimumDecoratedEntryProps;

/**
 * Create a `DecoratedInstance` from a `DecoratedEntry`
 */
export type CreateDecoratedInstanceType<E extends MinimumDecoratedEntryProps> =
  Omit<E, 'recurrences' | 'hidden' | 'exclusions' | 'messages'> &
    MinimumDecoratedInstanceProps;

/**
 * These are the default `DecoratedEntry` and `DecoratedInstance` types used
 * throughout the schedule tab. They assume a query fetched instances
 * using the `EntryFragment`.
 */
// TODO: Make sure only the schedule tab is using DecoratedEntry now that the
// messages property couples DecoratedEntry to the query that powers the tab.
// Each tab should create their own DecoratedEntry type based on the query that
// powers the tab. See the snippets index view for prior art.
export type DecoratedEntry = CreateDecoratedEntryType<
  EntryFragment & {
    rsvpInfo?: RsvpInfo;
  }
>;
export type DecoratedInstance = CreateDecoratedInstanceType<DecoratedEntry>;

export const isDecoratedInstance = (
  instance?: unknown
): instance is DecoratedInstance => {
  return !!instance && typeof instance === 'object' && 'parentId' in instance;
};
