import { tableAnatomy } from '@chakra-ui/anatomy';
import { mode } from '@chakra-ui/theme-tools';
import { createMultiStyleConfigHelpers } from '@/ui';

const { definePartsStyle } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const variantSpreadsheet = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      backgroundColor: mode(`${c}.100`, `${c}.700`)(props),
    },
    td: {
      borderBottom: '1px',
      borderRight: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

export const Table = {
  variants: {
    spreadsheet: variantSpreadsheet,
  },
};
