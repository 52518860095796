import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import { createScheduleHeaderImagesQueryKey } from '@/utils/queryKeys';
import type { HeaderImage } from '../types';
import type {
  GetScheduleHeaderImagesQuery,
  GetScheduleHeaderImagesQueryVariables,
} from './useGetScheduleHeaderImages.generated';

const query = gql`
  query GetScheduleHeaderImages {
    getScheduleHeaderImages {
      id
      keywords
      imageBaseUrl
    }
  }
`;

const selector = (data: GetScheduleHeaderImagesQuery): HeaderImage[] => {
  return data.getScheduleHeaderImages;
};

export const useGetScheduleHeaderImages = () => {
  const { data, isLoading, error } = useQuery<
    GetScheduleHeaderImagesQuery,
    QueryError,
    HeaderImage[]
  >({
    queryKey: createScheduleHeaderImagesQueryKey(),
    select: selector,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryFn: () => {
      return gqlClient.request<
        GetScheduleHeaderImagesQuery,
        GetScheduleHeaderImagesQueryVariables
      >(query);
    },
  });

  return {
    data: data ?? [],
    isLoading,
    error,
  };
};
