import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { Text, type TextProps } from '@/ui';

export const ScheduleTimeZone = (props?: TextProps) => {
  const { timeZone } = useScheduleContext();
  const { t } = useTranslation('actionBar');

  const { short, long } = useMemo(() => {
    const date = DateTime.local({ zone: timeZone });
    const short = date.toFormat('ZZZZ');
    const long = date.toFormat('ZZZZZ');
    return { short, long };
  }, [timeZone]);

  return (
    <Text
      color="customgray.mid"
      fontSize="sm"
      fontWeight="medium"
      title={long}
      {...props}
    >
      {t('times_in_zone', { zone: short })}
    </Text>
  );
};
