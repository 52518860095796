import { type BoxProps, Flex, Radio, forwardRef, type RadioProps } from '@/ui';

type Props = Omit<BoxProps, 'onClick'> & {
  radioProps?: RadioProps;
  isChecked: boolean;
  onClick: () => void;
};

export const Row = forwardRef<Props, 'div'>(
  (
    { isChecked, onClick, style, radioProps = {}, onPointerDown, ...rest },
    ref
  ) => {
    const { colorScheme: radioColorScheme, ...restRadioProps } = radioProps;

    return (
      <Flex
        align="center"
        h="34px"
        ref={ref}
        style={style}
        w="100%"
        onPointerDown={onPointerDown}
      >
        <Radio
          colorScheme={radioColorScheme ?? 'blackAlpha'}
          mr="2"
          {...restRadioProps}
          isChecked={isChecked}
          inputProps={{
            onKeyDown: (event) => {
              if (event.key === ' ') {
                onClick();
              }
            },
          }}
          onClick={onClick}
        />

        <Flex
          align="center"
          bg="white" // White background so colors come through properly even in dark modes
          borderRadius="8px"
          borderWidth="0"
          cursor="pointer"
          flex="1"
          fontSize="14"
          h="100%"
          overflow="hidden"
          role="button"
          transition="background-color 0.2s ease"
          onClick={onClick}
          onKeyDown={(event) => {
            if (event.key === ' ') {
              onClick();
            }
          }}
        >
          <Flex
            align="center"
            borderColor="transparent"
            borderWidth="1px"
            flex="1"
            h="100%"
            px="3"
            w="100%"
            {...rest}
          />
        </Flex>
      </Flex>
    );
  }
);
