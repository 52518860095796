import { Button, type ButtonProps, forwardRef, useToken } from '@/ui';

type Props = ButtonProps & {
  placeholder?: string;
};

export const EntryModalFieldButton = forwardRef(
  ({ placeholder, children, ...props }: Props, ref) => {
    const [brandColor] = useToken('colors', ['brand.500']);

    return (
      <Button
        color={placeholder ? 'customgray.alsolight' : undefined}
        flex="1"
        justifyContent="flex-start"
        ref={ref}
        variant="notion"
        _focusVisible={{
          outline: `1px solid ${brandColor}`,
        }}
        {...props}
      >
        {placeholder ? <span>{placeholder}</span> : children}
      </Button>
    );
  }
);
