import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useSchedulesCache } from '@/hooks/useSchedulesCache';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  LeaveScheduleMutation,
  LeaveScheduleMutationVariables,
} from './useLeaveSchedule.generated';

const query = gql`
  mutation LeaveSchedule($scheduleId: ID!) {
    leaveSchedule(scheduleId: $scheduleId)
  }
`;

export const useLeaveSchedule = () => {
  const { scheduleId } = useScheduleContext();
  const { removeSchedule } = useSchedulesCache();

  const { mutate, ...rest } = useMutation<
    LeaveScheduleMutation,
    QueryError,
    LeaveScheduleMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<LeaveScheduleMutation, LeaveScheduleMutationVariables>(
        query,
        variables
      ),
    onSuccess: () => {
      removeSchedule(scheduleId);
    },
  });

  const leaveSchedule = (
    options: MutateOptions<
      LeaveScheduleMutation,
      QueryError,
      LeaveScheduleMutationVariables
    >
  ) => {
    return mutate(
      { scheduleId },
      {
        ...options,
        onSuccess: (...args) => {
          // TODO timeout ensures state is updated before navigating
          setTimeout(() => options.onSuccess?.(...args));
        },
      }
    );
  };

  return {
    ...rest,
    leaveSchedule,
  };
};
