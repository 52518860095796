import {
  type Options,
  getSigninUrl,
} from '@/components/auth/utils/googleCalendar';
import { CAMPAIGN_SESSION_STORAGE_KEY } from '@/constants';
import type { DecoratedProfile } from '@/contexts';
import { wrapWebViewEscape } from '@/lib/webViewUtils';
import { getLocalTimeZone } from '@/utils/dates';

export const getAuthUrl = (
  currentUser: DecoratedProfile | undefined,
  options: Options,
  quickCreateJobId: string | undefined,
  queryParams: Record<string, string> = {}
): string => {
  const redirectParams: Record<string, string> = {
    ...queryParams,
  };
  const state: Record<string, string | boolean | number> = {
    app: 'magic',
    timeZone: getLocalTimeZone(),
  };

  if (quickCreateJobId) {
    redirectParams.e = quickCreateJobId;
  }

  const campaign = sessionStorage.getItem(CAMPAIGN_SESSION_STORAGE_KEY);
  if (campaign) {
    state.campaign = campaign;
  }

  const query = new URLSearchParams(location.search);
  Object.entries(redirectParams).forEach(([key, value]) => {
    query.append(key, value);
  });

  const search = query.size > 0 ? `?${query.toString()}` : '';
  const path = window.location.pathname;
  const redirectUrl = `${path}${search}`;
  const signinUrl = getSigninUrl(currentUser, redirectUrl, state, options);

  return wrapWebViewEscape(signinUrl);
};
