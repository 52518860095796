import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import type { PlatformTypes } from '@/types/gql.generated';
import type { DecoratedQuickCreateEntry } from '../types';

export type EntryExport = {
  entry: DecoratedQuickCreateEntry;
  externalLink: string;
};

export type PlatformExport = {
  platform: PlatformTypes;
  integrationId: string;
  calendarId: string;
  entryExports: EntryExport[];
};

type State = {
  addExport: (item: PlatformExport) => void;
  platformExports: PlatformExport[];
  reset: () => void;
};

const getStartDate = (entry: DecoratedQuickCreateEntry): Date => {
  return entry.startDate.toJSDate();
};

export const usePlatformExports = createWithEqualityFn<State>()(
  (set) => ({
    platformExports: [],
    addExport: (platformExport) => {
      set((state) => {
        const existing = state.platformExports.find((item) => {
          return (
            item.platform === platformExport.platform &&
            item.integrationId === platformExport.integrationId &&
            item.calendarId === platformExport.calendarId
          );
        });

        if (!existing) {
          return {
            platformExports: [...state.platformExports, platformExport],
          };
        }

        const newEntryExports = platformExport.entryExports.filter(
          (item) =>
            !existing.entryExports.some(
              (existingItem) => existingItem.entry.id === item.entry.id
            )
        );

        return {
          platformExports: state.platformExports.map((item) => {
            if (item.calendarId !== existing.calendarId) {
              return item;
            }

            return {
              ...platformExport,
              entryExports: [...existing.entryExports, ...newEntryExports].sort(
                (a, b) => {
                  const aStartAt = getStartDate(a.entry);
                  const bStartAt = getStartDate(b.entry);

                  return aStartAt > bStartAt ? 1 : -1;
                }
              ),
            };
          }),
        };
      });
    },
    reset: () => set({ platformExports: [] }),
  }),
  shallow
);
