import { useIsMagic } from '@/hooks/useIsMagic';
import { useLastTab } from '@/router/hooks/useLastTab';
import { Tabs as ChakraTabs, TabList } from '@/ui';
import { StyledNavLink } from './StyledNavLink';

export const Tabs = () => {
  const { setLastTab } = useLastTab();
  const { isMagic } = useIsMagic();
  const index = isMagic ? 0 : 1;

  return (
    <ChakraTabs index={index} variant="unstyled">
      <TabList gap="2">
        <StyledNavLink
          selectedBgColor="#fff"
          to="/magic"
          onClick={() => setLastTab('magic')}
        >
          Magic
        </StyledNavLink>
        <StyledNavLink
          selectedBgColor="#f4f2fa"
          to="/dashboard"
          onClick={() => setLastTab('dashboard')}
        >
          Schedules
        </StyledNavLink>
      </TabList>
    </ChakraTabs>
  );
};
