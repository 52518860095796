import type { ErrorExtensionsData } from '@/utils/errors';

export type Ingredient = 'category' | 'feed' | 'label';

export type SnippetInUse = {
  id: string;
  name: string;
};

export type SnippetsInUseErrorExtensionData = {
  snippets: SnippetInUse[];
};

export const isSnippetsInUseErrorExtensionData = (
  extensionData: ErrorExtensionsData | undefined
): extensionData is SnippetsInUseErrorExtensionData => {
  return !!extensionData && 'snippets' in extensionData;
};
