import type { DateTime } from 'luxon';
import type { ChangeEvent } from 'react';
import { type Options, RRule, type Weekday } from 'rrule';
import { TIME_ZONE_UTC } from '@/constants';
import { useTimeZonedDateUtils } from '@/hooks/useTimeZonedDateUtils';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type {
  DecoratedEntry,
  DecoratedRecurrence,
} from '@/pages/Schedule/types';
import { getDefaultWeekday, parseRRule } from '@/pages/Schedule/utils';
import type { Label } from '@/types/gql.generated';
import { applyTimeToDate, areDaysEqual, isMidnight } from '@/utils/dates';
import type { Category, RecurrenceOption } from '../../components';
import {
  getUntilDateForAllDayEntry,
  getUntilDateTimeForTimedEntry,
  getUntilDateStringForAllDayEntry,
} from './utils';

type UpdateEntryProps = Partial<Omit<DecoratedEntry, 'recurrences'>> &
  Partial<Omit<DecoratedRecurrence, 'rule'>> & {
    rrule?: Partial<Options> | null;
  };

export const useUpdateEntryHandlers = (
  entry: DecoratedEntry,
  onUpdate: (entry: DecoratedEntry) => void
) => {
  const { rule, startDate, endDate, isOnDay } = entry.recurrences[0];
  const { timeZone: scheduleTimeZone } = useScheduleContext();
  const { local } = useTimeZonedDateUtils();

  const { frequency, interval, byWeekday, untilDate } = parseRRule({
    rule,
    startDate,
    isOnDay,
    scheduleTimeZone,
    entryTimeZone: entry.timeZone,
  });

  const updateEntry = ({
    startDate,
    endDate,
    isOnDay,
    rrule,
    ...entryProps
  }: UpdateEntryProps) => {
    const recurrence = entry.recurrences[0];

    const newRecurrence: DecoratedRecurrence = {
      ...recurrence,
      startDate: startDate ?? recurrence.startDate,
      endDate: endDate ?? recurrence.endDate,
      isOnDay: isOnDay ?? recurrence.isOnDay,
    };

    if (rrule) {
      const newRRule = new RRule({
        ...RRule.parseString(newRecurrence.rule ?? ''),
        ...rrule,
      });

      const ruleStr = newRRule.toString();
      if (ruleStr) {
        newRecurrence.rule =
          // until dates for all day entries require special handling
          newRecurrence.isOnDay && newRRule.options.until
            ? getUntilDateStringForAllDayEntry(ruleStr)
            : ruleStr;
      }
    } else if (rrule === null) {
      newRecurrence.rule = null;
    }

    const newEntry: DecoratedEntry = {
      ...entry,
      ...entryProps,
      recurrences: [newRecurrence],
    };

    onUpdate(newEntry);
  };

  const onRecurrenceChange = (option: RecurrenceOption) => {
    const { freq, interval } = option.rule;

    // removing recurrence?
    if (freq === null) {
      updateEntry({ rrule: null });
      return;
    }

    let newEndDateTime = applyTimeToDate(startDate, endDate);
    if (isMidnight(newEndDateTime) || isOnDay) {
      newEndDateTime = newEndDateTime.plus({ days: 1 });
    }

    updateEntry({
      endDate: newEndDateTime,
      rrule: {
        freq,
        interval,
        bynweekday: [],
      },
    });
  };

  const onUntilDateChange = (untilDate: DateTime | null) => {
    if (untilDate) {
      const newUntilDate = isOnDay
        ? getUntilDateForAllDayEntry(untilDate)
        : untilDate;

      updateEntry({
        rrule: {
          until: newUntilDate.toJSDate(),
        },
      });
    } else {
      updateEntry({
        rrule: {
          until: null,
        },
      });
    }
  };

  const onAddTime = () => {
    const hour = local().hour;
    const newStartDate = startDate.set({ hour });
    const adjustedEndDate = endDate.minus({
      days: isOnDay ? 1 : 0,
    });
    const newEndDate = newStartDate.set({
      day: adjustedEndDate.day,
      month: adjustedEndDate.month,
      year: adjustedEndDate.year,
      hour: hour + 1,
    });

    const rrule = {
      until: untilDate
        ? getUntilDateTimeForTimedEntry(untilDate, scheduleTimeZone).toJSDate()
        : null,
    };

    updateEntry({
      timeZone: scheduleTimeZone,
      startDate: newStartDate,
      endDate: newEndDate,
      isOnDay: false,
      rrule,
    });
  };

  const onRemoveTime = () => {
    const newStartDate = startDate.startOf('day');
    const newEndDate = endDate.startOf('day').plus({ days: 1 });

    const rrule = {
      until: untilDate
        ? getUntilDateForAllDayEntry(untilDate).toJSDate()
        : null,
    };

    updateEntry({
      timeZone: TIME_ZONE_UTC,
      startDate: newStartDate,
      endDate: newEndDate,
      isOnDay: true,
      rrule,
    });
  };

  const onStartDateChange = (selectedStartDate: DateTime) => {
    const calculateEndOrUntilDate = (date: DateTime): DateTime => {
      const granularity = isOnDay ? 'days' : 'minutes';
      const duration = date.diff(startDate, granularity)[granularity];
      return newStartDateTime.plus({ [granularity]: duration });
    };

    // Start date is whatever was selected + the existing start date time parts
    const newStartDateTime = applyTimeToDate(selectedStartDate, startDate);

    // exit early for non-recurring entries
    if (frequency === null) {
      updateEntry({
        startDate: newStartDateTime,
        endDate: calculateEndOrUntilDate(endDate),
      });
      return;
    }

    const newUntilDateTime = untilDate
      ? calculateEndOrUntilDate(untilDate).toJSDate()
      : null;

    // Set the end date properly. The until date takes over in the UI but the end
    // date is still part of the `recurrence` object and needs to be accurate
    const newEndDateTime = isOnDay
      ? newStartDateTime.startOf('day').plus({ days: 1 })
      : applyTimeToDate(newStartDateTime, endDate);

    updateEntry({
      startDate: newStartDateTime,
      endDate: newEndDateTime,
      rrule: {
        until: newUntilDateTime,
      },
    });
  };

  const onEndDateChange = (date: DateTime) => {
    updateEntry({
      endDate: applyTimeToDate(date, endDate),
    });
  };

  const onMonthChange = (weekday: Weekday | null) => {
    updateEntry({
      rrule: {
        byweekday: weekday === null ? null : [weekday],
      },
    });
  };

  const onWeekdayChange = (weekday: Weekday[]) => {
    updateEntry({
      rrule: {
        byweekday: weekday.length ? weekday : [getDefaultWeekday(startDate)],
      },
    });
  };

  const onStartTimeChange = (updatedStartDateTime: DateTime) => {
    const duration = endDate.diff(startDate, 'minutes').minutes;
    const newStartDate = applyTimeToDate(startDate, updatedStartDateTime);
    const newEndDate = newStartDate.plus({ minutes: duration });

    updateEntry({
      startDate: newStartDate,
      endDate: newEndDate,
    });
  };

  const onEndTimeChange = (updatedEndDateTime: DateTime) => {
    let newEndDateTime = applyTimeToDate(endDate, updatedEndDateTime);

    // the end date is always relative to start date if recurrence is used
    if (frequency) {
      newEndDateTime = applyTimeToDate(startDate, updatedEndDateTime);
    }

    // Don't allow times earlier than the start time
    if (newEndDateTime < startDate) {
      newEndDateTime = startDate.plus({ hours: 1 });
    }

    // For midnight end times, ensure the date is the start of tomorrow
    // instead of midnight on the same date
    if (areDaysEqual(startDate, newEndDateTime) && isMidnight(newEndDateTime)) {
      newEndDateTime = newEndDateTime.plus({ days: 1 });
    }

    updateEntry({
      endDate: newEndDateTime,
    });
  };

  const onTitleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateEntry({ title: event.target.value });
  };

  const onEmojiChange = (emoji: string | null) => {
    updateEntry({ emoji });
  };

  const onCategoryChange = (category: Category | null) => {
    updateEntry({ category });
  };

  const onLabelsChange = (labels: Label[]) => {
    updateEntry({ labels });
  };

  const onWhoLabelsChange = (whoLabels: Label[]) => {
    updateEntry({ whoLabels });
  };

  const onLocationChange = (name: string, googlePlaceId: string | null) => {
    updateEntry({
      locationWithPlace: {
        name,
        googlePlaceId,
      },
    });
  };

  const onDescriptionChange = (description: string) => {
    updateEntry({ description });
  };

  return {
    // parsed rrule props
    frequency,
    interval,
    byWeekday,
    untilDate,
    // date + time handlers
    onStartDateChange,
    onEndDateChange,
    onStartTimeChange,
    onEndTimeChange,
    onAddTime,
    onRemoveTime,
    onUntilDateChange,
    onRecurrenceChange,
    onMonthChange,
    onWeekdayChange,
    // other metadata handlers
    onTitleChange,
    onEmojiChange,
    onCategoryChange,
    onLabelsChange,
    onWhoLabelsChange,
    onLocationChange,
    onDescriptionChange,
  };
};
