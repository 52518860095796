import React, { type Context, useContext } from 'react';

export const createContext = <T>(value: T, displayName: string) => {
  const context = React.createContext<T>(value);
  context.displayName = displayName;
  return context;
};

export const createContextHook = <T>(Context: Context<T>) => {
  return () => {
    const api = useContext(Context);
    if (api === undefined) {
      throw new Error(
        `Cannot call use${Context.displayName} outside of a ${Context.displayName}Provider`
      );
    }
    return api;
  };
};
