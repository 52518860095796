import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, createPath, useLocation } from 'react-router-dom';
import { useCurrentUserContext } from '@/contexts';
import { ErrorState } from '@/pages/Schedule/components';
import { Button, Flex, Link, Text } from '@/ui';
import { getErrorMessage } from '@/utils/errors';
import { useRequestAccess } from './hooks';

type Props = {
  title?: string;
};

export const InvalidPermissionsError = ({ title }: Props) => {
  const { t } = useTranslation('schedule', {
    keyPrefix: 'errors.invalid_permissions',
  });
  const { currentUser } = useCurrentUserContext();
  const {
    requestAccess,
    isPending: isRequestingAccess,
    error: requestAccessError,
    isSuccess: hasRequestedAccess,
  } = useRequestAccess();

  const location = useLocation();
  const path = createPath(location);

  const handleRequestAccess = () => {
    requestAccess();
  };

  return (
    <ErrorState title={title ?? t('title')}>
      {currentUser ? (
        <Flex align="center" direction="column" gap="6">
          <Text fontSize="lg">
            <Trans
              i18nKey="user_message"
              t={t}
              values={{ email: currentUser.email }}
            >
              You&apos;re signed in as <strong>{currentUser.email}</strong>.
              <br />
              Would you like to request access from the schedule owner?
            </Trans>
          </Text>

          <Button
            isDisabled={Boolean(requestAccessError) || hasRequestedAccess}
            isLoading={isRequestingAccess}
            onClick={handleRequestAccess}
          >
            {t('request_access')}
          </Button>
          {hasRequestedAccess && <Text>{t('request_access_success')}</Text>}
          {requestAccessError && (
            <Text color="red.500">{getErrorMessage(requestAccessError)}</Text>
          )}
        </Flex>
      ) : (
        <Text fontSize="lg">
          <Trans i18nKey="anonymous_message" t={t}>
            If you think you should have access, please{' '}
            <Link as={RouterLink} to={`/auth?from=${path}`}>
              sign up
            </Link>{' '}
            or{' '}
            <Link as={RouterLink} to={`/auth?from=${path}`}>
              login
            </Link>{' '}
            in first.
          </Trans>
        </Text>
      )}
    </ErrorState>
  );
};
