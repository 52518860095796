import type { ComponentProps } from 'react';
import { LabelSelect } from '@/pages/Schedule/components/LabelSelect';
import { type Label, LabelType } from '@/types/gql.generated';
import { Icons, type IconType, Menu, MenuButton, MenuList } from '@/ui';
import { FooterButton } from './FooterButton';

type LabelTypeConfig = Record<
  LabelType,
  {
    label: string;
    icon: IconType;
  }
>;

const labelTypeConfig: LabelTypeConfig = {
  [LabelType.Default]: {
    label: 'Labels',
    icon: Icons.Tag,
  },
  [LabelType.Who]: {
    label: 'Who',
    icon: Icons.User,
  },
};

type Props = Omit<
  ComponentProps<typeof LabelSelect>,
  'onSelect' | 'onDeselect' | 'onChange'
> & {
  onChange: (labels: Label[]) => void;
};

export const LabelPicker = ({
  selected,
  labelType,
  onChange,
  ...props
}: Props) => {
  const { label, icon } = labelTypeConfig[labelType];

  const handleSelect = (label: Label) => {
    if (selected) {
      const nextLabels = [...selected, label];
      onChange(nextLabels);
    }
  };

  const handleDeselect = (label: Label) => {
    if (selected) {
      const nextLabels = selected.filter((item) => item.id !== label.id);
      onChange(nextLabels);
    }
  };

  return (
    <Menu isLazy>
      <MenuButton as={FooterButton} label={label} leftIcon={icon} />
      <MenuList minW="350px" p="0">
        <LabelSelect
          labelType={labelType}
          selected={selected}
          onDeselect={handleDeselect}
          onSelect={handleSelect}
          {...props}
        />
      </MenuList>
    </Menu>
  );
};
