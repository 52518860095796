import { Flex, type FlexProps, Icon, Text } from '@/ui';
import { useItemCardContext } from '../context/useItemCardContext';
import { summarizeTime } from '../utils';

export const Time = (props: FlexProps) => {
  const { entry, instance } = useItemCardContext();

  return (
    <Flex
      align="center"
      fontWeight="medium"
      gap="2.5"
      lineHeight="1"
      {...props}
    >
      <Flex boxSize="20px" pos="relative" top="-1px">
        <Icon icon="Calendar" size="18px" />
      </Flex>

      <Text _firstLetter={{ textTransform: 'capitalize' }}>
        {summarizeTime(entry, instance)}
      </Text>
    </Flex>
  );
};
