import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useScheduleCache } from '@/pages/Schedule/hooks';
import type { MemberFormFieldDataInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  SubmitMemberFormMutation,
  SubmitMemberFormMutationVariables,
} from './useFormSubmit.generated';

const query = gql`
  mutation SubmitMemberForm(
    $scheduleId: ID!
    $formData: [MemberFormFieldDataInput!]!
  ) {
    submitMemberForm(scheduleId: $scheduleId, formData: $formData) {
      fieldId
      order
      label
      type
      helpText
      value
    }
  }
`;

export const useFormSubmit = () => {
  const scheduleCache = useScheduleCache();
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    SubmitMemberFormMutation,
    QueryError,
    SubmitMemberFormMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        SubmitMemberFormMutation,
        SubmitMemberFormMutationVariables
      >(query, variables),
    onSuccess: (data) => {
      const formSubmission = data.submitMemberForm;

      if (formSubmission) {
        scheduleCache.updateSchedule({
          selfMemberFormSubmission: formSubmission,
        });
      }
    },
  });

  const submit = useCallback(
    (
      formData: MemberFormFieldDataInput[],
      options?: MutateOptions<
        SubmitMemberFormMutation,
        QueryError,
        SubmitMemberFormMutationVariables
      >
    ) => {
      return mutate({ formData, scheduleId }, options);
    },
    [mutate, scheduleId]
  );

  return { submit, isPending };
};
