import { Link } from 'react-router-dom';
import type { As, StyleProps } from '@/ui';
import type { CustomHref, LogoHref } from './types';

type LogoLinkProps = Partial<{
  as: As;
  to: string;
  href: string;
  _hover: StyleProps;
}>;

const isCustomHref = (logoHref?: LogoHref): logoHref is CustomHref => {
  return typeof logoHref === 'object' && typeof logoHref.to === 'string';
};

export const getLogoLinkProps = (
  logoHref: LogoHref | undefined,
  isAuthenticated: boolean
): LogoLinkProps => {
  if (logoHref === 'none') {
    return {};
  }

  const logoLinkProps: LogoLinkProps = {
    _hover: {
      textDecor: 'underline',
    },
  };

  const to = isCustomHref(logoHref) ? logoHref.to : '/';
  const external = isCustomHref(logoHref)
    ? logoHref.external
    : !isAuthenticated;

  if (external) {
    logoLinkProps.as = 'a';
    logoLinkProps.href = to;
  } else {
    logoLinkProps.as = Link;
    logoLinkProps.to = to;
  }

  return logoLinkProps;
};
