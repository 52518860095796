import { RegisterModal } from '@/components/RegisterModal';
import { SettingsModal } from '@/components/SettingsModal';
import { SharedModalOverlay } from '@/components/SharedModalOverlay';
import { UpdateAvailableCallout } from '@/components/UpdateAvailableCallout';
import { useCurrentUserContext } from '@/contexts';
import { CreateScheduleModal } from '@/pages/Dashboard/components/CreateScheduleModal';
import { SnippetIngredientInUseModal } from '@/pages/Schedule/components/SnippetIngredientInUseModal';
import { RenderIf } from './RenderIf';

export const GlobalComponents = () => {
  const { isAuthenticated } = useCurrentUserContext();

  return (
    <>
      {isAuthenticated && (
        <>
          <SnippetIngredientInUseModal />
          <SettingsModal />
        </>
      )}

      <RenderIf paths={['/dashboard', '/schedule/*']}>
        <CreateScheduleModal />
        <SharedModalOverlay />
      </RenderIf>

      <RenderIf pathsNot={['/magic/*']}>
        <UpdateAvailableCallout />
      </RenderIf>

      {/**
       * This needs to be rendered outside of an isAuthenticated check
       * so it doesn't unmount before consumers are notified of
       * successful authentication
       */}
      <RegisterModal />
    </>
  );
};
