import { useQueryNormalizer } from '@normy/react-query';
import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import {
  type DecoratedSchedule,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type {
  EnableScheduleHeaderMutation,
  EnableScheduleHeaderMutationVariables,
} from './useEnableScheduleHeader.generated';

const query = gql`
  mutation EnableScheduleHeader($scheduleId: ID!, $headerEnabled: Boolean!) {
    updateSchedule(scheduleId: $scheduleId, headerEnabled: $headerEnabled) {
      id
      header {
        id
        enabled
        imageBaseUrl
      }
    }
  }
`;

export const useEnableScheduleHeader = () => {
  const { scheduleId } = useScheduleContext();
  const queryNormalizer = useQueryNormalizer();

  const { mutate } = useMutation<
    EnableScheduleHeaderMutation,
    QueryError,
    EnableScheduleHeaderMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        EnableScheduleHeaderMutation,
        EnableScheduleHeaderMutationVariables
      >(query, variables),
  });

  const enableScheduleHeader = (
    options?: MutateOptions<
      EnableScheduleHeaderMutation,
      QueryError,
      EnableScheduleHeaderMutationVariables
    >
  ) => {
    const prevSchedule =
      queryNormalizer.getObjectById<DecoratedSchedule>(scheduleId);

    const nextSchedule = {
      ...prevSchedule,
      header: {
        ...prevSchedule?.header,
        enabled: true,
      },
    };

    queryNormalizer.setNormalizedData(nextSchedule);

    return mutate(
      { scheduleId, headerEnabled: true },
      {
        onError: (...args) => {
          queryNormalizer.setNormalizedData(prevSchedule);
          options?.onError?.(...args);
        },
      }
    );
  };

  return {
    enableScheduleHeader,
  };
};
