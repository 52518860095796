import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import {
  createEntriesQueryKey,
  createEntryMessagesQueryKey,
  createScheduleEntryMessagesQueryKey,
} from '@/utils/queryKeys';
import type {
  DeleteEntryMessageMutation,
  DeleteEntryMessageMutationVariables,
} from './useDeleteEntryMessage.generated';

const query = gql`
  mutation DeleteEntryMessage($scheduleId: ID!, $entryMessageId: ID!) {
    deleteEntryMessage(scheduleId: $scheduleId, entryMessageId: $entryMessageId)
  }
`;

export const useDeleteEntryMessage = (entryId: string) => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending } = useMutation<
    DeleteEntryMessageMutation,
    QueryError,
    DeleteEntryMessageMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        DeleteEntryMessageMutation,
        DeleteEntryMessageMutationVariables
      >(query, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: createEntriesQueryKey(scheduleId),
      });

      return Promise.all([
        // invalidate messages tab
        queryClient.invalidateQueries({
          queryKey: createScheduleEntryMessagesQueryKey(scheduleId),
        }),
        // invalidate entry modal
        queryClient.invalidateQueries({
          queryKey: createEntryMessagesQueryKey(scheduleId, entryId),
        }),
      ]);
    },
  });

  const deleteEntryMessage = useCallback(
    (
      entryMessageId: string,
      options?: MutateOptions<
        DeleteEntryMessageMutation,
        QueryError,
        DeleteEntryMessageMutationVariables
      >
    ) => {
      return mutate(
        {
          scheduleId,
          entryMessageId,
        },
        options
      );
    },
    [scheduleId, mutate]
  );

  const bulkDeleteEntryMessages = useCallback(
    async (entryMessageIds: string[]) => {
      const operations = entryMessageIds.map((id) => {
        return new Promise<void>((resolve, reject) => {
          deleteEntryMessage(id, {
            onSuccess: () => resolve(),
            onError: reject,
          });
        });
      });

      await Promise.all(operations);
    },
    [deleteEntryMessage]
  );

  return {
    deleteEntryMessage,
    bulkDeleteEntryMessages,
    isPending,
  };
};
