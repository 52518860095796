import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@/ui';

type Props = {
  onClick: () => void;
};

export const AddHeaderButton = ({ onClick }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  if (isMobileBreakpoint) {
    return (
      <Menu isLazy>
        <MenuButton
          aria-label="Add banner"
          as={IconButton}
          colorScheme="dark"
          icon={<Icon icon="MoreHorizontal" />}
          size="sm"
          variant="shadow"
        />
        <Portal>
          <MenuList>
            <MenuItem onClick={onClick}>Add banner</MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <Button colorScheme="dark" size="sm" variant="shadow" onClick={onClick}>
      Add banner
    </Button>
  );
};
