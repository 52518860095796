import { Trans, useTranslation } from 'react-i18next';
import { Flex, Text } from '@/ui';

export const OnboardingHeader = () => {
  const { t } = useTranslation('addContent', {
    keyPrefix: 'onboarding_header',
  });

  return (
    <Flex
      align="center"
      direction="column"
      flex="1"
      fontSize={{ base: '2xl', sm: '3xl' }}
      fontWeight="bold"
      justify="center"
      lineHeight="normal"
      mb="3"
      mt="12"
      textAlign="center"
    >
      <Text>{t('title_line_1')}</Text>
      <Text>{t('title_line_2')}</Text>
      <Text
        fontSize={{ base: 'md', md: 'lg' }}
        fontWeight="medium"
        maxW={{ base: '80%', sm: 'none' }}
        mt="3"
      >
        <Trans i18nKey="tip" t={t} />
      </Text>
    </Flex>
  );
};
