import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@/ui';
import { factory } from '../categoryLib';
import type { Category, CompleteCategory } from '../types';
import { ColorInput } from './ColorInput';

type Props = {
  category: Category;
  onChange?: (category: CompleteCategory) => void;
  onSubmit: (category: CompleteCategory) => void;
  onCancel: (originalCategory: Category) => void;
};

const CATEGORY_MAX_LENGTH = 100;

export const CategoryForm = ({
  category,
  onCancel,
  onChange,
  onSubmit,
}: Props): JSX.Element => {
  const { t } = useTranslation('categorySelect');
  const [originalCategory] = useState(category);
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompleteCategory>({
    defaultValues: factory(category),
  });

  return (
    <form
      noValidate
      onBlur={(event) => {
        if (onChange) {
          handleSubmit((data) => onChange(data))(event);
        }
      }}
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
    >
      <Flex direction="column" gap="8">
        <FormControl isInvalid={Boolean(errors.text)}>
          <FormLabel variant="uppercase">{t('form_name')}</FormLabel>
          <Input
            autoFocus
            placeholder={t('form_category_placeholder')}
            size="sm"
            type="text"
            variant="underline"
            {...register('text', {
              maxLength: {
                message: t('form_category_maxlength', {
                  max: CATEGORY_MAX_LENGTH,
                }),
                value: CATEGORY_MAX_LENGTH,
              },
            })}
          />
          <FormErrorMessage>{errors.text?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.color)}>
          <FormLabel variant="uppercase">{t('form_color')}</FormLabel>
          <Controller
            control={control}
            name="color"
            render={({ field }) => (
              <ColorInput {...field} value={field.value} />
            )}
          />
        </FormControl>

        <Flex gap="2">
          <Button type="submit">{t('form_save')}</Button>
          <Button
            type="button"
            variant="secondary"
            onClick={() => onCancel(originalCategory)}
          >
            {t('form_cancel')}
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};
