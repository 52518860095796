import { useCallback, useState, type ReactNode } from 'react';
import { createContext } from '@/utils';

type FooterConfig = {
  onNext?: (() => void) | null;
  onPrev?: (() => void) | null;
  isNextDisabled?: boolean;
  nextButtonLabel?: string;
};

type FamilyFlowContextValue = {
  slide: number;
  direction: number;
  slides: number;
  moveNext: () => void;
  movePrev: () => void;
  footerConfig: FooterConfig;
  configureFooter: (config: Partial<FooterConfig>) => void;
};

type Props = {
  children: ReactNode | ((value: FamilyFlowContextValue) => ReactNode);
  slides: number;
};

export const FamilyFlowContext = createContext<
  FamilyFlowContextValue | undefined
>(undefined, 'FamilyFlowContext');

export const FamilyFlowContextProvider = ({ children, slides }: Props) => {
  const [{ slide, direction }, setSlide] = useState<
    Pick<FamilyFlowContextValue, 'slide' | 'direction'>
  >(() => ({
    slide: 1,
    direction: 1,
  }));

  const moveNext = useCallback(() => {
    setSlide(({ slide }) => ({
      slide: slide + 1,
      direction: 1,
    }));
  }, []);

  const movePrev = useCallback(() => {
    setSlide(({ slide }) => ({
      slide: slide - 1,
      direction: -1,
    }));
  }, []);

  const [footerConfig, setFooterConfig] = useState<FooterConfig>({});

  const configureFooter = useCallback(
    (config: Partial<FooterConfig>) => {
      setFooterConfig(() => ({
        onNext: moveNext,
        onPrev: movePrev,
        nextButtonLabel: 'Next',
        isNextDisabled: false,
        ...config,
      }));
    },
    [moveNext, movePrev]
  );

  const value = {
    slide,
    direction,
    slides,
    moveNext,
    movePrev,
    footerConfig,
    configureFooter,
  };

  return (
    <FamilyFlowContext.Provider value={value}>
      {typeof children === 'function' ? children(value) : children}
    </FamilyFlowContext.Provider>
  );
};
