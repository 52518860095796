import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  ToggleScheduleSaveMutation,
  ToggleScheduleSaveMutationVariables,
} from './useToggleScheduleSave.generated';

const query = gql`
  mutation ToggleScheduleSave($scheduleId: ID!, $saved: Boolean!) {
    toggleScheduleSave(scheduleId: $scheduleId, saved: $saved)
  }
`;

export const useToggleScheduleSave = () => {
  const { mutate, isPending, isSuccess, reset } = useMutation<
    ToggleScheduleSaveMutation,
    QueryError,
    ToggleScheduleSaveMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        ToggleScheduleSaveMutation,
        ToggleScheduleSaveMutationVariables
      >(query, variables);
    },
    onMutate: (variables) => {
      return {
        optimisticData: {
          id: variables.scheduleId,
          isSaved: variables.saved,
        },
        rollbackData: {
          id: variables.scheduleId,
          isSaved: !variables.saved,
        },
      };
    },
  });

  const toggleScheduleSave = (
    scheduleId: string,
    saved: boolean,
    options?: MutateOptions<
      ToggleScheduleSaveMutation,
      QueryError,
      ToggleScheduleSaveMutationVariables
    >
  ) => {
    const variables = { scheduleId, saved };
    return mutate(variables, options);
  };

  return { toggleScheduleSave, isPending, isSuccess, reset };
};
