import type { SVGProps } from 'react';

export const Arrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 28 29" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23.8613 0.154389C23.6704 -0.0451633 23.3539 -0.0521968 23.1544 0.138679L19.9025 3.24918C19.7029 3.44006 19.6959 3.75656 19.8868 3.95611C20.0777 4.15567 20.3942 4.1627 20.5937 3.97182L23.4843 1.20693L26.2492 4.0975C26.4401 4.29705 26.7566 4.30409 26.9561 4.11321C27.1557 3.92233 27.1627 3.60583 26.9718 3.40628L23.8613 0.154389ZM0.618727 28.9857C8.21919 27.1278 23.5926 18.8403 23.9999 0.511108L23.0001 0.488892C22.6074 18.1597 7.78081 26.2055 0.381273 28.0143L0.618727 28.9857Z"
        fill="currentColor"
        opacity="0.5"
      />
    </svg>
  );
};
