import {
  type MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import {
  createProfileQueryKey,
  createScheduleMembersQueryKey,
  createScheduleCollaboratorsQueryKey,
} from '@/utils/queryKeys';
import type {
  AddCollaboratorToScheduleMutation,
  AddCollaboratorToScheduleMutationVariables,
} from './useAddCollaborator.generated';

const query = gql`
  mutation AddCollaboratorToSchedule(
    $scheduleId: ID!
    $users: [UserPermissionInput!]!
    $message: String
  ) {
    addSchedulePermissions(
      scheduleId: $scheduleId
      users: $users
      message: $message
    )
  }
`;

export const useAddCollaborator = () => {
  const queryClient = useQueryClient();
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending, error, reset } = useMutation<
    AddCollaboratorToScheduleMutation,
    QueryError,
    AddCollaboratorToScheduleMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        AddCollaboratorToScheduleMutation,
        AddCollaboratorToScheduleMutationVariables
      >(query, variables);
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: createScheduleCollaboratorsQueryKey(variables.scheduleId),
      });
      queryClient.invalidateQueries({
        queryKey: createScheduleMembersQueryKey(variables.scheduleId),
      });
      queryClient.invalidateQueries({ queryKey: createProfileQueryKey() });
    },
  });

  const addCollaborator = (
    values: Omit<AddCollaboratorToScheduleMutationVariables, 'scheduleId'>,
    options?: MutateOptions<
      AddCollaboratorToScheduleMutation,
      QueryError,
      AddCollaboratorToScheduleMutationVariables
    >
  ) => {
    return mutate({ scheduleId, ...values }, options);
  };

  return {
    addCollaborator,
    isPending,
    reset,
    error,
  };
};
