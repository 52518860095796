import { useTranslation } from 'react-i18next';
import { RichTextEditor } from '@/pages/Schedule/components';
import { Flex, FormControl, forwardRef } from '@/ui';
import { Label } from './Label';

type Props = {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
};

export const ScheduleDescription = forwardRef(
  function ScheduleDescriptionWithRef({ value, onChange, onBlur }: Props, ref) {
    const { t } = useTranslation('scheduleSettingsModal');

    return (
      <FormControl>
        <Label>{t('description_label')}</Label>
        <Flex
          borderColor="gray.300"
          borderRadius="lg"
          borderWidth="1px"
          lineHeight="22px"
          p="2"
        >
          <RichTextEditor
            ref={ref}
            style={{ width: '100%', minHeight: 50 }}
            value={value}
            onBlur={onBlur}
            onChange={(value) => {
              if (typeof value === 'string') {
                onChange(value);
              }
            }}
          />
        </Flex>
      </FormControl>
    );
  }
);
