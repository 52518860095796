import { AnimatePresence } from 'framer-motion';
import { useIsMagic } from '@/hooks/useIsMagic';
import { MotionBox } from '@/ui';

export const Background = () => {
  const { isMagic } = useIsMagic();

  return (
    <AnimatePresence initial={false} mode="popLayout">
      <MotionBox
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        inset="0"
        key={isMagic ? 'magic' : 'dashboard'}
        layerStyle={isMagic ? 'magicBackground' : undefined}
        pos="fixed"
        transition={{ duration: 0.3 }}
        zIndex="-1"
      />
    </AnimatePresence>
  );
};
