import { useEffect, useRef } from 'react';
import type { Callback, Command } from './usePostMessageReceiver';

/**
 * useBroadcast provides a BroadCast channel that can be used to send messages
 * to other windows or workers on our domain in cases where we cannot use
 * postMessage because we are unable to reliably get a handle on the destination
 * window for the message. In cases where the destination window is known and
 * obtainable, postMessage should be preferred.
 */
export const useBroadcast = () => {
  const ref = useRef<BroadcastChannel>();

  useEffect(() => {
    ref.current = new BroadcastChannel('_ah');

    return () => ref.current?.close();
  }, []);

  const postMessage = (message: Command) => {
    ref.current?.postMessage(message);
  };

  return {
    postMessage,
  };
};

export const useBroadcastReceiver = (command: Command, callback: Callback) => {
  const stableCallback = useRef(callback);

  useEffect(() => {
    stableCallback.current = callback;
  });

  useEffect(() => {
    const channel = new BroadcastChannel('_ah');

    channel.onmessage = (event) => {
      if (typeof event.data === 'string' && event.data.startsWith(command)) {
        // some commands have a trailing '=' that we need to remove, like
        // `_ah_set_prompt=...` whereas others are a simple command like `_ah_reset`
        const message = event.data.substring(command.length).replace(/^=/, '');
        stableCallback.current(message);
      }
    };

    return () => channel.close();
  }, [command]);
};
