import { type FlexProps, Flex } from '@/ui';
import { Logo } from '../Logo';

type Props = FlexProps & {
  fullWidth?: boolean;
  hideLogo?: boolean;
  header: JSX.Element;
  onSubmit: () => void;
};

export const AuthFormStack = ({
  header,
  hideLogo,
  fullWidth,
  children,
  ...props
}: Props) => {
  return (
    <Flex
      as="form"
      direction="column"
      gap="6"
      noValidate
      textAlign="center"
      w={fullWidth ? '100%' : { base: '100vw', sm: '380px' }}
      {...props}
    >
      {!hideLogo && <Logo mx="auto" width={60} />}

      {header}

      <Flex direction="column" gap="3">
        {children}
      </Flex>
    </Flex>
  );
};
