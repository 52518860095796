import { useAnalytics } from '@/hooks/useAnalytics';
import {
  Box,
  Flex,
  forwardRef,
  MotionFlex,
  type MotionFlexProps,
  Text,
} from '@/ui';
import type { ScheduleOption } from '../../types';
import { BlankCanvasText } from './BlankCanvasText';
import { FamilyCalendarText } from './FamilyCalendarText';
import { OptionButton } from './OptionButton';

type Props = Omit<MotionFlexProps, 'onClick'> & {
  onClick: (option: ScheduleOption) => void;
};

export const IndexState = forwardRef<Props, typeof MotionFlex>(
  function IndexStateWithRef({ onClick, ...props }, ref) {
    const { trackEvent } = useAnalytics();

    const handleBlankClick = () => {
      onClick('blank');
      trackEvent('schedule:click create blank schedule');
    };

    const handleFamilyClick = () => {
      onClick('family');
      trackEvent('schedule:click create family schedule');
    };

    return (
      <MotionFlex direction="column" flex="1" ref={ref} {...props}>
        <Box
          fontSize={{ base: '36px', md: '48px' }}
          fontWeight="bold"
          lineHeight={{ base: '42px', md: '56px' }}
          mt="6"
          textAlign="center"
        >
          <Text fontSize="56px" mb="4">
            ✨
          </Text>
          <Text>Let&apos;s make a</Text>
          <Text textStyle="magic">dazzling schedule</Text>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          flex={{ md: 1 }}
          gap="3"
          mt={{ base: 9, md: 0 }}
        >
          <OptionButton onClick={handleBlankClick}>
            <Text>Start with a</Text>
            <BlankCanvasText />
          </OptionButton>

          <OptionButton onClick={handleFamilyClick}>
            <Text fontSize="48px">🏡</Text>
            <Text>Create a</Text>
            <FamilyCalendarText />
          </OptionButton>
        </Flex>
      </MotionFlex>
    );
  }
);
