import { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalytics } from '@/hooks/useAnalytics';
import type { Category } from '@/pages/Schedule/components/Category';
import {
  ItemCard,
  ItemCardContent,
  ItemCardFooter,
  ItemCardHeader,
  ItemCardMetadata,
} from '@/pages/Schedule/components/ItemCard';
import { useUpdateEntry, useUpdateEntryHandlers } from '@/pages/Schedule/hooks';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import { type Label, LabelType } from '@/types/gql.generated';
import { Box, Icons } from '@/ui';
import { CategoryPicker } from './components/CategoryPicker';
import { DeleteButton } from './components/DeleteButton';
import { FooterButton } from './components/FooterButton';
import { LabelPicker } from './components/LabelPicker';

type Props = {
  entry: DecoratedEntry;
  isConfirmingDelete: boolean;
  onEditClick: (entry: DecoratedEntry) => void;
  onMessagesClick: (entry: DecoratedEntry) => void;
  onDeleteClick: (entry: DecoratedEntry) => void;
};

export const EntryCard = memo(function MemoizedEntryCard({
  entry,
  isConfirmingDelete,
  onEditClick,
  onMessagesClick,
  onDeleteClick,
}: Props) {
  const { trackEvent } = useAnalytics();
  const { updateEntry } = useUpdateEntry();
  const cardRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('addContent');

  const handlers = useUpdateEntryHandlers(entry, (updatedEntry) => {
    updateEntry(updatedEntry);
    trackEvent('ai-add:inline edit');
  });

  const centerCardInScrollPosition = () => {
    cardRef.current?.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  };

  const handleEditClick = () => {
    onEditClick(entry);
    trackEvent('ai-add:click entry edit');
  };

  const handleMessagesClick = () => {
    onMessagesClick(entry);
    trackEvent('ai-add:click entry messages');
  };

  const handleDeleteClick = () => {
    onDeleteClick(entry);
    trackEvent('ai-add:click entry delete');
  };

  const handleAddCategory = (category: Category | null) => {
    handlers.onCategoryChange(category);
    centerCardInScrollPosition();
  };

  const handleRemoveCategory = () => {
    handlers.onCategoryChange(null);
  };

  const handleAddLabel = (labels: Label[]) => {
    handlers.onLabelsChange(labels);
    centerCardInScrollPosition();
  };

  const handleRemoveLabel = (label: Label) => {
    const nextLabels = entry.labels.filter(({ id }) => id !== label.id);
    handlers.onLabelsChange(nextLabels);
  };

  const handleAddWhoLabel = (whoLabels: Label[]) => {
    handlers.onWhoLabelsChange(whoLabels);
    centerCardInScrollPosition();
  };

  const handleRemoveWhoLabel = (whoLabel: Label) => {
    const nextLabels = entry.whoLabels.filter(({ id }) => id !== whoLabel.id);
    handlers.onWhoLabelsChange(nextLabels);
  };

  return (
    <ItemCard
      borderColor="var(--add-content-modal-border-color)"
      entry={entry}
      flexGrow="0"
      pb="0"
      ref={cardRef}
    >
      <ItemCardContent>
        <DeleteButton
          isActive={isConfirmingDelete}
          onClick={handleDeleteClick}
        />
        <ItemCardHeader />
        <ItemCardMetadata
          onRemoveCategory={handleRemoveCategory}
          onRemoveLabel={handleRemoveLabel}
          onRemoveWhoLabel={handleRemoveWhoLabel}
        />
      </ItemCardContent>
      <ItemCardFooter
        align="center"
        bg="customgray.bglight"
        gap={{ base: 1.5, md: 2 }}
        py="3"
      >
        <FooterButton
          label={t('aria_edit')}
          leftIcon={Icons.Pencil}
          onClick={handleEditClick}
        />

        <Box
          bg="var(--add-content-modal-border-color)"
          h="70%"
          mx="1"
          py="2"
          w="1px"
        />

        <FooterButton
          label={t('aria_texts')}
          leftIcon={Icons.TextMessage}
          onClick={handleMessagesClick}
        />

        <CategoryPicker
          selected={entry.category}
          onSelect={handleAddCategory}
        />

        <LabelPicker
          labelType={LabelType.Default}
          selected={entry.labels}
          onChange={handleAddLabel}
        />

        <LabelPicker
          labelType={LabelType.Who}
          selected={entry.whoLabels}
          onChange={handleAddWhoLabel}
        />
      </ItemCardFooter>
    </ItemCard>
  );
});
