import { cloneElement, useState } from 'react';
import { Link } from 'react-router-dom';
import { colors } from '@/theme';
import { type ButtonProps, IconButton, Tooltip } from '@/ui';

type Props = ButtonProps & {
  href?: string;
  label: string;
  icon: JSX.Element;
};

export const ToolbarButton = ({
  label,
  icon,
  isActive,
  href,
  onClick,
}: Props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const button = (
    <IconButton
      _active={{ bg: 'gray.100' }}
      _hover={{ bg: 'gray.100' }}
      alignItems="center"
      aria-label={label}
      as={Link}
      borderRadius="0"
      display="flex"
      h="100%"
      isActive={isActive}
      p="0"
      {...(href ? { to: href } : { onClick })}
      variant="unstyled"
      w="56px"
      icon={cloneElement(icon, {
        color: colors.customgray.dark,
        fontSize: 20,
      })}
    />
  );

  // This is my hacky way of forcing the tooltip to only appear on hover.
  // Otherwise Chakra will show the tooltip when you return to the browser
  // and a ToolbarButton has focus, which it commonly does and is great for
  // accessibility, but it makes the app look buggy.
  if (!tooltipVisible) {
    return cloneElement(button, {
      onMouseEnter: () => setTooltipVisible(true),
    });
  }

  return (
    <Tooltip closeOnClick={false} label={label}>
      {cloneElement(button, {
        onMouseLeave: () => setTooltipVisible(false),
      })}
    </Tooltip>
  );
};
