import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import {
  ButtonGroup,
  type ButtonProps,
  forwardRef,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Button,
  type SystemProps,
} from '@/ui';

const shadow = '0px 0px 14px 0px rgba(0, 0, 0, 0.15)';

const buttonProps: ButtonProps = {
  size: 'sm',
  variant: 'secondary',
  border: 'none',
  bg: 'white',
  _hover: { bg: 'gray.100' },
  _active: { bg: 'gray.200' },
};

const ChangeButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    justifyContent="flex-start"
    leftIcon={<Icon icon="Pencil" />}
    ref={ref}
    {...buttonProps}
    {...props}
  >
    Change
  </Button>
));

const RemoveButton = forwardRef((props: ButtonProps, ref) => (
  <Button
    justifyContent="flex-start"
    leftIcon={<Icon icon="Trash" />}
    ref={ref}
    {...buttonProps}
    {...props}
  >
    Remove
  </Button>
));

type Props = SystemProps & {
  isMenuOpen: boolean;
  onMenuOpen: () => void;
  onMenuClose: () => void;
  onChange: () => void;
  onRemove: () => void;
};

export const BannerMenu = ({
  isMenuOpen,
  onMenuOpen,
  onMenuClose,
  onChange,
  onRemove,
  ...props
}: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();

  if (isMobileBreakpoint) {
    return (
      <Menu
        isLazy
        isOpen={isMenuOpen}
        onClose={onMenuClose}
        onOpen={onMenuOpen}
      >
        <MenuButton
          as={IconButton}
          icon={<Icon icon="MoreHorizontal" size="6" />}
          shadow={shadow}
          size="sm"
          {...buttonProps}
          {...props}
        />
        <Portal>
          <MenuList>
            <ChangeButton as={MenuItem} onClick={onChange} />
            <RemoveButton as={MenuItem} onClick={onRemove} />
          </MenuList>
        </Portal>
      </Menu>
    );
  }

  return (
    <ButtonGroup isAttached shadow={shadow} {...props}>
      <ChangeButton h="9" px="3" onClick={onChange} />
      <RemoveButton h="9" px="3" onClick={onRemove} />
    </ButtonGroup>
  );
};
