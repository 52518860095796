import type {
  ComponentProps,
  KeyboardEventHandler,
  MouseEventHandler,
} from 'react';
import { chakra, type ChakraComponent } from '@/ui';

type Props = ComponentProps<ChakraComponent<'div', object>>;
type Handler<E> = KeyboardEventHandler<E> & MouseEventHandler<E>;

const captureEvent: Handler<HTMLDivElement> = (event) => {
  event.stopPropagation();
};

export const EventCapture = (props: Props): JSX.Element => {
  return (
    <chakra.div
      {...props}
      onClick={captureEvent}
      onKeyDown={captureEvent}
      onKeyUp={captureEvent}
    />
  );
};
