import { useQuery } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { createScheduleCollaboratorsQueryKey } from '@/utils/queryKeys';
import type {
  GetScheduleCollaboratorsQuery,
  GetScheduleCollaboratorsQueryVariables,
} from './useScheduleCollaborators.generated';

const query = gql`
  query GetScheduleCollaborators($scheduleId: ID!) {
    getSchedule(scheduleId: $scheduleId) {
      permissions {
        all {
          id
          email
          name
          avatar
          role
        }
      }
    }
  }
`;

export type ScheduleCollaborators = Exclude<
  GetScheduleCollaboratorsQuery['getSchedule']['permissions'],
  undefined | null
>['all'];

const selector = (data: GetScheduleCollaboratorsQuery) =>
  data.getSchedule.permissions?.all ?? [];

export const useScheduleCollaborators = () => {
  const { scheduleId, isPublicRole } = useScheduleContext();

  const { data, isLoading } = useQuery({
    queryKey: createScheduleCollaboratorsQueryKey(scheduleId),
    enabled: !isPublicRole,
    select: selector,
    staleTime: 10_000,
    queryFn: () => {
      return gqlClient.request<
        GetScheduleCollaboratorsQuery,
        GetScheduleCollaboratorsQueryVariables
      >(query, { scheduleId });
    },
  });

  return {
    collaborators: data ?? [],
    isLoading,
  };
};
