import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import { createScheduleMembersQueryKey } from '@/utils/queryKeys';
import type {
  RemoveMemberMutation,
  RemoveMemberMutationVariables,
} from './useRemoveMember.generated';

const query = gql`
  mutation RemoveMember($scheduleId: ID!, $memberId: ID!) {
    removeMember(scheduleId: $scheduleId, memberId: $memberId)
  }
`;

export const useRemoveMember = () => {
  const { scheduleId } = useScheduleContext();

  const { mutate, isPending, error } = useMutation<
    RemoveMemberMutation,
    QueryError,
    RemoveMemberMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<RemoveMemberMutation, RemoveMemberMutationVariables>(
        query,
        variables
      ),
    onSuccess: () => {
      // TODO optimistically update the query
      queryClient.invalidateQueries({
        queryKey: createScheduleMembersQueryKey(scheduleId),
      });
    },
  });

  const removeMember = useCallback(
    (
      memberId: string,
      options?: MutateOptions<
        RemoveMemberMutation,
        QueryError,
        RemoveMemberMutationVariables
      >
    ) => mutate({ scheduleId, memberId }, options),
    [scheduleId, mutate]
  );

  return {
    removeMember,
    error,
    isPending,
  };
};
