import { i18n } from '@/i18n';
import { Alert, AlertIcon } from '@/ui';
import type { Action } from '../types';

type Props = {
  action?: Action;
};

function getDescription(action: Action): string {
  const t = i18n.getFixedT(null, 'auth', 'register_modal_actions');

  return {
    follow_schedule: t('follow_schedule'),
    follow_item: t('follow_item'),
    rsvp_item: t('rsvp_item'),
    copy_public_schedule: t('copy_public_schedule'),
  }[action];
}

export const ActionText = ({ action }: Props): JSX.Element | null => {
  if (!action) {
    return null;
  }

  const description = getDescription(action);

  return (
    <Alert status="info">
      <AlertIcon />
      {description}
    </Alert>
  );
};
