import {
  ModalBody as ChakraModalBody,
  forwardRef,
  type ModalBodyProps,
} from '@/ui';

type Props = ModalBodyProps & {
  pollJobSuccess: boolean;
};

export const ModalBody = forwardRef(
  ({ pollJobSuccess, ...props }: Props, ref) => {
    return (
      <ChakraModalBody
        display="flex"
        flex="1"
        flexDir="column"
        gap="3"
        justifyContent={{ base: 'flex-start', md: 'center' }}
        overflowY={pollJobSuccess ? 'hidden' : 'initial'}
        p={pollJobSuccess ? 0 : { base: 4, md: 6 }}
        pb={pollJobSuccess ? 0 : { base: 0, md: 6 }}
        pt={pollJobSuccess ? 0 : { base: 0, md: 6 }}
        ref={ref}
        transition="padding 0.1s ease"
        {...props}
      />
    );
  }
);
