import { useTranslation } from 'react-i18next';
import { Box, Flex, Text } from '@/ui';
import { useIsColumnLayout } from '../../../hooks/useIsColumnLayout';
import { PublishButton } from './PublishButton';

type Props = {
  count: number;
  isPublishLoading: boolean;
  onPublish: () => void;
};

export const HeaderCard = ({ count, isPublishLoading, onPublish }: Props) => {
  const { t } = useTranslation('addContent', { keyPrefix: 'header_card' });
  const { isColumnLayout } = useIsColumnLayout();

  return (
    <Flex
      direction="column"
      pos="sticky"
      top={isColumnLayout ? 3 : 0}
      zIndex="1"
    >
      {/* This div sits underneath the header to prevent entry cards from bleeding underneath */}
      <Box
        bg="var(--add-content-modal-bg-light)"
        h={{ base: 6, lg: 10 }}
        left="0"
        pos="absolute"
        right="0"
        top={{ base: -3, lg: -8 }}
        zIndex="-1"
      />

      <Flex
        align={{ md: 'center' }}
        bg="var(--add-content-modal-bg-dark)"
        border="1px solid"
        borderColor="var(--add-content-modal-border-color)"
        borderRadius="xl"
        direction={{ base: 'column', md: 'row' }}
        gap={{ base: 2, md: 0 }}
        justify="space-between"
        px={{ base: 4, md: 5 }}
        py={{ base: 3, md: 6 }}
      >
        <Box>
          <Text fontSize="2xl" fontWeight="bold" textStyle="magic">
            {t('title')}
          </Text>
          <Text fontWeight="medium">{t('byline')}</Text>
        </Box>

        <PublishButton
          count={count}
          isPublishLoading={isPublishLoading}
          onClick={onPublish}
        />
      </Flex>
    </Flex>
  );
};
