import { v4 as uuidv4 } from 'uuid';
import type { DecoratedEntry, DecoratedInstance } from '@/pages/Schedule/types';
import { DeliveryTimeType } from '@/types/gql.generated';
import type { EntryModalMessage } from '../types';

export const createEntryMessage = (
  entry: DecoratedEntry,
  instance?: DecoratedInstance
): EntryModalMessage => {
  const isSeriesInstance = !!instance?.rule;

  const startDate = isSeriesInstance
    ? instance.startDate
    : entry.recurrences[0].startDate;

  const deliveryTimeType = (
    isSeriesInstance ? instance.isOnDay : entry.recurrences[0].isOnDay
  )
    ? DeliveryTimeType.Hybrid
    : DeliveryTimeType.Relative;

  return {
    id: uuidv4(),
    instance: startDate,
    status: null,
    body: '',
    deliveryTimeType,
    recipients: [],
  };
};
