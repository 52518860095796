import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  type ModalProps,
  Text,
  ModalOverlay,
  ModalFooter,
  ModalHeader,
  UnorderedList,
  ListItem,
} from '@/ui';

export const ExamplesModal = ({
  isOpen,
  onClose,
}: Pick<ModalProps, 'isOpen' | 'onClose'>) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pb="2">Optional instructions</ModalHeader>
        <ModalBody>
          Use this field to tailor your results. Examples:
          <UnorderedList mt="4">
            <ListItem>
              &quot;Include this Zoom link in the description:{' '}
              <em>https://zoom.us/xyz</em>&quot;
            </ListItem>
            <ListItem>
              &quot;Prepend <em>[Child&apos;s name]</em> in front of every event
              title&quot;
            </ListItem>
            <ListItem>&quot;Use the dove emoji&quot;</ListItem>
            <ListItem>
              &quot;This event actually occurs on the 18th&quot;
            </ListItem>
            <ListItem>&quot;This event is two hours&quot;</ListItem>
          </UnorderedList>
          <Text mt="4">
            <strong>💡 Tip!</strong> Be specific about where changes should
            appear. Say{' '}
            <em>&quot;include the following link in the description&quot;</em>{' '}
            instead of just <em>&quot;include the following link&quot;</em>.
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="center">
          <Button colorScheme="dark" onClick={onClose}>
            Got it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
