import { useCurrentUserContext } from '@/contexts';
import type { LaunchOption, LaunchParams } from '@/hooks/useLauncher';
import { useExtensionPopup } from '@/pages/QuickCreate/components/GoogleCalendar/hooks/useExtensionPopup';
import { getAuthUrl } from '@/pages/QuickCreate/components/GoogleCalendar/util';
import { useIsExtension } from '@/pages/QuickCreate/hooks/useIsExtension';

type Options = {
  launchOnRedirect?: LaunchOption;
  redirectParams?: LaunchParams;
  email?: string;
  quickCreateJobId?: string;
};

export const useCalendarAuth = ({
  launchOnRedirect,
  redirectParams,
  email,
  quickCreateJobId,
}: Options = {}) => {
  const { currentUser } = useCurrentUserContext();
  const { isExtension } = useIsExtension();
  const { onAuth: handlePopupAuth } = useExtensionPopup({
    launch: launchOnRedirect,
    launchParams: redirectParams,
    email,
  });

  const handleCalendarAuth = () => {
    if (isExtension) {
      return handlePopupAuth();
    }

    const query: Record<string, string> = { ...redirectParams };
    if (launchOnRedirect) {
      query.launch = launchOnRedirect;
    }

    window.location.href = getAuthUrl(
      currentUser,
      { email },
      quickCreateJobId,
      query
    );
  };

  return {
    handleCalendarAuth,
  };
};
