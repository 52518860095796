import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { FaApple, FaGoogle, FaMicrosoft, FaCalendarAlt } from 'react-icons/fa';
import { config } from '@/config';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { useToast } from '@/hooks/useToast';
import { useSchedulePermissions } from '@/pages/Schedule/hooks';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Flex,
  Switch,
  Spinner,
  Center,
  type ButtonProps,
  FormControl,
  FormLabel,
  ModalFooter,
  IconButton,
  Tooltip,
  Collapse,
} from '@/ui';
import AddToCalendarIcon from './addToCalendarIcon.svg?react';
import { Advanced } from './components/Advanced';
import { SendToButton } from './components/SendToButton';
import { SendToOtherButton } from './components/SendToOtherButton';
import { useScheduleFeedSecret } from './hooks/useScheduleFeed';
import { useToggleScheduleFeedEnabled } from './hooks/useToggleScheduleFeedEnabled';

type ButtonRenderer = (props: ButtonProps) => ReactNode;

type Props = {
  renderButton?: ButtonRenderer;
  fetchSecretBeforeOpen?: boolean;
} & ButtonProps;

export const AddToCalendarModal = ({
  renderButton,
  fetchSecretBeforeOpen = false,
  ...props
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation('addToCalendarModal');
  const { toggleEnabled } = useToggleScheduleFeedEnabled();
  const { data, isLoading } = useScheduleFeedSecret(
    fetchSecretBeforeOpen ? true : isOpen
  );
  const debouncedIsLoading = useDebounceLoading(isLoading, 250);
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { hasSchedulePermission } = useSchedulePermissions();
  const toast = useToast();
  const { trackEvent } = useAnalytics();

  const canEdit = hasSchedulePermission('SCHEDULE_MODIFY_SETTINGS');
  const enabled = data?.feedSecret.enabled ?? false;
  const url = `${config.basePath}/feeds/${data?.feedSecret.slug}`;

  const button = renderButton ? (
    renderButton({ onClick: onOpen })
  ) : isMobileBreakpoint ? (
    <IconButton
      aria-label={t('button')}
      borderRadius="full"
      icon={<AddToCalendarIcon height={22} width={22} />}
      variant="secondary"
      onClick={onOpen}
      {...props}
    />
  ) : (
    <IconButton
      aria-label={t('button')}
      colorScheme="dark"
      icon={<AddToCalendarIcon height={28} width={28} />}
      variant="ghost"
      onClick={onOpen}
      {...props}
    />
  );

  if (!canEdit && !enabled) {
    return null;
  }

  return (
    <>
      <Tooltip label={t('button')}>{button}</Tooltip>
      <Modal
        isCentered
        isOpen={isOpen}
        returnFocusOnClose={false}
        variant="primary"
        onClose={onClose}
      >
        <ModalOverlay />

        <ModalContent minW={{ base: '100vw', md: '525px' }}>
          <ModalHeader>
            <AddToCalendarIcon height={24} width={24} />
            {t('heading')}
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            {debouncedIsLoading ? (
              <Center h="28px">
                <Spinner />
              </Center>
            ) : (
              <>
                {canEdit && (
                  <FormControl alignItems="center" display="flex">
                    <FormLabel flex="1" m="0">
                      {t('switch_label')}
                    </FormLabel>

                    <Switch
                      isChecked={enabled}
                      size="lg"
                      onChange={({ currentTarget }) => {
                        toggleEnabled(currentTarget.checked, {
                          onError: () => toast.error(t('enable_error')),
                        });
                      }}
                    />
                  </FormControl>
                )}

                <Collapse in={enabled}>
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    gap="4"
                    my="4"
                  >
                    <SendToButton
                      href={url.replace(/https?/, 'webcal')}
                      icon={<FaApple size="34" />}
                      label={t('apple')}
                      onClick={() =>
                        trackEvent('feed-export:click', { medium: 'apple' })
                      }
                    />
                    <SendToButton
                      icon={<FaGoogle size="28" />}
                      label={t('google')}
                      href={`https://calendar.google.com/calendar/render?cid=${encodeURIComponent(
                        url.replace(/https?/, 'webcal')
                      )}`}
                      onClick={() =>
                        trackEvent('feed-export:click', { medium: 'google' })
                      }
                    />
                  </Flex>
                  <Flex direction={{ base: 'column', md: 'row' }} gap="4">
                    <SendToButton
                      icon={<FaMicrosoft size="28" />}
                      label={t('outlook')}
                      href={`https://outlook.live.com/owa/?path=/calendar/action/subscribe&url=${encodeURIComponent(
                        url
                      )}`}
                      onClick={() =>
                        trackEvent('feed-export:click', { medium: 'outlook' })
                      }
                    />
                    <SendToOtherButton
                      href={url}
                      icon={<FaCalendarAlt size="28" />}
                      label={t('other_calendar')}
                      onClick={() =>
                        trackEvent('feed-export:click', { medium: 'other' })
                      }
                    />
                  </Flex>
                </Collapse>
              </>
            )}
          </ModalBody>

          {!debouncedIsLoading && enabled && canEdit && (
            <ModalFooter
              alignItems="flex-start"
              flexDir="column"
              pb="5 !important"
              pt="5 !important"
            >
              <Advanced />
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
