import { Center, Text } from '@/ui';

type Props = {
  title: string;
  message?: string;
  children?: JSX.Element;
};

export const ErrorState = ({ title, message, children }: Props) => {
  return (
    <Center
      flex="1"
      flexDir="column"
      gap="2"
      px={{ base: 4, sm: 0 }}
      textAlign="center"
    >
      <Text fontSize="3xl">{title}</Text>
      {message && <Text fontSize="lg">{message}</Text>}
      {children}
    </Center>
  );
};
