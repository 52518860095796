import { useTranslation } from 'react-i18next';
import { Divider } from '@/ui';
import { PanelContent } from '../../components/PanelContent';
import { PanelHeader } from '../../components/PanelHeader';
import { DestinationsSection } from './components/DestinationsSection';
import { DisconnectDialog } from './components/DisconnectDialog';
import { IntegrationsSection } from './components/IntegrationsSection';

export const Calendars = () => {
  const { t } = useTranslation('settingsModal', { keyPrefix: 'calendars' });

  return (
    <>
      <PanelHeader>{t('heading')}</PanelHeader>
      <PanelContent>
        <DestinationsSection />
        <Divider my="8" />
        <IntegrationsSection />
      </PanelContent>

      <DisconnectDialog />
    </>
  );
};
