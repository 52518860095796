import { gql } from 'graphql-request';
import type { ExecutionResult } from 'graphql-ws';
import { useCallback, useEffect } from 'react';
import { gqlWsClient, handleError } from '@/lib/gql-client';
import { queryClient } from '@/lib/query-client';
import { createEntriesQueryKey } from '@/utils/queryKeys';
import { EntriesContextFragment } from '../EntriesContextFragment';
import type { EntriesContextOptions } from '../types';
import type {
  EntryUpdatedSubscription,
  EntryUpdatedSubscriptionVariables,
} from './useEntryUpdatedSubscription.generated';

const query = gql`
  ${EntriesContextFragment}
  subscription EntryUpdated(
    $scheduleId: ID!
    $includeMessages: Boolean!
    $includeRsvps: Boolean!
  ) {
    entryUpdated(scheduleId: $scheduleId) {
      id
    }
  }
`;

export const useEntryUpdatedSubscription = (
  scheduleId: string,
  options: EntriesContextOptions
) => {
  const handleData = useCallback(
    ({ data }: ExecutionResult<EntryUpdatedSubscription>) => {
      if (data?.entryUpdated) {
        queryClient.invalidateQueries({
          queryKey: createEntriesQueryKey(scheduleId),
        });
      }
    },
    [scheduleId]
  );

  useEffect(() => {
    const variables: EntryUpdatedSubscriptionVariables = {
      scheduleId,
      ...options,
    };

    const unsubscribe = gqlWsClient.subscribe<EntryUpdatedSubscription>(
      {
        query: query,
        variables,
      },
      {
        next: handleData,
        error: handleError,
        complete: () => null,
      }
    );

    return () => unsubscribe();
  }, [scheduleId, handleData, options]);
};
