import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { queryClient } from '@/lib';
import { gqlClient } from '@/lib/gql-client';
import { useScheduleContext } from '@/pages/Schedule/contexts';
import { useDraftEntryStore } from '@/pages/Schedule/stores';
import type { DecoratedEntry } from '@/pages/Schedule/types';
import type { AttendeeStatus } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import { createEntryInvitesQueryKey } from '@/utils/queryKeys';
import type { RsvpMutation, RsvpMutationVariables } from './useRsvp.generated';

const query = gql`
  mutation RSVP(
    $scheduleId: ID!
    $entryId: ID!
    $status: AttendeeStatus
    $notes: String
  ) {
    rsvp(
      scheduleId: $scheduleId
      entryId: $entryId
      status: $status
      notes: $notes
    )
  }
`;

export const useRsvp = (entry: DecoratedEntry) => {
  const { scheduleId } = useScheduleContext();

  const { draftEntry, updateDraftEntry } = useDraftEntryStore((store) => ({
    draftEntry: store.draftEntry,
    updateDraftEntry: store.updateDraftEntry,
  }));

  const { mutate, ...result } = useMutation<
    RsvpMutation,
    QueryError,
    RsvpMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<RsvpMutation, RsvpMutationVariables>(query, variables),
    onMutate: (variables) => {
      const propsToUpdate: Record<string, unknown> = {};
      if ('status' in variables) {
        propsToUpdate.status = variables.status;
      }
      if ('notes' in variables) {
        propsToUpdate.notes = variables.notes;
      }
      return {
        optimisticData: {
          contextId: entry.contextId,
          rsvpInfo: propsToUpdate,
        },
      };
    },
    onSuccess: (data, variables) => {
      // update RSVP in the entry modal
      if (draftEntry?.id === variables.entryId) {
        updateDraftEntry({
          ...draftEntry,
          rsvpInfo: {
            ...draftEntry.rsvpInfo,
            status: variables.status ?? draftEntry.rsvpInfo?.status,
            notes: variables.notes ?? draftEntry.rsvpInfo?.notes,
          },
        });
      }

      // update RSVP in the entry modal sidebar
      return queryClient.invalidateQueries({
        queryKey: createEntryInvitesQueryKey(scheduleId, variables.entryId),
      });
    },
  });

  const rsvp = (
    status: AttendeeStatus,
    options?: MutateOptions<RsvpMutation, QueryError, RsvpMutationVariables>
  ) => {
    const variables: RsvpMutationVariables = {
      scheduleId,
      entryId: entry.id,
      status,
    };
    return mutate(variables, options);
  };

  const setNotes = (
    entryId: string,
    notes: string | null,
    options?: MutateOptions<RsvpMutation, QueryError, RsvpMutationVariables>
  ) => {
    const variables: RsvpMutationVariables = { scheduleId, entryId, notes };
    return mutate(variables, options);
  };

  return {
    ...result,
    rsvp,
    setNotes,
  };
};
