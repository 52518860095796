import { useQueryNormalizer } from '@normy/react-query';
import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import {
  type DecoratedSchedule,
  useScheduleContext,
} from '@/pages/Schedule/contexts';
import type { QueryError } from '@/utils/errors';
import type { HeaderImage } from '../types';
import type {
  UpdateScheduleHeaderMutation,
  UpdateScheduleHeaderMutationVariables,
} from './useUpdateScheduleHeader.generated';

const query = gql`
  mutation UpdateScheduleHeader(
    $scheduleId: ID!
    $headerId: ScheduleHeaderId
    $headerEnabled: Boolean
  ) {
    updateSchedule(
      scheduleId: $scheduleId
      headerId: $headerId
      headerEnabled: $headerEnabled
    ) {
      id
      header {
        id
        enabled
        imageBaseUrl
      }
    }
  }
`;

export const useUpdateScheduleHeader = () => {
  const { scheduleId } = useScheduleContext();
  const queryNormalizer = useQueryNormalizer();

  const { mutate } = useMutation<
    UpdateScheduleHeaderMutation,
    QueryError,
    UpdateScheduleHeaderMutationVariables
  >({
    mutationFn: (variables) =>
      gqlClient.request<
        UpdateScheduleHeaderMutation,
        UpdateScheduleHeaderMutationVariables
      >(query, variables),
  });

  const updateScheduleHeader = (
    header: HeaderImage,
    options?: MutateOptions<
      UpdateScheduleHeaderMutation,
      QueryError,
      UpdateScheduleHeaderMutationVariables
    >
  ) => {
    const prevSchedule =
      queryNormalizer.getObjectById<DecoratedSchedule>(scheduleId);

    const nextSchedule = {
      ...prevSchedule,
      header: {
        ...prevSchedule?.header,
        id: header.id,
        enabled: true,
        imageBaseUrl: header.imageBaseUrl,
      },
    };

    queryNormalizer.setNormalizedData(nextSchedule);

    return mutate(
      { scheduleId, headerId: header.id, headerEnabled: true },
      {
        ...options,
        onError: (...args) => {
          queryNormalizer.setNormalizedData(prevSchedule);
          options?.onError?.(...args);
        },
      }
    );
  };

  return {
    updateScheduleHeader,
  };
};
