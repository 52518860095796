import {
  forwardRef,
  TabPanel as ChakraTabPanel,
  type TabPanelProps,
} from '@/ui';

export const TabPanel = forwardRef((props: TabPanelProps, ref) => {
  return (
    <ChakraTabPanel
      ref={ref}
      {...props}
      bg="dark.500"
      borderBottomRadius="2xl"
    />
  );
});
